import React from 'react';
import { View, StyleSheet, Font, Text } from '@react-pdf/renderer';

// Font import and registration
import font from '../../../../assets/fonts/Cairo-VariableFont_slnt,wght.ttf';
Font.register({
  family: 'ArabicFont',
  format: 'truetype',
  src: font,
});

// Styles definition
const styles = StyleSheet.create({
  tableContainer: {
    fontFamily: 'ArabicFont',
    textAlign: 'right',
    flexDirection: 'column',
    marginTop: 24,
    borderWidth: 1,
    borderColor: '#E6E6FA',
  },
  table: {
    display: 'table',
    width: '100%',
    borderStyle: 'solid',
    borderColor: '#8A2BE2',
    borderWidth: 1,
    marginBottom: 5,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: 'row',
    backgroundColor: '#E6E6FA',
  },
  tableColHeader: {
    borderColor: '#8A2BE2',
    backgroundColor: '#8A2BE2',
    color: 'white',
    textAlign: 'center',
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    width: '25%',
    textAlign: 'center',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
});

const OfferItemsTable = ({ products, productDetails }) => {
  return (
    <View style={styles.tableContainer}>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <Text style={styles.tableColHeader}>ملاحظات</Text>
          <Text style={styles.tableColHeader}>الإجمالي</Text>
          <Text style={styles.tableColHeader}>السعر</Text>
          <Text style={styles.tableColHeader}>الكمية</Text>
          <Text style={styles.tableColHeader}>المنتج</Text>
        </View>
        {products.map((product, index) => {
          const productDetail = productDetails.find(
            p => p.id === product.product_id
          ); // Changed to product.product_id
          const totalPrice = productDetail
            ? product.quantity * productDetail.price
            : '';
          return (
            <View key={index} style={styles.tableRow}>
              <Text style={styles.tableCol}>{product.notes || ''}</Text>
              <Text style={styles.tableCol}>{totalPrice}</Text>
              <Text style={styles.tableCol}>
                {productDetail ? productDetail.price : ''}
              </Text>
              <Text style={styles.tableCol}>{product.quantity}</Text>
              <Text style={styles.tableCol}>
                {productDetail
                  ? productDetail.name
                  : 'معلومات المنتج غير متوفرة'}
              </Text>
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default OfferItemsTable;
