import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  orderStatusColors,
  paymentStatusColors,
} from '../Tools/OrderStatusColors';
import {
  FaCheckCircle,
  FaUserAlt,
  FaHandshake,
  FaTicketAlt,
  FaMoneyCheckAlt,
  FaTimes,
  FaMinus,
  FaReceipt,
  FaAlipay,
  FaMoneyCheck,
  FaMoneyBillAlt,
  FaRegClock,
  FaCalendarAlt,
  FaPenFancy,
  FaTags,
  FaMoneyBillWave,
} from 'react-icons/fa';
import useAuth from '../../Auth/Authentication/AuthUser';
import InvoiceFormModal from '../../Financial/Invoices/InvoiceModalForm';

const OrderViewMain = () => {
  const { orderId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orderData, setOrder] = useState(null);
  const [productData, setProductData] = useState([]);
  const { http } = useAuth();

  const [invoice, setInvoice] = useState(null);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);

  useEffect(() => {
    async function fetchOrder() {
      try {
        const orderResponse = await http.get(`/api/orders/${orderId}`);
        const productsResponse = await http.get('/api/products');
        const invoiceCheckResponse = await http.get(
          `/api/invoices/${orderId}/check-invoice`
        );

        setOrder(orderResponse.data);
        setProductData(productsResponse.data);
        if (invoiceCheckResponse.data.exists) {
          setInvoice(invoiceCheckResponse.data.invoice);
        }
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    }

    fetchOrder();
  }, [orderId]);

  if (loading) {
    return <div>جاري التحميل...</div>;
  }

  if (error) {
    return <div>خطأ: {error.message}</div>;
  }

  const handleOpenInvoiceModal = () => {
    setIsInvoiceModalOpen(true);
  };

  const handleCloseInvoiceModal = () => {
    setIsInvoiceModalOpen(false);
  };

  const handleSaveInvoice = savedInvoice => {
    setInvoice(savedInvoice);
  };

  const formatMoney = amount => {
    return parseFloat(amount).toFixed(2);
  };

  const getProductDetailsWithId = id => {
    const product = productData.find(product => product.id === id);
    return product
      ? {
          name: product.name,
          price: product.price,
          category: product.category?.name,
        }
      : null;
  };

  const getLabelByMethod = (method, methods) => {
    const item = methods.find(m => m.method === method);
    return item ? item.label : 'غير معروف';
  };

  const getLabelByType = (type, types) => {
    const item = types.find(t => t.type === type);
    return item ? item.label : 'غير معروف';
  };

  const paymentMethods = [
    { method: 'cash', label: 'نقداً' },
    { method: 'bankTransfer', label: 'تحويل بنكي' },
    { method: 'visa', label: 'دفع فيزا' },
  ];

  const paymentTypes = [
    { type: 'full', label: 'سداد كامل' },
    { type: 'partial', label: 'سداد جزئي' },
  ];

  const statusTypes = [
    { type: 'active', label: 'ساري' },
    { type: 'inactive', label: 'غير ساري' },
    { type: 'converted', label: 'تم التحويل' },
  ];

  const formatDate = dateString => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-CA');
  };

  const discountAmount = (
    orderData.total *
    (orderData.discount_rate / 100)
  ).toFixed(2);
  const taxAmount = (
    (orderData.total - discountAmount) *
    (orderData.tax_rate / 100)
  ).toFixed(2);
  const totalAfterDiscount = (orderData.total - discountAmount).toFixed(2);
  const totalAfterTax = (
    parseFloat(totalAfterDiscount) + parseFloat(taxAmount)
  ).toFixed(2);

  const translateOrderType = type => {
    const translations = {
      promotional_products: 'منتجات دعائية',
      designs_and_prints: 'تصميمات ومطبوعات',
      service: 'خدمة',
      marketing: 'تسويق',
    };

    try {
      const orderTypeArray = JSON.parse(type);
      return orderTypeArray.map(item => translations[item] || item);
    } catch (error) {
      console.error('Failed to parse order type:', error);
      return ['نوع الطلب غير معروف'];
    }
  };

  const renderOrderTypes = orderTypes => {
    return orderTypes.map((type, index) => (
      <span
        key={type + index}
        className='block text-sm sm:text-base md:text-lg text-white'
      >
        - {type}
      </span>
    ));
  };

  return (
    <div className='container mx-auto h-full px-4 pt-10'>
      <div className='mt-4 neon-shadow bg-gradient-to-br from-purple-900 to-blue-900 dark:from-green-700 dark:to-blue-900 p-8 rounded-3xl text-white animate-opacity'>
        <Link
          to='/offers'
          className='text-yellow-300 mb-4 block text-right hover:underline'
        >
          <button className='bg-cyan-700 px-4 py-2 rounded-full shadow-lg hover:scale-105 transition duration-300 hover:bg-cyan-800 hover:text-yellow-300 font-bold text-yellow-400'>
            العودة للطلبات
          </button>
        </Link>
        <h2 className='text-3xl font-bold text-center mb-10 text-yellow-300'>
          تفاصيل الطلب
        </h2>
        <div className='mb-6 grid grid-cols-1 md:grid-cols-6 lg:grid-cols-6 gap-6 text-center'>
          {[
            {
              label: 'رقم الطلب',
              value: orderData.order_number,
              icon: FaTicketAlt,
            },
            {
              label: 'تاريخ الطلب',
              value: formatDate(orderData.order_date),
              icon: FaCalendarAlt,
            },
            {
              label: 'العميل',
              value: orderData.customer?.name,
              icon: FaUserAlt,
            },
            {
              label: 'مندوب المبيعات',
              value: orderData.employees?.sale_rep?.name,
              icon: FaHandshake,
            },
            {
              label: 'مندوب التسويق',
              value: orderData.employees?.social_rep?.name,
              icon: FaHandshake,
            },
            {
              label: 'المصمم',
              value: orderData.employees?.designer?.name,
              icon: FaHandshake,
            },
          ].map((item, index) => (
            <div
              key={item.label + index}
              className='neon-shadow flex flex-col items-center justify-center p-2 dark:bg-gradient-to-b dark:from-logic-half-light-amber dark:to-logic-half-dark-indigo bg-gradient-to-t from-purple-700 to-indigo-400 rounded-3xl shadow-xl transform transition duration-500 hover:scale-105'
            >
              <item.icon className='text-2xl sm:text-2xl md:text-3xl text-center dark:text-blue-400 text-orange-300 mb-2' />
              <div className='text-center'>
                <span className='block text-sm sm:text-md md:text-lg text-yellow-400 mb-1'>
                  {item.label}
                </span>
                <span className='block text-xs sm:text-base md:text-md text-yellow-100'>
                  {item.value}
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className='text-center mb-6'>
          {invoice ? (
            <button
              className='mt-6 hover:bg-logic-half-light-amber text-white px-4 p-4 rounded-full shadow-lg hover:scale-105 transition duration-300 bg-logic-half-dark-indigo'
              onClick={() => console.log('عرض الفاتورة')}
            >
              عرض الفاتورة
            </button>
          ) : (
            <button
              onClick={handleOpenInvoiceModal}
              className='mt-6 hover:bg-logic-half-light-amber text-white px-4 p-4 rounded-full shadow-lg hover:scale-105 transition duration-300 bg-logic-half-dark-indigo'
            >
              إنشاء فاتورة
            </button>
          )}
        </div>
        {isInvoiceModalOpen && (
          <InvoiceFormModal
            orderId={orderId}
            orderData={orderData} // تمرير بيانات الطلب
            invoiceData={invoice}
            productData={productData} // تمرير بيانات المنتج
            onClose={handleCloseInvoiceModal}
            onSave={handleSaveInvoice}
            totalBeforeDiscountAndTax={orderData.total} // تمرير الاجمالي قبل الخصم والضريبة
            totalAfterDiscountAndTax={totalAfterTax} // تمرير الاجمالي بعد الخصم والضريبة
            saleRepName={orderData.employees?.sale_rep?.name}
          />
        )}
        <div className='justify-center mb-2 overflow-x-auto'>
          <table className='min-w-full divide-y divide-gray-200 mb-6'>
            <thead>
              <tr className='font-semibold text-gray-900 dark:text-white bg-gray-50 dark:bg-gray-700 uppercase tracking-wider'>
                <th className='px-3 py-2 border border-gray-300 shadow-lg text-center text-xs sm:text-sm'>
                  #
                </th>
                <th className='px-3 py-2 border border-gray-300 shadow-lg text-center text-xs sm:text-sm'>
                  المنتج
                </th>
                <th className='px-3 py-2 border border-gray-300 shadow-lg text-center text-xs sm:text-sm'>
                  التصنيف
                </th>
                <th className='px-3 py-2 border border-gray-300 shadow-lg text-center text-xs sm:text-sm'>
                  الكمية
                </th>
                <th className='px-3 py-2 border border-gray-300 shadow-lg text-center text-xs sm:text-sm'>
                  سعر الوحدة
                </th>

                <th className='px-3 py-2 border border-gray-300 shadow-lg text-center text-xs sm:text-sm'>
                  إجمالي السعر
                </th>
                <th className='px-3 py-2 border border-gray-300 shadow-lg text-center text-xs sm:text-sm'>
                  الحالة
                </th>
                <th className='px-3 py-2 border border-gray-300 shadow-lg text-center text-xs sm:text-sm'>
                  ملاحظات
                </th>
              </tr>
            </thead>
            <tbody>
              {orderData.products.map((product, index) => {
                const productDetails = getProductDetailsWithId(
                  product.product_id
                );
                return (
                  <tr
                    key={index}
                    className={`text-sm font-medium text-gray-700 dark:text-gray-300 ${
                      index % 2 === 0
                        ? 'bg-gray-50 dark:bg-gray-700'
                        : 'bg-gray-100 dark:bg-gray-600'
                    }`}
                  >
                    <td className='px-3 py-2 border border-gray-200 shadow-lg text-center text-violet-500 dark:text-yellow-300'>
                      {index + 1}
                    </td>
                    <td className='px-3 py-2 border border-gray-200 shadow-lg text-center text-violet-500 dark:text-yellow-300'>
                      {productDetails?.name}
                    </td>
                    <td className='px-3 py-2 border border-gray-200 shadow-lg text-center'>
                      {productDetails?.category}
                    </td>
                    <td className='px-3 py-2 border border-gray-200 shadow-lg text-center'>
                      {product.quantity}
                    </td>
                    <td className='px-3 py-2 border border-gray-200 shadow-lg text-center'>
                      {formatMoney(productDetails?.price)}
                    </td>
                    <td className='px-3 py-2 border border-gray-200 shadow-lg text-center'>
                      ${formatMoney(productDetails?.price * product.quantity)}
                    </td>
                    <td
                      className={`px-3 py-2 border border-gray-200 shadow-lg text-center ${orderStatusColors[orderData.order_status]}`}
                    >
                      {orderData.order_status}
                    </td>
                    <td className='px-3 py-2 border border-gray-200 shadow-lg text-center'>
                      {product.notes}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
          <div>
            {[
              {
                label: 'الإجمالي قبل الخصم',
                value: `${formatMoney(orderData.total)} ر.س`,
                icon: FaMoneyCheckAlt,
              },
              {
                label: 'قيمة الخصم المطبق',
                value: `${formatMoney(discountAmount)} ر.س`,
                icon: FaTags,
              },
              {
                label: 'قيمة الضريبة المضافة',
                value: `${formatMoney(taxAmount)} ر.س`,
                icon: FaMoneyBillAlt,
              },
              {
                label: 'الإجمالي بعد الخصم',
                value: `${formatMoney(totalAfterDiscount)} ر.س`,
                icon: FaMoneyCheck,
              },
              {
                label: 'الإجمالي بعد الضريبة',
                value: `${formatMoney(totalAfterTax + orderData.tax_amount)} ر.س`,
                icon: FaMoneyBillAlt,
              },
              {
                label: 'نوع الطلب',
                value: renderOrderTypes(
                  translateOrderType(orderData.order_type)
                ),
                icon: FaCheckCircle,
              },
              {
                label: 'محرر الطلب',
                value: orderData.creator?.name,
                icon: FaPenFancy,
              },
            ].map((item, index) => (
              <div key={index} className='mb-4 flex items-center'>
                <item.icon className='text-3xl sm:text-4xl md:text-5xl text-green-500 ml-4' />
                <div className='text-right'>
                  <span className='block text-base sm:text-lg md:text-xl text-yellow-300'>
                    {item.label}:
                  </span>
                  {Array.isArray(item.value) ? (
                    item.value
                  ) : (
                    <span className='block text-sm sm:text-base md:text-lg text-white'>
                      {item.value}
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div>
            {[
              {
                label: 'نسبة الخصم المطبق',
                value: `${orderData.discount_rate}%`,
                icon: FaMinus,
              },
              {
                label: 'نسبة الضريبة المضافة',
                value: `${orderData.tax_rate}%`,
                icon: FaReceipt,
              },
              {
                label: 'نظام السداد',
                value: getLabelByType(orderData.payment_type, paymentTypes),
                icon: FaAlipay,
              },
              {
                label: 'طريقة الدفع',
                value: getLabelByMethod(
                  orderData.payment_method,
                  paymentMethods
                ),
                icon: FaMoneyBillWave,
              },
              {
                label: 'حالة السداد',
                value: orderData.payment_status,
                icon: FaMoneyBillWave,
                colorClass: paymentStatusColors[orderData.payment_status],
              },
              {
                label: 'حالة الطلب',
                value: orderData.order_status,
                icon: FaCheckCircle,
                colorClass: orderStatusColors[orderData.order_status],
              },
              {
                label: 'مدة التنفيذ',
                value: `${orderData.time_plementation_range} يوم `,
                icon: FaRegClock,
              },
            ].map((item, index) => (
              <div key={index} className='mb-4 flex items-center'>
                <item.icon className='text-2xl sm:text-3xl md:text-4xl text-green-500 ml-4' />
                <div className='text-right'>
                  <span className='block text-base sm:text-lg md:text-xl text-yellow-300'>
                    {item.label}:
                  </span>
                  <span
                    className={`block text-sm sm:text-base md:text-lg ${item.colorClass}`}
                  >
                    {item.value}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderViewMain;
