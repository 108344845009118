import { Route, Routes } from 'react-router-dom';
import Home from '../Home/Home.jsx';
import DesignList from '../Designs/DesignList.jsx';
import CustomerList from '../Customers/index';
import DesignModalForm from '../Designs/DesignModalForm';
import CustomerModalForm from '../Customers/CustomerModalForm.jsx';
import SaleRepList from '../Employees/SalesReps/index.jsx';
import DesignerList from '../Employees/Designers/index.jsx';
import DesignerModalForm from '../Employees/Designers/DesignerModalForm.jsx';
import SaleRepModalForm from '../Employees/SalesReps/SaleRepModalForm.jsx';
import ProductList from '../Store/index.jsx';
import ProductModalForm from '../Store/Products/ProductModalForm.jsx';
import EmployeeManager from '../Employees/index.jsx';
import OrderManagment from '../Orders/index';
import OfferManagement from '../Offers/index.jsx';
import OfferDetails from '../Offers/OfferDetails.jsx';
import OrderViewMain from '../Orders/Tools/OrderViewMain.jsx';
import EmployeeUsers from '../Users/index.jsx';
import InvoiceSection from '../Financial/Invoices/index.jsx';
import RoleManagement from '../Users/Sections/RoleManagement.jsx';
import DesignerComponent from '../Employees/Designers/index.jsx';
import CollectionsSection from '../Financial/Collections/index.jsx';

export default function AuthRoutes() {
  return (
    <div className='container mx-auto h-full px-4 sm:px-6 lg:px-8 pt-10'>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/designs' element={<DesignList />} />
        <Route path='/designs/new' element={<DesignModalForm />} />

        <Route path='/customers' element={<CustomerList />} />
        <Route path='/customers/new' element={<CustomerModalForm />} />
        <Route path='/employees/*' element={<EmployeeManager />} />
        <Route path='/products' element={<ProductList />} />
        <Route path='/sales-reps/new' element={<ProductModalForm />} />

        <Route path='/offers' element={<OfferManagement />} />
        <Route path='/offers/:offer' element={<OfferDetails />} />

        <Route path='/orders' element={<OrderManagment />} />
        <Route path='/orders/:orderId' element={<OrderViewMain />} />
        <Route path='/users' element={<EmployeeUsers />} />
        <Route path='/invoices' element={<InvoiceSection />} />
        <Route path='/collects' element={<CollectionsSection />} />
        <Route path='/user-permissions' element={<RoleManagement />} />
      </Routes>
    </div>
  );
}
