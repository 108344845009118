import React, { useState, useEffect } from 'react';
import { IoMdContact, IoMdCall } from 'react-icons/io';
import useAuth from '../../Auth/Authentication/AuthUser';
import API_CONFIG from '../../../ApiConfig';
import ModalComponent from '../../Tools/ModalComponent';

import {
  validateImage,
  validatePhoneNumber,
  validateForm,
} from '../../Tools/Validation'; // استيراد الوظائف

const DesignerModalForm = ({
  isOpen,
  onClose,
  designer,
  refreshDesigners,
  setSuccessMessage,
}) => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    skills: '',
    image: null,
  });

  const [validationErrors, setValidationErrors] = useState({});
  const [imageFile, setImageFile] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { http } = useAuth();

  useEffect(() => {
    if (isOpen && designer) {
      setFormData({
        name: designer.name,
        phone: designer.phone,
        skills: designer.skills,
        image: designer.image ? `${API_CONFIG.baseURL}${designer.image}` : null,
      });
    } else {
      setFormData({ name: '', phone: '', skills: '', image: null });
      setImageFile(null);
    }
    setIsSubmitted(false);
    setValidationErrors({}); // Clear any validation errors when the modal is reopened
  }, [isOpen, designer]);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleFileChange = e => {
    const file = e.target.files[0];
    if (file) {
      const imageErrors = validateImage(file);
      if (!imageErrors) {
        setImageFile(file);
        setFormData(prevState => ({
          ...prevState,
          image: URL.createObjectURL(file),
        }));
      } else {
        setValidationErrors(prevErrors => ({
          ...prevErrors,
          image: [imageErrors],
        }));
      }
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsSubmitted(true);

    const errors = validateForm(formData, imageFile); // Perform initial validation
    const phoneError = validatePhoneNumber(formData.phone);
    if (phoneError) errors.phone = phoneError; // Always validate phone number

    setValidationErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('phone', formData.phone);
    formDataToSend.append('skills', formData.skills);
    if (imageFile) {
      formDataToSend.append('image', imageFile);
    }

    try {
      const endpoint =
        designer && designer.id
          ? `/api/designers/${designer.id}`
          : '/api/designers';
      const method = designer && designer.id ? 'PATCH' : 'POST';
      formDataToSend.append('_method', method);

      await http.post(endpoint, formDataToSend);
      onClose();
      refreshDesigners();
      setSuccessMessage(designer ? 'تم التحديث بنجاح!' : 'تمت الإضافة بنجاح!');
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    } catch (error) {
      // Ensure to capture and display errors correctly
      setValidationErrors(error.response.data.errors || {});
    }
  };

  const modalContent = (
    <form onSubmit={handleSubmit} className='mt-4'>
      {Object.keys(validationErrors).length > 0 && (
        <div className='mt-4'>
          <h3 className='text-red-500 text-md font-bold'>أخطاء:</h3>
          <ul>
            {Object.entries(validationErrors).map(([field, errors]) => {
              // Ensure errors is always an array
              const listOfErrors = Array.isArray(errors) ? errors : [errors];
              return listOfErrors.map(error => (
                <li key={error} className='text-red-500 text-xs italic'>
                  {error} {field}
                </li>
              ));
            })}
          </ul>
        </div>
      )}
      <div className='flex items-center border-b border-gray-300 py-2'>
        <IoMdContact className='text-lg text-gray-500 dark:text-violet-400 ml-2' />
        <input
          type='text'
          name='name'
          value={formData.name}
          onChange={handleChange}
          placeholder='اسم المصمم'
          className='w-full bg-transparent p-2 text-gray-700 dark:text-gray-300 border-none text-right'
          required
          readOnly={!!designer}
        />
      </div>
      {isSubmitted && !formData.name && (
        <div className='text-red-500 font-bold'>يرجى إدخال اسم المصمم</div>
      )}
      {validationErrors.name && (
        <div className='text-red-500 font-bold'>{validationErrors.name[0]}</div>
      )}
      <div className='flex items-center border-b border-gray-300 py-2'>
        <IoMdCall className='text-lg text-gray-500 dark:text-violet-400 ml-2' />
        <input
          type='text'
          name='phone'
          value={formData.phone}
          onChange={handleChange}
          placeholder='رقم الهاتف'
          className='w-full bg-transparent p-2 text-gray-700 dark:text-gray-300 border-none text-right'
          required
        />
      </div>
      {isSubmitted && !formData.phone && (
        <div className='text-red-500 font-bold'>يرجى إدخال رقم الهاتف</div>
      )}
      {validationErrors.phone && (
        <div className='text-red-500 font-bold'>
          {validationErrors.phone[0]}
        </div>
      )}
      <div className='flex items-center border-b border-gray-300 py-2'>
        <textarea
          className='w-full bg-transparent p-2 text-gray-700 dark:text-gray-300 border-none text-right'
          name='skills'
          value={formData.skills}
          onChange={handleChange}
          placeholder='المهارات'
        />
      </div>
      <div className='mb-4'>
        <label
          htmlFor='image'
          className='block text-gray-500 dark:text-violet-400 font-bold mb-2'
        >
          الصورة
        </label>

        <input
          type='file'
          name='image'
          onChange={handleFileChange}
          className='appearance-none bg-transparent border-none w-full text-gray-700 dark:text-gray-300 p-2 leading-tight focus:outline-none'
        />
        {formData.image && (
          <div>
            <img
              src={formData.image}
              alt='Designer Image'
              className='w-32 h-32 object-cover'
            />
          </div>
        )}
      </div>
      {validationErrors.image && (
        <p className='text-red-500 text-xs italic'>
          {validationErrors.image[0]}
        </p>
      )}
      <div className='flex justify-around mt-2'>
        <button
          className='bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700'
          onClick={onClose}
        >
          إلغاء
        </button>
        <button
          type='submit'
          className='bg-violet-500 hover:bg-violet-700 text-white font-bold px-4 py-2 rounded focus:outline-none focus:shadow-outline'
        >
          {designer ? 'تحديث' : 'إضافة'}
        </button>
      </div>
      ء
    </form>
  );

  return (
    <ModalComponent
      titleModal={designer ? 'تحديث مصمم جرافيك' : 'إضافة مصمم جرافيك'}
      onClose={onClose}
      ContentModal={modalContent}
      isOpen={isOpen}
    />
  );
};

export default DesignerModalForm;
