// Import necessary modules
import React, { useEffect, useState, useMemo } from 'react';
import useAuth from '../Auth/Authentication/AuthUser.jsx';
import { v4 as uuidv4 } from 'uuid';
import { FaPlus } from 'react-icons/fa';
import OrderProducts from './Tools/OrderProducts.jsx';
import OrderDiscountAndTax from './Tools/OrderDiscountAndTax.jsx';
import OrderPaymentMethod from './Tools/OrderPaymentMethod.jsx';
import OrderCustomerSaleRep from './Tools/OrderCustomerSaleRep.jsx';
import OrderCategoryProducts from './Tools/OrderCategoryProducts.jsx';

const OrderModalForm = ({
  refetchOrders,
  isOpen,
  onClose,
  orderData: initialOrderData = null,
  isEditing = false,
}) => {
  const { http, user } = useAuth();

  const initialOrderState = {
    created_by: user?.id || '',
    customer_id: '',
    sale_rep_id: '',
    products: [],
    tax_amount: 0,
    discount_amount: 0,
    discount_rate: 0,
    tax_rate: 0,
    total_final: 0,
    valid_until: '',
    payment_method: 'cash',
    payment_type: 'full',
    status: 'active',
    time_plementation_range: '',
  };

  const [orderData, setOrderData] = useState(initialOrderState);
  const [orderDate, setOrderDate] = useState(
    new Date().toISOString().slice(0, 16)
  );

  const [categories, setCategories] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [saleReps, setSaleReps] = useState([]);
  const [products, setProducts] = useState([]);
  const [showAddedProductMessage, setShowAddedProductMessage] = useState(false);
  const [addedProductMessage, setAddedProductMessage] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('cash');
  const [productInputs, setProductInputs] = useState({});
  const [paymentType, setPaymentType] = useState('full');
  const [socialReps, setSocialReps] = useState([]);
  const [commission, setCommission] = useState(false);
  const [orderType, setOrderType] = useState([]);
  const [taxRate, setTaxRate] = useState(0);
  const [discountRate, setDiscountRate] = useState(0);
  const [selectedSocialRep, setSelectedSocialRep] = useState(null);
  const [selectedDesigner, setSelectedDesigner] = useState(null);
  const [designers, setDesigners] = useState([]);
  const [timePlementationRange, setTimePlementationRange] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({
    id: '',
    name: '',
  });

  const [selectedSaleRep, setSelectedSaleRep] = useState('');
  const [dataFetched, setDataFetched] = useState(false);
  const total = useMemo(
    () =>
      orderData.products.reduce(
        (acc, curr) => acc + curr.quantity * curr.price,
        0
      ),
    [orderData.products]
  );
  const [error, setError] = useState(null);
  const taxAmount = useMemo(() => {
    return total * (taxRate / 100);
  }, [total, taxRate]);

  const discountAmount = useMemo(() => {
    return (total + taxAmount) * (discountRate / 100);
  }, [total, taxRate, discountRate]);

  const totalFinal = useMemo(() => {
    return total + taxAmount - discountAmount;
  }, [total, taxAmount, discountAmount]);

  useEffect(() => {
    const initialInputs = {};
    filteredProducts.forEach(product => {
      initialInputs[product.id] = { quantity: 1, notes: '' };
    });
    setProductInputs(initialInputs);
  }, [filteredProducts]);

  // In the useEffect for fetching data, set the state for sale reps correctly
  useEffect(() => {
    async function fetchData() {
      try {
        const [
          saleRepsData,
          customersData,
          productsData,
          designersResponse,
          socialRepsResponse,
        ] = await Promise.all([
          http.get('/api/sale-reps'),
          http.get('/api/customers'),
          http.get('/api/products'),
          http.get('/api/designers'),
          http.get('/api/social-reps'),
        ]);
        setSaleReps(saleRepsData.data);
        setCustomers(customersData.data);
        setProducts(
          productsData.data.map(product => ({
            ...product,
            category: product.category.name,
          }))
        );
        setDesigners(designersResponse.data);
        setSocialReps(socialRepsResponse.data);
        setDataFetched(true);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    }

    if (isOpen && !dataFetched) {
      fetchData();
    }

    if (!isOpen) {
      resetForm();
      setDataFetched(false);
    }
  }, [isOpen]);

  // Handle sale rep selection
  const handleSaleRepSelect = event => {
    const selectedId = event.target.value;
    setSelectedSaleRep(selectedId);
    setOrderData(prevState => ({
      ...prevState,
      employees: {
        ...prevState.employees,
        sale_rep_id: selectedId,
      },
    }));
  };

  useEffect(() => {
    if (products.length > 0) {
      const extractedCategories = Array.from(
        new Set(products.map(product => product.category))
      );
      setCategories(extractedCategories);
    }
  }, [products]);

  useEffect(() => {
    if (selectedCategory !== '') {
      const filtered = products.filter(
        product => product.category === selectedCategory
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts([]);
    }
  }, [selectedCategory, products]);

  const resetForm = () => {
    setOrderData(initialOrderState);
    setOrderDate(new Date().toISOString().slice(0, 16));
    setCategories([]);
    setCustomers([]);
    setSaleReps([]);
    setProducts([]);
    setTimePlementationRange('');
    setSelectedCategory('');
    setSelectedCustomer({ id: '', name: '' });
    setSelectedSaleRep(null);
    setSelectedDesigner(null);
    setSelectedSocialRep(null);
    setDataFetched(false);
  };
  const handleDesignerChange = event => {
    setSelectedDesigner(event.target.value);
  };
  const handleCategorySelect = category => {
    setSelectedCategory(category);
  };

  const handlePaymentMethodChange = method => {
    setPaymentMethod(method);
  };

  const handlePaymentTypeChange = type => {
    setPaymentType(type);
  };
  const handleAddToProductList = (product, quantityInput, notesInput) => {
    const quantity = parseInt(quantityInput);
    if (isNaN(quantity) || quantity <= 0) {
      alert('يرجى إدخال كمية صالحة.');
      return;
    }

    if (!selectedCustomer || !selectedSaleRep) {
      alert('الرجاء تحديد العميل ومندوب المبيعات قبل إضافة المنتجات.');
      return;
    }

    const newProduct = {
      ...product,
      slug: uuidv4(),
      product_id: product.id,
      quantity: quantity,
      notes: notesInput,
    };

    setOrderData(prevState => ({
      ...prevState,
      products: [...prevState.products, newProduct],
    }));

    setAddedProductMessage(`تمت إضافة ${product.name} إلى السلة.`);
    setShowAddedProductMessage(true);

    const timer = setTimeout(() => {
      setShowAddedProductMessage(false);
    }, 3000);

    // تنظيف المؤقت عند إعادة التنفيذ
    return () => clearTimeout(timer);
  };

  const totalProducts = orderData.products.reduce(
    (sum, product) => sum + product.quantity,
    0
  );

  const handleCustomerSelect = customer => {
    setSelectedCustomer(customer);
    setOrderData(prevState => ({
      ...prevState,
      customer_id: customer.id,
    }));
  };

  const handleSocialRepChange = event => {
    setSelectedSocialRep(event.target.value);
  };

  const handleRemoveProduct = slug => {
    setOrderData(prevState => ({
      ...prevState,
      products: prevState.products.filter(product => product.slug !== slug),
    }));
  };
  const handleOrderTypeChange = event => {
    const value = event.target.value;
    setOrderType(prevOrderType =>
      prevOrderType.includes(value)
        ? prevOrderType.filter(type => type !== value)
        : [...prevOrderType, value]
    );
  };

  const handleCommissionChange = event => {
    setCommission(event.target.checked);
  };
  const handleSubmit = async e => {
    e.preventDefault();

    if (orderData.processing) return;
    setOrderData(prev => ({ ...prev, processing: true }));

    try {
      const newTotal = orderData.products.reduce(
        (acc, cur) => acc + cur.quantity * cur.price,
        0
      );
      const employeesData = {
        designer_id: selectedDesigner,
        sale_rep_id: selectedSaleRep,
        social_rep_id: selectedSocialRep,
      };
      const newTaxAmount = newTotal * (taxRate / 100);
      const newDiscountAmount =
        (newTotal + newTaxAmount) * (discountRate / 100);
      const newTotalFinal = newTotal + newTaxAmount - newDiscountAmount;

      const formattedProducts = orderData.products.map(product => {
        const productDetails = products.find(p => p.id === product.product_id);
        return {
          product_id: product.product_id,
          quantity: product.quantity,
          price: productDetails?.price,
          notes: product.notes,
        };
      });

      const requestData = {
        order_date: orderDate,
        order_type: orderType,
        created_by: user.id,
        customer_id: selectedCustomer.id,
        employees: employeesData,
        products: formattedProducts,
        tax_rate: taxRate,
        is_commission: commission,
        discount_rate: discountRate,
        time_plementation_range: timePlementationRange,
        total_final: newTotalFinal,
        total: newTotal,
        tax_amount: newTaxAmount,
        discount_amount: newDiscountAmount,
        payment_method: paymentMethod,
        payment_type: paymentType,
      };

      const response = await http.post('/api/orders', requestData);
      console.log('Response:', response.data);
      // عرض رسالة نجاح
      alert('تم إرسال الطلب بنجاح!');
      resetForm();
      refetchOrders();
      onClose(); // إغلاق النافذة المنبثقة
    } catch (error) {
      console.error('حدث خطأ أثناء إرسال البيانات:', error);
      setError(error.toString());
    } finally {
      setOrderData(prev => ({ ...prev, processing: false }));
    }
  };

  const orderTypes = [
    { label: 'خدمة', value: 'service' },
    { label: 'تصميمات ومطبوعات', value: 'designs_and_prints' },
    { label: 'منتجات دعائية', value: 'promotional_products' },
    { label: 'نشر وتسويق', value: 'marketing' },
  ];

  return (
    <div
      className={`fixed inset-0 z-50 overflow-y-auto ${isOpen ? 'bg-gray-900 bg-opacity-50 dark:bg-black dark:bg-opacity-80' : 'hidden'}`}
    >
      <div className='bg-gray-50 dark:bg-logic-dark-blue rounded-lg overflow-hidden w-full max-w-4xl m-auto'>
        {/* Header */}
        <div className='flex justify-between items-center bg-gradient-to-l from-purple-600 to-indigo-600 dark:from-gray-700 dark:to-gray-900 border-3 p-3 rounded-t-xl border-orange-400'>
          <h2 className='text-xl font-bold text-white'>طلب جديد</h2>
          <button onClick={onClose} className='text-white'>
            <FaPlus className='rotate-45 text-2xl' />
          </button>
        </div>
        <form onSubmit={handleSubmit} className='space-y-6 p-4'>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4 p-4 bg-white dark:bg-gray-900 rounded-lg shadow-md'>
            <div className='col-span-1 md:col-span-2'>
              <h3 className='text-lg font-bold text-center text-orange-500 dark:text-orange-300 mb-2'>
                تاريخ الطلب
              </h3>
              <input
                type='datetime-local'
                className='input-order text-center mt-1 block w-full bg-violet-200 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50'
                value={orderDate}
                onChange={e => setOrderDate(e.target.value)}
                readOnly
              />
            </div>
            <div className='col-span-2'>
              <h3 className='text-lg font-bold text-center text-orange-400 mb-2'>
                نوع الطلب
              </h3>
              <div className='flex flex-wrap justify-center gap-4'>
                {orderTypes.map((type, index) => (
                  <div key={index} className='flex items-center mb-2'>
                    <input
                      type='checkbox'
                      id={type.value}
                      value={type.value}
                      onChange={handleOrderTypeChange}
                      checked={orderType.includes(type.value)}
                      className='form-checkbox ml-4 h-5 w-5 text-blue-500 dark:text-blue-300 transition duration-150 ease-in-out'
                    />
                    <label
                      htmlFor={type.value}
                      className='ml-2 text-gray-800 dark:text-gray-200'
                    >
                      {type.label}
                    </label>
                  </div>
                ))}
              </div>

              <div className='col-span-2 flex flex-col md:flex-row items-center justify-between md:justify-center space-y-4 md:space-y-0 md:space-x-4'>
                <h3 className='text-lg font-bold text-center text-orange-400 mb-2 md:mb-0'>
                  شمول الطلب عمولة المندوب:
                </h3>
                <input
                  type='checkbox'
                  checked={commission}
                  onChange={handleCommissionChange}
                  className='form-checkbox h-5 w-5 text-green-500 dark:text-green-300 transition duration-150 ease-in-out'
                />
              </div>

              <div className='col-span-1 md:col-span-2'>
                <div className='flex flex-col md:flex-row justify-between gap-4'>
                  {orderType.includes('designs_and_prints') && (
                    <div className='flex-1'>
                      <h3 className='text-lg font-bold text-center text-orange-400 mb-2'>
                        المصمم:
                      </h3>
                      <select
                        value={selectedDesigner}
                        onChange={handleDesignerChange}
                        className='mt-1 block w-full bg-gray-300 text-gray-800 border-gray-600 rounded-md focus:ring-indigo-500 focus:border-indigo-500 text-center'
                        style={{
                          height: '40px',
                          padding: '6px 12px',
                          fontSize: '16px',
                        }}
                      >
                        <option value=''>اختر</option>
                        {designers.map(designer => (
                          <option key={designer.id} value={designer.id}>
                            {designer.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {orderType.includes('marketing') && (
                    <div className='flex-1'>
                      <h3 className='text-lg font-bold text-center text-orange-400 mb-2'>
                        المسؤول التسويقي:
                      </h3>
                      <select
                        value={selectedSocialRep}
                        onChange={handleSocialRepChange}
                        className='mt-1 block w-full bg-gray-300 text-gray-800 border-gray-600 rounded-md focus:ring-indigo-500 focus:border-indigo-500 text-center'
                        style={{
                          height: '40px',
                          padding: '6px 12px',
                          fontSize: '16px',
                        }}
                      >
                        <option value=''>اختر</option>
                        {socialReps.map(rep => (
                          <option key={rep.id} value={rep.id}>
                            {rep.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <OrderCustomerSaleRep
              customers={customers}
              saleReps={saleReps}
              selectedCustomer={selectedCustomer}
              selectedSaleRep={selectedSaleRep}
              handleCustomerSelect={handleCustomerSelect}
              handleSaleRepSelect={handleSaleRepSelect}
            />

            <OrderCategoryProducts
              categories={categories}
              selectedCategory={selectedCategory}
              handleCategorySelect={handleCategorySelect}
              filteredProducts={filteredProducts}
              productInputs={productInputs}
              setProductInputs={setProductInputs}
              handleAddToProductList={handleAddToProductList}
              showAddedProductMessage={showAddedProductMessage}
              addedProductMessage={addedProductMessage}
            />
          </div>
          <div className='justify-center mb-2'>
            <div className='bg-gradient-to-b from-blue-800 to-blue-900 dark:from-violet-900 dark:to-blue-800 p-6 rounded-2xl shadow-2xl overflow-hidden text-white'>
              <OrderProducts
                products={orderData.products}
                handleRemoveProduct={handleRemoveProduct}
              />
              <OrderDiscountAndTax
                taxRate={taxRate}
                setTaxRate={setTaxRate}
                discountRate={discountRate}
                setDiscountRate={setDiscountRate}
                timePlementationRange={timePlementationRange}
                setTimePlementationRange={setTimePlementationRange}
                error={error}
              />
              <OrderPaymentMethod
                totalFinal={totalFinal}
                paymentMethod={paymentMethod}
                paymentType={paymentType}
                setPaymentMethod={handlePaymentMethodChange}
                setPaymentType={handlePaymentTypeChange}
                total={total}
                discountAmount={discountAmount}
                taxAmount={taxAmount}
              />
            </div>
          </div>
          <button
            type='submit'
            className='bg-violet-600 hover:bg-violet-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full'
          >
            تأكيد الطلب
          </button>
        </form>
      </div>
    </div>
  );
};

export default OrderModalForm;
