import React, { useState, useEffect } from 'react';
import API_CONFIG from '../../../ApiConfig';
import TableComponent from '../../Tools/TableComponent';
import SaleRepModalForm from './SaleRepModalForm';
import { AiOutlinePlus } from 'react-icons/ai';
import useAuth from '../../Auth/Authentication/AuthUser';

import DeleteConfirmationModal from '../DeleteConfirmationModal';

const SaleRepComponent = ({ saleReps, refreshData }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedSaleRep, setSelectedSaleRep] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [saleRepIdToDelete, setSaleRepIdToDelete] = useState(null);

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const { http, user } = useAuth();

  useEffect(() => {
    refreshData();
  }, []);

  const headers = [
    { key: 'id', text: 'ID' },
    { key: 'name', text: 'الاسم' },
    { key: 'phone', text: 'رقم الهاتف' },
    { key: 'covered_areas', text: 'منطقة التغطية' },
    { key: 'image', text: 'الصورة' },
  ];

  const handleEditClick = saleRepId => {
    const saleRepToEdit = saleReps.find(saleRep => saleRep['id'] === saleRepId);
    setSelectedSaleRep(saleRepToEdit);
    setShowModal(true);
  };

  const confirmDelete = saleRepId => {
    setSaleRepIdToDelete(saleRepId);
    setShowDeleteConfirmation(true);
  };

  const handleDelete = async () => {
    if (saleRepIdToDelete.id === user.id) {
      setShowDeleteConfirmation(false);
      setErrorMessage('لا تستطيع حذف الحساب الخاص بك');
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
      return;
    }

    try {
      await http.delete(`/api/sale-reps/${saleRepIdToDelete}`);
      setShowDeleteConfirmation(false);
      setSuccessMessage('تم الحذف بنجاح!');
      refreshData();
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    } catch (error) {
      console.error('Error deleting sale rep:', error);
      setErrorMessage('حدث خطأ أثناء محاولة الحذف');
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    }
  };

  const renderAddButton = () => {
    return (
      <button
        onClick={() => {
          setSelectedSaleRep(null);
          setShowModal(true);
        }}
        className='flex items-center px-4 py-2 bg-violet-500 hover:bg-violet-700 text-white rounded-md'
      >
        <AiOutlinePlus className='mr-2' /> إضافة مندوب مبيعات
      </button>
    );
  };

  return (
    <>
      {successMessage && (
        <div className='mb-4 p-2 bg-green-400 font-bold text-center text-green-800 rounded'>
          {successMessage}
        </div>
      )}

      {errorMessage && (
        <div className='mb-4 p-2 bg-red-400 font-bold text-center text-gray-100 rounded'>
          {errorMessage}
        </div>
      )}

      <div className='p-5 bg-gradient-to-r from-blue-200/[0.8] to-blue-100/[0.8] dark:from-gray-800/[0.8] dark:to-gray-900/[0.8] rounded-xl shadow-xl'>
        {showModal && (
          <SaleRepModalForm
            isOpen={showModal}
            saleRep={selectedSaleRep}
            onClose={() => setShowModal(false)}
            refreshData={refreshData}
            setSuccessMessage={setSuccessMessage}
          />
        )}

        {showDeleteConfirmation && (
          <DeleteConfirmationModal
            onDelete={handleDelete}
            onCancel={() => setShowDeleteConfirmation(false)}
          />
        )}

        <div className='overflow-auto'>
          <TableComponent
            sectionName={'SaleReps'}
            data={saleReps}
            headers={headers}
            onDelete={confirmDelete}
            onEdit={handleEditClick}
            renderAddButton={renderAddButton}
          />
        </div>
      </div>
    </>
  );
};

export default SaleRepComponent;
