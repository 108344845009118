import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconOffers } from '../../assets/img/icons/index';
import OfferForm from './OfferForm';
import { AiOutlinePlus } from 'react-icons/ai';
import SectionHeader from '../Tools/SectionHeader';
import TableComponent from '../Tools/TableComponent';
import useAuth from '../Auth/Authentication/AuthUser';

const OfferManagement = () => {
  const { http } = useAuth();
  const [error, setError] = useState('');
  const [offers, setOffers] = useState([]);
  const [isOfferModalOpen, setIsOfferModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(1); // 1 for active offers by default
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  // Fetch offers from API
  const fetchOffers = async () => {
    setLoading(true);
    try {
      const response = await http.get('/api/offers');
      setOffers(response.data);
    } catch (error) {
      console.error('Failed to fetch offers:', error);
      setError('فشل في جلب العروض. يرجى المحاولة مرة أخرى.');
    } finally {
      setLoading(false);
    }
  };

  // Fetch offers on component mount
  useEffect(() => {
    fetchOffers();
  }, []);

  // Toggle the modal for adding an offer
  const toggleOfferModal = () => {
    setIsOfferModalOpen(!isOfferModalOpen);
    fetchOffers();
  };

  // Format date to a readable format
  const formatDate = dateString => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-CA');
  };

  // Filter offers based on active tab (1: active, 2: inactive, 3: converted)
  const filteredOffers = offers.filter(offer => {
    switch (activeTab) {
      case 1:
        return offer.status === 'active';
      case 2:
        return offer.status === 'inactive';
      case 3:
        return offer.status === 'converted';
      default:
        return [];
    }
  });

  // Custom renderers for specific fields in the table
  const customRenderers = {
    customer_id: row =>
      row.customer ? row.customer.name : 'No customer assigned',
    sale_rep_id: row =>
      row.sale_rep ? row.sale_rep.name : 'No sales rep assigned',
    created_by: row => (row.creator ? row.creator.name : 'No creator assigned'),
    offer_date: row => formatDate(row.offer_date),
    valid_until: row => formatDate(row.valid_until),
    status: row => {
      const statusColors = {
        active: 'text-green-500',
        inactive: 'text-red-500',
        converted: 'text-blue-500',
      };
      return (
        <span className={`font-bold ${statusColors[row.status]}`}>
          {row.status}
        </span>
      );
    },
  };

  // Table headers configuration
  const headers = [
    { key: 'id', text: 'ID' },
    { key: 'offer_number', text: 'رقم العرض' },
    {
      key: 'offer_date',
      text: 'تاريخ العرض',
      render: customRenderers.offer_date,
    },
    { key: 'customer_id', text: 'العميل', render: customRenderers.customer_id },
    {
      key: 'sale_rep_id',
      text: 'المندوب المبيعات',
      render: customRenderers.sale_rep_id,
    },
    {
      key: 'created_by',
      text: 'محرر العرض',
      render: customRenderers.created_by,
    },
    {
      key: 'valid_until',
      text: 'تاريخ الانتهاء',
      render: customRenderers.valid_until,
    },
    { key: 'status', text: 'الحالة', render: customRenderers.status }, // Render status with color logic
  ];

  // Handle selection of an offer (navigate to offer details)
  const handleOfferSelect = offer => {
    navigate(`/offers/${offer}`);
  };

  // Render the add button for adding new offers
  const renderAddButton = () => (
    <button
      onClick={toggleOfferModal}
      className='flex items-center px-4 py-2 bg-violet-500 rounded-xl hover:bg-violet-700 text-white'
    >
      <AiOutlinePlus className='mr-2' /> إضافة عرض سعر
    </button>
  );

  // Tab buttons for filtering offers
  const tabButtons = [
    { id: 1, label: 'نشطة' },
    { id: 2, label: 'غير نشطة' },
    { id: 3, label: 'محولة' },
  ];

  return (
    <div className='container mx-auto px-4'>
      <SectionHeader imageSrc={IconOffers} sectionTitle='قائمة العروض'>
        <div className='flex justify-between'>
          {tabButtons.map(tab => (
            <button
              key={tab.id}
              aria-selected={activeTab === tab.id}
              className={`px-4 py-2 font-bold ${activeTab === tab.id ? 'text-green-700 dark:text-green-300' : 'text-yellow-500 dark:text-yellow-300'}`}
              onClick={() => setActiveTab(tab.id)} // Change active tab on click
            >
              {tab.label}
            </button>
          ))}
        </div>
      </SectionHeader>

      <div className='mt-8'>
        {loading ? (
          <div className='text-center'>جاري التحميل...</div>
        ) : error ? (
          <div className='text-center text-red-500'>{error}</div>
        ) : (
          <TableComponent
            onView={handleOfferSelect}
            customRenderers={customRenderers}
            data={filteredOffers} // Filtered offers based on the active tab
            renderAddButton={renderAddButton}
            headers={headers}
            sectionName={'Offers'}
          />
        )}
      </div>

      {isOfferModalOpen && (
        <OfferForm
          refetchOffers={fetchOffers}
          isOpen={isOfferModalOpen}
          onClose={() => {
            toggleOfferModal();
            setSuccessMessage(''); // Reset success message on close
          }}
          setSuccessMessage={setSuccessMessage}
        />
      )}

      {successMessage && (
        <div className='mb-4 p-2 bg-green-400 font-bold text-center text-green-800 rounded'>
          {successMessage}
        </div>
      )}
    </div>
  );
};

export default OfferManagement;
