import React from 'react';
import Notifications from '../../Tools/DropdownNotifications';
import UserMenu from '../../Tools/DropdownProfile';
import ThemeToggle from '../../Tools/ThemeToggle';
import HeaderToggle from './HeaderToggle';
import { useSidebar } from '../../../utils/SidebarContext';

function Header({ variant = 'default' }) {

  return (
    <div
      className={`sticky top-0 z-40 flex-none bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700 transition-all duration-300`}
    >
      <div className='px-4 sm:px-6 lg:px-12'>
        <div
          className={`flex flex-wrap items-center justify-between h-16 ${variant === 'v2' || variant === 'v3' ? '' : 'lg:border-b border-gray-200 dark:border-gray-700/60'}`}
        >
          {/* زر التبديل الجانبي */}
          <HeaderToggle />

          {/* التنقل داخل الهيدر */}
          <nav className='flex items-center space-x-3 space-x-reverse'>
            {/* إشعارات */}
            <Notifications align='right' />

            {/* التبديل بين الوضع الليلي والنهاري */}
            <ThemeToggle />

            {/* فاصل */}
            <hr className='w-px h-6 bg-gray-200 dark:bg-gray-700/60 border-none' />

            {/* قائمة المستخدم */}
            <UserMenu align='right' />
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Header;
