// GradualText component
import React from 'react';
import { useSpring, animated } from '@react-spring/web';

const GradualText = ({ text }) => {
  const fade = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 1000, // Delays the start
    config: { duration: 3000 }, // Duration
  });

  return (
    <animated.div
      style={fade}
      className='brand-logo mb-2 font-bold text-center '
    >
      {text}
    </animated.div>
  );
};

export default GradualText;
