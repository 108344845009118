import React, { useState, useEffect } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import useAuth from '../../Auth/Authentication/AuthUser';
import API_CONFIG from '../../../ApiConfig';
import SocialRepModalForm from './SocialRepModalForm';
import TableComponent from '../../Tools/TableComponent';
import DeleteConfirmationModal from '../DeleteConfirmationModal';

const SocialRepComponent = ({ refreshData, socialReps }) => {
  const [selectedSocialRep, setSelectedSocialRep] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [socialRepIdToDelete, setSocialRepIdToDelete] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { http, user } = useAuth();

  const handleEditClick = socialRepId => {
    const socialRepToEdit = socialReps.find(
      socialRep => socialRep.id === socialRepId
    );
    setSelectedSocialRep(socialRepToEdit);
    setShowModal(true);
  };

  const confirmDelete = socialRepId => {
    setSocialRepIdToDelete(socialRepId);
    setShowDeleteConfirmation(true);
  };

  const handleDelete = async () => {
    if (socialRepIdToDelete === user.id) {
      setShowDeleteConfirmation(false);
      setErrorMessage('لا تستطيع حذف الحساب الخاص بك');
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
      return;
    }

    try {
      await http.delete(`/api/social-reps/${socialRepIdToDelete}`);
      setShowDeleteConfirmation(false);
      setSuccessMessage('تم الحذف بنجاح!');
      refreshData(); // Refresh the data
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    } catch (error) {
      console.error('Error deleting social rep:', error);
      setErrorMessage('حدث خطأ أثناء محاولة الحذف');
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    }
  };

  const renderAddButton = () => (
    <button
      onClick={() => {
        setSelectedSocialRep(null);
        setShowModal(true);
      }}
      className='flex items-center px-4 py-2 bg-violet-500 hover:bg-violet-700 text-white rounded-md'
    >
      <AiOutlinePlus className='mr-2' /> إضافة مندوب تسويق
    </button>
  );

  return (
    <>
      {successMessage && (
        <div className='mb-4 p-2 bg-green-400 font-bold text-center text-green-800 rounded'>
          {successMessage}
        </div>
      )}

      {errorMessage && (
        <div className='mb-4 p-2 bg-red-400 font-bold text-center text-gray-100 rounded'>
          {errorMessage}
        </div>
      )}

      <div className='p-5 bg-gradient-to-r from-violet-200 to-purple-100 dark:from-gray-800 dark:to-gray-900 rounded-xl shadow-xl'>
        {showModal && (
          <SocialRepModalForm
            isOpen={showModal}
            socialRep={selectedSocialRep}
            onClose={() => setShowModal(false)}
            refreshData={refreshData}
            setSuccessMessage={setSuccessMessage}
          />
        )}

        {showDeleteConfirmation && (
          <DeleteConfirmationModal
            onDelete={handleDelete}
            onCancel={() => setShowDeleteConfirmation(false)}
          />
        )}

        <div className='overflow-auto'>
          <TableComponent
            data={socialReps}
            headers={[
              { key: 'id', text: 'ID' },
              { key: 'name', text: 'الاسم' },
              { key: 'phone', text: 'رقم الهاتف' },
              { key: 'image', text: 'الصورة' },
            ]}
            onDelete={confirmDelete}
            onEdit={handleEditClick}
            renderAddButton={renderAddButton}
            sectionName={'SocialReps'}
          />
        </div>
      </div>
    </>
  );
};

export default SocialRepComponent;
