import React, { useState, useEffect } from 'react';
import ProductModalForm from './Products/ProductModalForm';
import DeleteConfirmationModal from './Tools/DeleteConfirmationModal';
import CategoryModalForm from './Categories/CategoryModalForm';
import { AiOutlinePlus } from 'react-icons/ai';
import CategoryItem from './Categories/CategoryItem';
import ProductItem from './Products/ProductItem';
import { IconProducts } from '../../assets/img/icons/index';
import SectionHeader from '../Tools/SectionHeader';
import {
  fetchCategories,
  fetchProducts,
  deleteCategory,
  deleteProduct,
} from '../Routes/api';
import useAuth from '../Auth/Authentication/AuthUser';

const ProductAndcategoryManager = () => {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [showProductModal, setShowProductModal] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [deleteTarget, setDeleteTarget] = useState({ type: '', id: null });
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [visibleCategories, setVisibleCategories] = useState(4);
  const { http } = useAuth();
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setError('');
      setSuccessMessage('');
    }, 3000);

    return () => clearTimeout(timer);
  }, [error, successMessage]);

  const fetchData = async () => {
    try {
      const categoriesData = await fetchCategories(http);
      setCategories(categoriesData);
      const productsData = await fetchProducts(http);
      setProducts(productsData);
      setError('');
    } catch (error) {
      setError('فشل في جلب البيانات. يرجى المحاولة مرة أخرى.');
    }
  };

  const refreshData = async () => {
    await fetchData();
  };

  const openProductForm = (product = null) => {
    setSelectedProduct(product);
    setShowProductModal(true);
  };

  const openCategoryForm = (category = null) => {
    setSelectedCategory(category);
    setSelectedCategoryId(category ? category.id : null);
    setShowCategoryModal(true);
  };

  const handleSelectCategory = categoryId => {
    setSelectedCategoryId(categoryId);
  };

  const promptDelete = (type, id) => {
    setDeleteTarget({ type, id });
    setShowDeleteConfirmation(true);
  };

  const handleDelete = async () => {
    const { type, id } = deleteTarget;
    if (!id) {
      setError('خطأ: لم يتم توفير معرف للحذف.');
      return;
    }

    try {
      if (type === 'category') {
        await deleteCategory(http, id);
        setSuccessMessage('تم حذف التصنيف بنجاح.');
      } else {
        await deleteProduct(http, id);
        setSuccessMessage('تم حذف المنتج بنجاح.');
      }
      setShowDeleteConfirmation(false);
      refreshData();
    } catch (error) {
      setError(
        `حدث خطأ أثناء حذف ${type === 'category' ? 'التصنيف' : 'المنتج'}. يرجى المحاولة مرة أخرى.`
      );
    }
  };

  const displayMoreCategories = () => {
    setVisibleCategories(prevVisible => prevVisible + 4);
  };

  return (
    <div className='container mx-auto px-4 py-6'>
      <SectionHeader imageSrc={IconProducts} sectionTitle='المتجر' />

      {error && (
        <div
          className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4'
          role='alert'
        >
          <span className='block'>{error}</span>
        </div>
      )}

      {successMessage && (
        <div
          className='bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4'
          role='alert'
        >
          <span className='block'>{successMessage}</span>
        </div>
      )}

      <div className='mb-8'>
        <div className='border-b-4 border-violet-500 py-2'>
          <h3 className='text-2xl md:text-3xl text-center font-bold text-violet-500 mb-2'>
            التصنيفات
          </h3>
        </div>

        <button
          onClick={() => openCategoryForm(null)}
          className='px-4 py-2 bg-violet-500 text-white rounded hover:bg-violet-700 transition ease-in-out duration-200'
        >
          <AiOutlinePlus className='inline mr-2' /> إضافة تصنيف
        </button>

        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-4'>
          {categories.slice(0, visibleCategories).map(category => (
            <CategoryItem
              key={category.id}
              category={category}
              onSelect={() => handleSelectCategory(category.id)}
              onEdit={() => openCategoryForm(category)}
              onDelete={() => promptDelete('category', category.id)}
            />
          ))}
        </div>

        {visibleCategories < categories.length && (
          <div className='text-center mt-4'>
            <button
              onClick={displayMoreCategories}
              className='px-4 py-2 bg-violet-500 text-white rounded hover:bg-violet-700 transition ease-in-out duration-200'
            >
              عرض المزيد
            </button>
          </div>
        )}
      </div>

      {selectedCategoryId && (
        <div className='mt-8'>
          <div className='border-b-4 border-green-500 py-2'>
            <h3 className='text-2xl md:text-3xl text-center font-bold text-green-500 mb-2'>
              منتجات التصنيف
            </h3>
          </div>

          <div className='flex justify-between mb-4'>
            <button
              onClick={() => openProductForm()}
              className='px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700 transition ease-in-out duration-200'
            >
              <AiOutlinePlus className='inline mr-2' /> إضافة منتج
            </button>
          </div>

          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4'>
            {products.filter(
              product => product.category_id === selectedCategoryId
            ).length > 0 ? (
              products
                .filter(product => product.category_id === selectedCategoryId)
                .map(product => (
                  <ProductItem
                    key={product.id}
                    product={product}
                    onEdit={() => {
                      setShowProductModal(true);
                      setSelectedProduct(product);
                    }}
                    onDelete={() => promptDelete('product', product.id)}
                  />
                ))
            ) : (
              <div className='w-full text-center'>
                لا يوجد منتجات لهذا التصنيف
              </div>
            )}
          </div>
        </div>
      )}

      {showProductModal && (
        <ProductModalForm
          isOpen={showProductModal}
          product={selectedProduct}
          onClose={() => setShowProductModal(false)}
          refreshProducts={refreshData}
        />
      )}

      {showDeleteConfirmation && (
        <DeleteConfirmationModal
          onDelete={handleDelete}
          onCancel={() => setShowDeleteConfirmation(false)}
        />
      )}

      {showCategoryModal && (
        <CategoryModalForm
          isOpen={showCategoryModal}
          category={selectedCategory}
          onClose={() => setShowCategoryModal(false)}
          refreshCategories={refreshData}
        />
      )}
    </div>
  );
};

export default ProductAndcategoryManager;
