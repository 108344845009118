import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  greeting: {
    textAlign: 'center',
    marginBottom: 15,
  },
});

const OfferThankYouMsg = () => (
  <>
    <Text style={styles.greeting}>السلام عليكم ورحمة الله وبركاتة</Text>
    <Text style={styles.greeting}>
      نتقدم اليكم بعرض سعرنا لتوريد الموضح بالجدول أدناه آملين أن يحوز على رضاكم
      واستحسانكم
    </Text>
  </>
);

export default OfferThankYouMsg;
