import React, { useState, useEffect } from 'react';
import useAuth from '../Auth/Authentication/AuthUser';
import {
  IoMdContact,
  IoMdMail,
  IoMdPin,
  IoMdCall,
  IoMdPerson,
  IoMdGlobe,
  IoMdCreate,
} from 'react-icons/io';
import { RiUserAddFill } from 'react-icons/ri';
import { validatePhoneNumber, validateForm } from '../Tools/Validation'; // Adjust the path as needed

const CustomerModalForm = ({
  customer,
  onClose,
  refreshCustomers,
  isOpen,
  setSuccessMessage,
}) => {
  const { http } = useAuth();
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    phone: '',
    gender: '',
    email: '',
    city: '',
    country: '',
    notes: '',
  });
  const [error, setError] = useState('');

  useEffect(() => {
    if (customer) {
      setFormData({
        name: customer.name || '',
        address: customer.address || '',
        phone: customer.phone || '',
        gender: customer.gender || '',
        email: customer.email || '',
        city: customer.city || '',
        country: customer.country || '',
        notes: customer.notes || '',
      });
    }
  }, [customer]);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setError('');
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const errors = validateForm(formData);
    const phoneError = validatePhoneNumber(formData.phone);
    if (phoneError) errors.phone = phoneError;

    console.log('Form Data:', formData); // Debugging line
    console.log('Errors:', errors); // Debugging line

    if (Object.keys(errors).length > 0) {
      setError('Please ensure all required fields are filled out correctly.');
      console.log('Validation Errors:', errors); // Log detailed errors
      return;
    }

    const method = customer?.id ? 'put' : 'post';
    const url = customer?.id
      ? `/api/customers/${customer.id}`
      : '/api/customers';

    try {
      await http[method](url, formData);
      refreshCustomers();
      onClose();
      setSuccessMessage(
        customer?.id ? 'تم تحديث العميل بنجاح.' : 'تم إضافة العميل بنجاح.'
      );
    } catch (error) {
      console.error('Error saving customer:', error);
      setError('An error occurred while saving the customer.');
    }
  };

  if (!isOpen) return null;

  const formFields = [
    {
      id: 'name',
      name: 'name',
      type: 'text',
      placeholder: 'الاسم',
      icon: IoMdContact,
      required: true,
    },
    {
      id: 'email',
      name: 'email',
      type: 'email',
      placeholder: 'البريد الإلكتروني (اختياري)',
      icon: IoMdMail,
      required: false,
    },
    {
      id: 'address',
      name: 'address',
      type: 'text',
      placeholder: 'العنوان',
      icon: IoMdPin,
      required: true,
    },
    {
      id: 'phone',
      name: 'phone',
      type: 'tel',
      placeholder: 'رقم الهاتف',
      icon: IoMdCall,
      required: true,
    },
    {
      id: 'gender',
      name: 'gender',
      type: 'select',
      placeholder: 'الجنس',
      icon: IoMdPerson,
      required: true,
    },
    {
      id: 'city',
      name: 'city',
      type: 'text',
      placeholder: 'المدينة',
      icon: IoMdCreate,
      required: true,
    },
    {
      id: 'country',
      name: 'country',
      type: 'text',
      placeholder: 'الدولة',
      icon: IoMdGlobe,
      required: true,
    },
    {
      id: 'notes',
      name: 'notes',
      type: 'text',
      placeholder: 'الملاحظات (اختياري)',
      icon: IoMdCreate,
      required: false,
    },
  ];

  return (
    <div
      className={`fixed inset-0 z-50 overflow-y-auto ${!isOpen ? 'hidden' : ''}`}
    >
      <div className='flex items-center justify-center min-h-screen'>
        <div
          className='fixed inset-0 bg-gray-600 bg-opacity-50 transition-opacity'
          onClick={onClose}
        ></div>
        <div className='bg-white dark:bg-gray-800 rounded-lg shadow-xl transform transition-all sm:max-w-lg sm:w-full p-6'>
          <div className='text-right'>
            <button
              onClick={onClose}
              className='text-gray-400 hover:text-gray-600 transition ease-in-out duration-150'
            >
              &times;
            </button>
          </div>
          <form onSubmit={handleSubmit} className='space-y-6'>
            <h3 className='text-xl font-medium border-b pb-4 shadow-sm text-violet-900 dark:text-violet-300 flex items-center'>
              <RiUserAddFill className='text-4xl text-violet-600 dark:text-violet-300 mr-2' />
              إضافة عميل
            </h3>
            {formFields.map(
              ({ id, name, type, placeholder, icon: Icon, required }) => (
                <div
                  key={id}
                  className='flex items-center border-b border-gray-300 py-2'
                >
                  <Icon className='text-lg text-gray-800 dark:text-violet-600 mr-2' />
                  {type !== 'select' ? (
                    <input
                      type={type}
                      name={name}
                      id={id}
                      placeholder={placeholder}
                      value={formData[name]}
                      onChange={handleChange}
                      required={required}
                      className='appearance-none bg-transparent border-none w-full bg-gray-200 dark:bg-gray-200 text-violet-600 dark:text-gray-600 mr-3 py-1 px-2 text-right leading-tight focus:outline-none'
                    />
                  ) : (
                    <select
                      name={name}
                      id={id}
                      value={formData[name]}
                      onChange={handleChange}
                      required={required}
                      className='appearance-none bg-transparent border-none w-full bg-gray-200 dark:bg-gray-200 text-violet-600 dark:text-gray-600 mr-3 py-1 px-2 leading-tight focus:outline-none'
                    >
                      <option value=''>{placeholder}</option>
                      <option value='ذكر'>ذكر</option>
                      <option value='أنثى'>أنثى</option>
                    </select>
                  )}
                </div>
              )
            )}
            {error && <div className='text-red-500 text-sm'>{error}</div>}
            <div className='flex justify-end pt-2'>
              <button
                type='submit'
                className='inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-violet-600 hover:bg-violet-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
              >
                {customer ? 'تحديث' : 'حفظ'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CustomerModalForm;
