import React, { useState, useEffect } from 'react';
import axios from 'axios';
import API_CONFIG from '../../ApiConfig';
import TableComponent from '../Tools/TableComponent';
import { ProjectSection } from '../../assets/img/index';
import SectionHeader from '../Tools/SectionHeader';
import DesignModalForm from './DesignModalForm';

import { AiOutlinePlus } from 'react-icons/ai'; // Example icon
const DesignList = () => {
  const [designs, setDesigns] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedDesign, setSelectedDesign] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [designIdToDelete, setDesignIdToDelete] = useState(null);

  useEffect(() => {
    fetchDesigns();
  }, []);

  const fetchDesigns = async () => {
    try {
      const response = await axios.get(`${API_CONFIG.baseURL}/api/designs`);
      const transformedData = response.data.map(design => ({
        id: design.id,
        name: design.name,
        phone: design.phone,
        email: design.email,
        country: design.country,
        city: design.city,
        notes: design.notes,
        address: design.address,
        gender: design.gender,
        last_update: design.last_update,
      }));

      setDesigns(transformedData);
    } catch (error) {
      console.error('Failed to fetch designs:', error);
    }
  };

  const headers = [
    { key: 'id', text: 'id' },
    { key: 'name', text: 'الاسم' },
    { key: 'phone', text: 'رقم الهاتف' },
    { key: 'country', text: 'الدولة' },
    { key: 'gender', text: 'الجنس' },
  ];

  const refreshDesigns = () => {
    fetchDesigns();
  };

  const handleEditClick = designId => {
    const designToEdit = designs.find(design => design['id'] === designId);
    setSelectedDesign(designToEdit);
    setShowModal(true);
  };
  const promptDeleteDesign = designId => {
    setDesignIdToDelete(designId);
    setShowDeleteConfirmation(true);
  };

  const handleDeleteConfirmation = designId => {
    promptDeleteDesign(designId);
  };

  const handleDeleteClick = async designId => {
    try {
      await axios.delete(`${API_CONFIG.baseURL}/api/designs/${designId}`);
      refreshDesigns();
    } catch (error) {
      console.error('Error deleting design:', error);
    }
  };
  const renderAddButton = () => {
    return (
      <button
        onClick={() => {
          setSelectedDesign(null);
          setShowModal(true);
        }}
        className='flex items-center px-4 py-2 bg-violet-500 hover:bg-violet-700 text-white rounded-md'
      >
        <AiOutlinePlus className='mr-2' /> إضافة تصميم
      </button>
    );
  };

  return (
    <div
      className={`container mx-auto h-full px-4 pt-10 ${showModal ? 'modal-active' : ''}`}
    >
      {showModal && (
        <DesignModalForm
          isOpen={showModal}
          design={selectedDesign}
          onClose={() => setShowModal(false)}
          refreshDesigns={refreshDesigns}
        />
      )}
      {showDeleteConfirmation && (
        <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center'>
          <div className='bg-white p-4 rounded-lg shadow-lg text-center'>
            <h2 className='font-semibold text-lg mb-4'>تأكيد الحذف</h2>
            <p>هل أنت متأكد أنك تريد حذف هذا المشروع</p>
            <div className='flex justify-around mt-4'>
              <button
                className='bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700'
                onClick={() => {
                  handleDeleteClick(designIdToDelete);
                  setShowDeleteConfirmation(false);
                }}
              >
                حذف
              </button>
              <button
                className='bg-gray-500 text-gray-900 dark:bg-gray-600 dark:text-violet-300 px-4 py-2 rounded hover:bg-gray-700'
                onClick={() => setShowDeleteConfirmation(false)}
              >
                إلغاء
              </button>
            </div>
          </div>
        </div>
      )}

      <SectionHeader imageSrc={ProjectSection} sectionTitle='قائمة المشروعات' />

      <div className='overflow-auto'>
        <TableComponent
          data={designs}
          headers={headers}
          onDelete={handleDeleteConfirmation}
          onEdit={handleEditClick}
          renderAddButton={renderAddButton}
          sectionName={'Designs'}
        />
      </div>
    </div>
  );
};

export default DesignList;
