import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import OfferPricePDF from './OfferPdf/OfferPricePDF';
import {
  FaCheckCircle,
  FaUserAlt,
  FaHandshake,
  FaPercentage,
  FaInfo,
  FaMinus,
  FaTicketAlt,
  FaMoneyCheckAlt,
  FaFileInvoice,
  FaMoneyCheck,
  FaTimes,
  FaReceipt,
  FaAlipay,
  FaMoneyBillAlt,
  FaRegClock,
  FaCalendarAlt,
  FaPenFancy,
  FaTags,
  FaMoneyBillWave,
} from 'react-icons/fa';
import useAuth from '../Auth/Authentication/AuthUser';
import ShowSpinner from '../Tools/ShowSpinner';
import OfferConvertForm from './Tools/OfferConvertForm';

const OfferDetails = () => {
  const { offer } = useParams();
  const [offerData, setOfferData] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { http } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchInitialData() {
      try {
        const offerResponse = await http.get(`/api/offers/${offer}`);
        const productsResponse = await http.get('/api/products');
        const offerData = offerResponse.data;
        const products = productsResponse.data;

        if (
          offerData.total &&
          offerData.tax_rate &&
          offerData.discount_rate &&
          offerData.total_final
        ) {
          const totalBeforeDiscount = parseFloat(
            offerData.total + offerData.tax_amount
          );
          const taxRate = parseFloat(offerData.tax_rate) / 100;
          const discountRate = parseFloat(offerData.discount_rate) / 100;

          if (
            !isNaN(totalBeforeDiscount) &&
            !isNaN(taxRate) &&
            !isNaN(discountRate)
          ) {
            const taxAmount = totalBeforeDiscount * taxRate;
            const discountAmount = totalBeforeDiscount * discountRate;
            const totalPriceAfterTaxAndDiscount =
              totalBeforeDiscount - discountAmount;

            setOfferData({
              ...offerData,
              total_before_discount: totalBeforeDiscount,
              tax_amount: taxAmount,
              discount_amount: discountAmount,
              totalPriceAfterTaxAndDiscount: totalPriceAfterTaxAndDiscount,
            });
          } else {
            console.error('Failed to parse numbers correctly');
            setError({ message: 'فشل في تحليل بيانات العرض بشكل صحيح' });
          }
        } else {
          console.error('Missing necessary data for calculations');
          setError({ message: 'بيانات العرض غير كاملة للحسابات' });
        }

        setProducts(products);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch data:', error);
        setError(error);
        setLoading(false);
      }
    }

    fetchInitialData();
  }, [offer]);

  const formatDate = dateString => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-CA');
  };

  const formatMoney = amount => {
    return parseFloat(amount).toFixed(2);
  };

  const getProductDetailsWithId = id => {
    const product = products.find(product => product.id === id);
    return product
      ? {
          name: product.name,
          price: product.price,
          category: product.category?.name,
        }
      : null;
  };

  const getLabelByMethod = (method, methods) => {
    const item = methods.find(m => m.method === method);
    return item ? item.label : 'غير معروف';
  };

  const getLabelByType = (type, types) => {
    const item = types.find(t => t.type === type);
    return item ? item.label : 'غير معروف';
  };

  const getLabelByStatus = (type, types) => {
    const item = types.find(t => t.type === type);
    return item ? item.label : 'غير معروف';
  };

  const paymentMethods = [
    { method: 'cash', label: 'نقداً' },
    { method: 'bankTransfer', label: 'تحويل بنكي' },
    { method: 'visa', label: 'دفع فيزا' },
  ];

  const paymentTypes = [
    { type: 'full', label: 'سداد كامل' },
    { type: 'partial', label: 'سداد جزئي' },
  ];

  const statusTypes = [
    { type: 'active', label: 'ساري' },
    { type: 'inactive', label: 'غير ساري' },
    { type: 'converted', label: 'تم التحويل' },
  ];

  const getOrderIdByOfferId = async offerId => {
    try {
      const response = await http.get(`/api/orders/by-offer/${offerId}`);
      return response.data.orderId;
    } catch (error) {
      console.error('Failed to fetch orderId:', error);
      return null;
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = async () => {
    if (offerData.status === 'converted') {
      const orderId = await getOrderIdByOfferId(offerData.id);
      if (orderId) {
        navigate(`/orders/${orderId}`);
      } else {
        alert('Failed to retrieve the order details.');
      }
    } else {
      setShowModal(true);
    }
  };

  if (loading) {
    return <div>جاري التحميل...</div>;
  }

  if (error) {
    return <div>خطأ: {error.message}</div>;
  }

  return (
    <div className='container mx-auto h-full px-4 pt-10'>
      <div className='mt-4 neon-shadow bg-gradient-to-br from-purple-900 to-blue-900 dark:from-green-700 dark:to-blue-900 p-8 rounded-3xl text-white animate-opacity'>
        <Link
          to='/offers'
          className='text-yellow-300 mb-4 block text-right hover:underline'
        >
          <button className='bg-cyan-700 px-4 py-2 rounded-full shadow-lg hover:scale-105 transition duration-300 hover:bg-cyan-800 hover:text-yellow-300 font-bold text-yellow-400'>
            العودة للعروض
          </button>
        </Link>
        <h2 className='text-3xl font-bold text-center mb-10 text-yellow-300'>
          تفاصيل العرض
        </h2>
        <div className='mb-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6 text-center'>
          {[
            {
              label: 'رقم العرض',
              value: offerData.offer_number,
              icon: FaTicketAlt,
            },
            {
              label: 'تاريخ العرض',
              value: formatDate(offerData.offer_date),
              icon: FaCalendarAlt,
            },
            {
              label: 'العميل',
              value: offerData.customer?.name,
              icon: FaUserAlt,
            },
            {
              label: 'المندوب',
              value: offerData.sale_rep?.name,
              icon: FaHandshake,
            },
            {
              label: 'حالة العرض',
              value: getLabelByStatus(offerData.status, statusTypes),
              icon: FaCheckCircle,
            },
          ].map((item, index) => (
            <div
              key={index}
              className='flex flex-col items-center justify-center p-4 bg-gray-800 rounded-lg shadow-lg'
            >
              <item.icon className='text-3xl sm:text-4xl md:text-5xl text-blue-500 mb-2' />
              <div className='text-center'>
                <span className='block text-base sm:text-lg md:text-xl text-yellow-300 mb-1'>
                  {item.label}
                </span>
                <span className='block text-sm sm:text-base md:text-lg text-white'>
                  {item.value}
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className='justify-center mb-2 overflow-x-auto'>
          <table className='min-w-full divide-y divide-gray-200 mb-6'>
            <thead>
              <tr className='font-semibold text-gray-900 dark:text-white bg-gray-50 dark:bg-gray-700 uppercase tracking-wider'>
                <th className='px-3 py-2 border border-gray-300 shadow-lg text-center text-xs sm:text-sm'>
                  #
                </th>
                <th className='px-3 py-2 border border-gray-300 shadow-lg text-center text-xs sm:text-sm'>
                  المنتج
                </th>
                <th className='px-3 py-2 border border-gray-300 shadow-lg text-center text-xs sm:text-sm'>
                  التصنيف
                </th>
                <th className='px-3 py-2 border border-gray-300 shadow-lg text-center text-xs sm:text-sm'>
                  الكمية
                </th>
                <th className='px-3 py-2 border border-gray-300 shadow-lg text-center text-xs sm:text-sm'>
                  سعر الوحدة
                </th>
                <th className='px-3 py-2 border border-gray-300 shadow-lg text-center text-xs sm:text-sm'>
                  إجمالي السعر
                </th>
                <th className='px-3 py-2 border border-gray-300 shadow-lg text-center text-xs sm:text-sm'>
                  ملاحظات
                </th>
              </tr>
            </thead>
            <tbody>
              {offerData.products.map((product, index) => {
                const productDetails = getProductDetailsWithId(
                  product.product_id
                );
                return (
                  <tr
                    key={index}
                    className={`text-sm font-medium text-gray-700 dark:text-gray-300 ${index % 2 === 0 ? 'bg-gray-50 dark:bg-gray-700' : 'bg-gray-100 dark:bg-gray-600'}`}
                  >
                    <td className='px-3 py-2 border border-gray-200 shadow-lg text-center text-violet-500 dark:text-yellow-300'>
                      {index + 1}
                    </td>
                    <td className='px-3 py-2 border border-gray-200 shadow-lg text-center text-violet-500 dark:text-yellow-300'>
                      {productDetails?.name}
                    </td>
                    <td className='px-3 py-2 border border-gray-200 shadow-lg text-center'>
                      {productDetails?.category}
                    </td>
                    <td className='px-3 py-2 border border-gray-200 shadow-lg text-center'>
                      {product.quantity}
                    </td>
                    <td className='px-3 py-2 border border-gray-200 shadow-lg text-center'>
                      {formatMoney(productDetails?.price)}
                    </td>
                    <td className='px-3 py-2 border border-gray-200 shadow-lg text-center'>
                      ${formatMoney(productDetails?.price * product.quantity)}
                    </td>
                    <td className='px-3 py-2 border border-gray-200 shadow-lg text-center'>
                      {product.notes}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
          <div>
            {[
              {
                label: 'الإجمالي قبل الخصم',
                value: `${formatMoney(offerData.total)} ر.س`,
                icon: FaMoneyCheckAlt,
              },
              {
                label: 'قيمة الخصم المطبق',
                value: `${formatMoney(offerData.discount_amount)} ر.س`,
                icon: FaTags,
              },
              {
                label: 'قيمة الضريبة المضافة',
                value: `${formatMoney(offerData.tax_amount)} ر.س`,
                icon: FaMoneyBillAlt,
              },
              {
                label: 'الإجمالي بعد الخصم',
                value: `${formatMoney(offerData.total - offerData.discount_amount)} ر.س`,
                icon: FaMoneyCheck,
              },
              {
                label: 'الإجمالي بعد الضريبة',
                value: `${formatMoney(offerData.total - offerData.discount_amount + offerData.tax_amount)} ر.س`,
                icon: FaMoneyBillAlt,
              },
              {
                label: 'محرر العرض',
                value: offerData.creator?.name,
                icon: FaPenFancy,
              },
            ].map((item, index) => (
              <div key={index} className='mb-4 flex items-center'>
                <item.icon className='text-3xl sm:text-4xl md:text-5xl text-green-500 ml-4' />
                <div className='text-right'>
                  <span className='block text-base sm:text-lg md:text-xl text-yellow-300'>
                    {item.label}:
                  </span>
                  <span className='block text-sm sm:text-base md:text-lg text-white'>
                    {item.value}
                  </span>
                </div>
              </div>
            ))}
          </div>
          <div>
            {[
              {
                label: 'نسبة الخصم المطبق',
                value: `${offerData.discount_rate}%`,
                icon: FaMinus,
              },
              {
                label: 'نسبة الضريبة المضافة',
                value: `${offerData.tax_rate}%`,
                icon: FaReceipt,
              },
              {
                label: 'نظام السدلد',
                value: getLabelByType(offerData.payment_type, paymentTypes),
                icon: FaAlipay,
              },
              {
                label: 'طريقة الدفع',
                value: getLabelByMethod(
                  offerData.payment_method,
                  paymentMethods
                ),
                icon: FaMoneyBillWave,
              },
              {
                label: 'صلاحية العرض',
                value: `حتى تاريخ ${formatDate(offerData.valid_until)}`,
                icon: FaCalendarAlt,
              },
              {
                label: 'مدة التنفيذ',
                value: `${offerData.time_plementation_range} يوم `,
                icon: FaRegClock,
              },
            ].map((item, index) => (
              <div key={index} className='mb-4 flex items-center'>
                <item.icon className='text-3xl sm:text-4xl md:text-5xl text-green-500 ml-4' />
                <div className='text-right'>
                  <span className='block text-base sm:text-lg md:text-xl text-yellow-300'>
                    {item.label}:
                  </span>
                  <span className='block text-sm sm:text-base md:text-lg text-white'>
                    {item.value}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className='relative w-full mt-4 mb-4'>
          <OfferPricePDF
            offerNumber={offerData.offer_number}
            offerDate={formatDate(offerData.offer_date)}
            saleRepName={offerData.sale_rep?.name}
            customerName={offerData.customer?.name}
            total={offerData.total}
            totalFinal={offerData.total_final}
            products={offerData.products}
            productDetails={products}
            validUntil={formatDate(offerData.valid_until)}
            taxAmount={offerData.tax_amount.toFixed(2)}
            paymentType={offerData.payment_type}
            paymentMethod={offerData.payment_method}
            discountAmount={offerData.discount_amount.toFixed(2)}
          />
        </div>

        <div className='text-center mt-8 relative inline-block w-full'>
          <div className='text-right mt-6 p-4'>
            <button
              onClick={openModal}
              className={`bg-green-600 text-white px-4 py-2 rounded-full shadow-lg transition duration-300 ${offerData.status === 'converted' ? 'bg-blue-600 hover:bg-blue-700' : 'hover:bg-green-700'}`}
              style={{
                position: 'absolute',
                bottom: '10px',
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            >
              {offerData.status === 'converted'
                ? 'عرض الطلب'
                : 'تحويل العرض إلى طلب'}
            </button>
          </div>
          {showModal && (
            <OfferConvertForm
              offerData={offerData}
              products={products}
              showModal={showModal}
              closeModal={closeModal}
              setShowModal={setShowModal}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OfferDetails;
