import { useSpring, animated } from '@react-spring/web';
import { Link } from 'react-router-dom';

const DashboardCard = ({ title = '', value = 0, link = '/', Icon }) => {
  const [style, api] = useSpring(() => ({
    scale: 1,
    config: { mass: 1, tension: 210, friction: 20 },
  }));

  return (
    <animated.div
      className='flex neon-shadow items-center justify-center p-3  bg-pink-100 rounded-full transition-transform duration-300 ease-in-out transform hover:scale-110 hover:shadow-lg dark:bg-gray-800
                 w-full h-28 sm:w-full sm:h-32 md:w-full md:h-36 lg:w-full lg:h-40 xl:w-full xl:h-44'
      style={style}
      onMouseEnter={() => api.start({ scale: 1.1 })}
      onMouseLeave={() => api.start({ scale: 1 })}
      role='article'
    >
      <Link
        to={link}
        className='w-full h-full flex items-center justify-between'
      >
        {/* Icon */}
        <div className='flex items-center justify-center relative w-12 h-12 sm:w-14 sm:h-14 md:w-16 md:h-16 lg:w-20 lg:h-20 xl:w-24 xl:h-24 ml-2'>
          <Icon className='text-gray-600 dark:text-gray-200 w-full h-full' />
          {/* Overlay Icon */}
          <div className='absolute inset-0 flex items-center justify-center'>
            <Icon className='text-yellow-400 opacity-75 w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 lg:w-12 lg:h-12 xl:w-14 xl:h-14' />
          </div>
        </div>
        {/* Title and Value */}
        <div className='flex flex-col items-center justify-center text-center ml-2'>
          <h6 className='text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl font-bold tracking-wider text-orange-400 dark:text-green-200'>
            {title}
          </h6>
          <span className='block mt-2 text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl font-semibold text-blue-600 dark:text-orange-300'>
            {value}
          </span>
        </div>
      </Link>
    </animated.div>
  );
};

export default DashboardCard;
