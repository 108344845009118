// api.js
// api.jsx

export const fetchCustomers = async http => {
  try {
    const response = await http.get('/api/customers');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch customers:', error);
    throw error;
  }
};

export const addCustomer = async (http, customerData) => {
  try {
    const response = await http.post('/api/customers', customerData);
    return response.data;
  } catch (error) {
    console.error('Failed to add customer:', error);
    throw error;
  }
};

export const deleteCustomer = async (http, customerId) => {
  try {
    await http.delete(`/api/customers/${customerId}`);
  } catch (error) {
    console.error('Failed to delete customer:', error);
    throw error;
  }
};

export const updateCustomer = async (http, customerId, customerData) => {
  try {
    await http.put(`/api/customers/${customerId}`, customerData);
  } catch (error) {
    console.error('Failed to update customer:', error);
    throw error;
  }
};

export const fetchOffers = async http => {
  try {
    const response = await http.get('/api/offers');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch offers:', error);
    throw error;
  }
};

export const fetchRoles = async http => {
  try {
    const response = await http.get('/api/roles');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch roles:', error);
    throw error;
  }
};
export const fetchOrders = async http => {
  try {
    const response = await http.get('/api/orders');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch orders:', error);
    throw error;
  }
};

export const addOrder = async (http, orderData) => {
  try {
    const response = await http.post('/api/orders', orderData);
    return response.data;
  } catch (error) {
    console.error('Failed to add order:', error);
    throw error;
  }
};

export const updateOrder = async (http, orderId, orderData) => {
  try {
    const response = await http.put(`/api/orders/${orderId}`, orderData);
    return response.data;
  } catch (error) {
    console.error('Failed to update order:', error);
    throw error;
  }
};

export const deleteOrder = async (http, orderId) => {
  try {
    await http.delete(`/api/orders/${orderId}`);
  } catch (error) {
    console.error('Failed to delete order:', error);
    throw error;
  }
};

// social-reps api

export const fetchSocialReps = async http => {
  try {
    const response = await http.get('/api/social-reps');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch socialReps:', error);
    throw error;
  }
};

export const deleteSocialRep = async (http, socialRepId) => {
  try {
    await http.delete(`/api/social-reps/${socialRepId}`);
  } catch (error) {
    console.error('Failed to delete socialRep:', error);
    throw error;
  }
};

export const updateSocialRep = async (http, socialRepId, socialRepData) => {
  try {
    await http.put(`/api/social-reps/${socialRepId}`, socialRepData);
  } catch (error) {
    console.error('Failed to update socialRep:', error);
    throw error;
  }
};

// Sale Reps api

export const fetchSaleReps = async http => {
  try {
    const response = await http.get('/api/sale-reps');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch saleReps:', error);
    throw error;
  }
};

export const deleteSaleRep = async (http, saleRepId) => {
  try {
    await http.delete(`/api/sale-reps/${saleRepId}`);
  } catch (error) {
    console.error('Failed to delete saleRep:', error);
    throw error;
  }
};

export const updateSaleRep = async (http, saleRepId, saleRepData) => {
  try {
    await http.put(`/api/sale-reps/${saleRepId}`, saleRepData);
  } catch (error) {
    console.error('Failed to update saleRep:', error);
    throw error;
  }
};

// designers api

export const fetchDesigners = async http => {
  try {
    const response = await http.get('/api/designers');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch designers:', error);
    throw error;
  }
};

export const deleteDesigner = async (http, designerId) => {
  try {
    await http.delete(`/api/designers/${designerId}`);
  } catch (error) {
    console.error('Failed to delete designer:', error);
    throw error;
  }
};

export const updateDesigner = async (http, designerId, designerData) => {
  try {
    await http.put(`/api/designers/${designerId}`, designerData);
  } catch (error) {
    console.error('Failed to update designer:', error);
    throw error;
  }
};

// Categories API
export const fetchCategories = async http => {
  try {
    const response = await http.get('/api/categories');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch categories:', error);
    throw error;
  }
};

export const addCategory = async (http, categoryData) => {
  try {
    const response = await http.post('/api/categories', categoryData);
    return response.data;
  } catch (error) {
    console.error('Failed to add category:', error);
    throw error;
  }
};

export const updateCategory = async (http, categoryId, categoryData) => {
  try {
    await http.put(`/api/categories/${categoryId}`, categoryData);
  } catch (error) {
    console.error('Failed to update category:', error);
    throw error;
  }
};

export const deleteCategory = async (http, categoryId) => {
  try {
    await http.delete(`/api/categories/${categoryId}`);
  } catch (error) {
    console.error('Failed to delete category:', error);
    throw error;
  }
};

// Products API
export const fetchProducts = async http => {
  try {
    const response = await http.get('/api/products');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch products:', error);
    throw error;
  }
};

export const addProduct = async (http, productData) => {
  try {
    const response = await http.post('/api/products', productData);
    return response.data;
  } catch (error) {
    console.error('Failed to add product:', error);
    throw error;
  }
};

export const updateProduct = async (http, productId, productData) => {
  try {
    await http.put(`/api/products/${productId}`, productData);
  } catch (error) {
    console.error('Failed to update product:', error);
    throw error;
  }
};

export const deleteProduct = async (http, productId) => {
  try {
    await http.delete(`/api/products/${productId}`);
  } catch (error) {
    console.error('Failed to delete product:', error);
    throw error;
  }
};
