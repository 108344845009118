import React, { useState, useEffect } from 'react';
import { BsCashCoin } from 'react-icons/bs';
import {
  FaUserAlt,
  FaHandshake,
  FaShoppingBasket,
  FaFileInvoice,
  FaMoneyBillAlt,
  FaPercentage,
  FaCalendarAlt,
  FaTags,
  FaInfo,
  FaMoneyBillWave,
  FaCreditCard,
  FaHandHoldingUsd,
} from 'react-icons/fa';
import { GiCash } from 'react-icons/gi';
import { HiOutlineBanknotes } from 'react-icons/hi2';
const PaymentMethod = ({
  validUntil,
  totalFinal,
  paymentMethod,
  paymentType,
  setPaymentMethod,
  setPaymentType,
  total,
  discountAmount,
  taxAmount,
}) => {
  return (
    <div className='bg-white dark:bg-gray-800 neon-shadow overflow-x-auto border-0 p-4 sm:p-8 shadow-lg'>
      <div className='grid grid-cols-1 md:grid-cols-1 gap-2'>
        <h2 className='text-3xl bg-gray-200 dark:bg-gray-700 p-4 font-bold text-center mb-10 text-orange-400   dark:text-yellow-300'>
          تفاصيل الدفع
        </h2>

        <div className='shadow-lg p-4 rounded-lg '>
          {[
            {
              label: 'قيمة الضريبة المضافة',
              value: `${taxAmount} ر.س`,
              icon: FaMoneyBillAlt,
            },
            {
              label: 'قيمة الخصم المطبق',
              value: `${discountAmount} ر.س`,
              icon: FaTags,
            },
            {
              label: 'الإجمالي قبل الخصم و الضريبة',
              value: `${total} ر.س`,
              icon: FaFileInvoice,
            },
            {
              label: 'الإجمالي بعد الخصم والضريبة',
              value: `${totalFinal} ر.س`,
              icon: FaFileInvoice,
            },
            {
              label: 'صلاحية العرض',
              value: `حتى تاريخ   ${validUntil}`,
              icon: FaCalendarAlt,
            },
          ].map((item, index) => (
            <div
              key={index}
              className='flex items-center justify-between p-3 hover:bg-gray-200 dark:hover:bg-gray-700 rounded-lg transition duration-300 ease-in-out'
            >
              <span className='font-semibold text-green-500 dark:text-green-400'>
                {item.label}:
                <span className='text-orange-300 dark:text-yellow-300 mr-4'>
                  {item.value}
                </span>
              </span>
              <item.icon className='text-3xl text-orange-500' />
            </div>
          ))}
        </div>
      </div>

      <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
        <div className='shadow-lg p-4 rounded-lg'>
          <h3 className='text-2xl font-bold my-4 text-center dark:text-yellow-400 text-green-500'>
            نظام السداد
          </h3>
          <div className='flex space-x-2 border-[3px]  border-green-500 dark:border-yellow-400 rounded-xl select-none'>
            {[
              { method: 'cash', icon: FaHandHoldingUsd, label: 'نقداً' },
              {
                method: 'bankTransfer',
                icon: HiOutlineBanknotes,
                label: 'تحويل بنكي',
              },
              { method: 'visa', icon: FaCreditCard, label: 'دفع فيزا' },
            ].map(({ method, icon: Icon, label }) => (
              <label
                className='radio flex flex-grow items-center justify-center rounded-lg p-1 cursor-pointer'
                key={method}
              >
                <input
                  type='radio'
                  name='paymentMethod'
                  value={method}
                  checked={paymentMethod === method}
                  onChange={() => setPaymentMethod(method)}
                  className='peer hidden'
                />

                <span className='tracking-widest peer-checked:bg-green-400 dark:peer-checked:bg-yellow-600  peer-checked:text-dark dark:peer-checked:text-white-600  text-gray-900 dark:text-logic-blue p-2 rounded-lg transition duration-150 ease-in-out'>
                  {label}
                </span>
              </label>
            ))}
          </div>
          {/* Transaction ID input */}
        </div>

        {/* Payment Type Selection */}
        <div className='shadow-lg p-4 rounded-lg'>
          <h3 className='text-2xl font-bold my-4 text-center dark:text-yellow-400 text-green-500'>
            حالة السداد
          </h3>
          <div className='flex space-x-2 border-[3px] border-green-400 dark:border-yellow-400  rounded-xl select-none'>
            {[
              { type: 'full', icon: GiCash, label: 'سداد كامل' },
              { type: 'partial', icon: BsCashCoin, label: 'سداد جزئي' },
            ].map(({ type, icon: Icon, label }) => (
              <label
                className='radio flex flex-grow items-center justify-center rounded-lg p-1 cursor-pointer'
                key={type}
              >
                <input
                  type='radio'
                  name='paymentType'
                  value={type}
                  checked={paymentType === type}
                  onChange={() => setPaymentType(type)}
                  className='peer hidden'
                />
                <span className='tracking-widest peer-checked:bg-green-400 dark:peer-checked:bg-yellow-600  peer-checked:text-dark dark:peer-checked:text-white-600  text-gray-900 dark:text-logic-blue p-2 rounded-lg transition duration-150 ease-in-out'>
                  {label}
                </span>
              </label>
            ))}
          </div>
        </div>
      </div>
      {/* Total Amount Display */}
      <div className='flex border-t border-gray-600 bg-gray-300 dark:bg-gray-800 text-gray-900 dark:text-gray-100 p-4    pt-4 justify-center space-x-4 mt-6 items-center'>
        <span className='text-xl bg'>الإجمالى:</span>
        <span className='text-2xl'>${totalFinal.toFixed(2)}</span>
        <FaMoneyBillWave className='text-3xl' />
      </div>
    </div>
  );
};

export default PaymentMethod;
