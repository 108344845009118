import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import { LogoArt } from '../../../../assets/img/index';
import { useSidebar } from '../../../../utils/SidebarContext';
const NavbarLogo = () => {
  const { sidebarOpen } = useSidebar();
  // Determine size based on sidebarOpen state
  const size = sidebarOpen ? '50px' : '40px';

  // Define the spinning animation
  const spinningLogo = useSpring({
    loop: { reverse: false },
    to: { rotateZ: 360 },
    from: { rotateZ: 0 },
    reset: true,
    repeat: Infinity,
    config: { duration: 8000 },
  });

  return (
    <animated.img
      style={{
        ...spinningLogo,
        width: size,
        height: size,
      }}
      src={LogoArt}
      alt='Logo'
    />
  );
};

export default NavbarLogo;
