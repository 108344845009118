import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Transition from '../../utils/Transition';
import API_CONFIG from '../../ApiConfig';
import useAuth from '../Auth/Authentication/AuthUser';

function UserMenu({ align = 'right' }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { user, image, logout } = useAuth();

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // Close dropdown on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  }, [dropdownOpen]);

  // Close dropdown if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  }, [dropdownOpen]);

  // Handle image error
  const handleImageError = e => {
    e.target.src = '/default-profile.png'; // path to default image
  };

  return (
    <div className='relative inline-flex'>
      <button
        ref={trigger}
        className='inline-flex items-center space-x-2 group focus:outline-none'
        aria-haspopup='true'
        aria-expanded={dropdownOpen}
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        <img
          src={`${API_CONFIG.baseURL}${image}`}
          onError={handleImageError}
          alt='User profile'
          className='w-8 h-8 rounded-full object-cover'
        />
        <span className='text-gray-800 dark:text-gray-200 font-bold'>
          {user?.name}
        </span>
        <svg
          className='w-3 h-3 shrink-0 ml-1 fill-current text-gray-400 dark:text-gray-500'
          viewBox='0 0 12 12'
        >
          <path d='M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z' />
        </svg>
      </button>

      <Transition
        className={`origin-top-right z-10 absolute top-full min-w-44 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700/60 py-1.5 rounded-lg shadow-lg overflow-hidden mt-1 ${
          align === 'right' ? 'right-0' : 'left-0'
        }`}
        show={dropdownOpen}
        enter='transition ease-out duration-200 transform'
        enterStart='opacity-0 -translate-y-2'
        enterEnd='opacity-100 translate-y-0'
        leave='transition ease-out duration-200'
        leaveStart='opacity-100'
        leaveEnd='opacity-0'
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <ul>
            <li>
              <Link
                className='font-medium text-sm text-violet-500 hover:text-violet-600 dark:hover:text-violet-400 focus:text-violet-600 dark:focus:text-violet-400 flex items-center py-1 px-3'
                to='/settings'
                onClick={() => setDropdownOpen(false)}
              >
                Settings
              </Link>
            </li>
            <li>
              <button
                className='font-medium text-sm text-violet-500 hover:text-violet-600 dark:hover:text-violet-400 focus:text-violet-600 dark:focus:text-violet-400 flex items-center py-1 px-3 w-full text-left'
                onClick={logout}
              >
                Sign Out
              </button>
            </li>
          </ul>
        </div>
      </Transition>
    </div>
  );
}

export default UserMenu;
