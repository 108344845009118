import React, { useState, useEffect, useCallback } from 'react';
import { FaUserShield, FaUserCircle } from 'react-icons/fa';
import PermissionsSection from './PermissionsSection';
import useAuth from '../../Auth/Authentication/AuthUser';
import API_CONFIG from '../../../ApiConfig';
import LogoImageSpinner from '../../Tools/LogoImageSpinner';
import { IconRoles } from '../../../assets/img/icons';
import SectionHeader from '../../Tools/SectionHeader';
import debounce from 'lodash/debounce';
import UserInfoCard from './UserCard';

const RoleManagement = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [employeeUsers, setEmployeeUsers] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [userPermissions, setUserPermissions] = useState({});
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ message: '', type: '' });
  const { http } = useAuth();

  // Function to fetch data with debounce
  const fetchData = useCallback(
    debounce(async () => {
      setLoading(true);
      try {
        const [usersResponse, permissionsResponse] = await Promise.all([
          http.get('/api/employee-users'),
          http.get('/api/permissions'),
        ]);

        setEmployeeUsers(usersResponse.data?.employeeUsers || []);
        setPermissions(permissionsResponse.data || []);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setLoading(false);
      }
    }, 300),
    []
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handlePermissionChange = (section, name) => {
    setUserPermissions(prev => ({
      ...prev,
      [section]: prev[section].map(permission => ({
        ...permission,
        enabled:
          permission.name === name ? !permission.enabled : permission.enabled,
      })),
    }));
  };

  const handleSelectUser = async e => {
    const userId = parseInt(e.target.value, 10);
    const user = employeeUsers.find(u => u.id === userId);
    setSelectedUser(user);
    if (userId) {
      setLoading(true);
      try {
        const response = await http.get(`/api/permissions/${userId}`);
        const userPermissions = response.data;

        const updatedPermissions = { ...permissions };
        Object.keys(updatedPermissions).forEach(section => {
          updatedPermissions[section] = updatedPermissions[section].map(
            permission => ({
              ...permission,
              enabled:
                userPermissions.some(
                  up =>
                    up.name === permission.name &&
                    up.section === section &&
                    up.pivot.enabled
                ) || false,
            })
          );
        });
        setUserPermissions(updatedPermissions);
      } catch (error) {
        console.error('Failed to fetch permissions:', error);
        setAlert({ message: 'يرجى اختيار مستخدم أولاً.', type: 'error' });
        setTimeout(() => setAlert({ message: '', type: '' }), 3000);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSavePermissions = async () => {
    if (!selectedUser) {
      setAlert({ message: 'يرجى اختيار مستخدم أولاً.', type: 'error' });
      setTimeout(() => setAlert({ message: '', type: '' }), 3000); // Hide alert after 3 seconds
      return;
    }

    setLoading(true);
    try {
      const permissionsToSave = Object.entries(userPermissions).reduce(
        (acc, [section, perms]) => {
          perms.forEach(perm => {
            acc.push({
              permission_id: perm.id,
              enabled: perm.enabled,
            });
          });
          return acc;
        },
        []
      );

      await http.post(`/api/permissions/${selectedUser.id}`, {
        permissions: permissionsToSave,
      });

      setAlert({ message: 'تم تحديث الصلاحيات بنجاح!', type: 'success' });

      // Scroll to top after success
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

      fetchData(); // Reload data after updating

      setTimeout(() => setAlert({ message: '', type: '' }), 3000);
    } catch (error) {
      console.error('فشل في تحديث الصلاحيات:', error);
      setAlert({ message: 'فشل في تحديث الصلاحيات.', type: 'error' });

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

      setTimeout(() => setAlert({ message: '', type: '' }), 3000);
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordReset = useCallback(async () => {
    if (!selectedUser || !selectedUser.id) {
      setAlert({ message: 'يرجى اختيار مستخدم أولاً.', type: 'error' });
      return;
    }
    try {
      const response = await http.post('/api/password/user-reset', {
        user_id: selectedUser.id,
      });
      setAlert({ message: response.data.message, type: 'success' });
    } catch (error) {
      console.error('Error sending password reset email:', error);
      if (error.response && error.response.status === 404) {
        setAlert({
          message: 'لم يتم العثور على مستخدم بهذا البريد الإلكتروني.',
          type: 'error',
        });
      } else {
        setAlert({
          message: 'حدث خطأ أثناء إرسال رابط إعادة تعيين كلمة السر.',
          type: 'error',
        });
      }
    }
  }, [selectedUser]);

  return (
    <div className='container mx-auto h-full px-4'>
      <SectionHeader sectionTitle='ادارة الصلاحيات' imageSrc={IconRoles} />
      <div className='mt-4 bg-gradient-to-br pb-4 from-gray-300 to-gray-100 dark:from-green-700 dark:to-blue-900 p-6 rounded-xl text-white shadow-lg'>
        <div className='w-full text-center'>
          <select
            onChange={handleSelectUser}
            className='w-full sm:w-full md:w-2/3 lg:w-1/2 xl:w-1/3 p-3 bg-gradient-to-r from-blue-100 to-green-100 dark:bg-gray-700 text-gray-900 dark:text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500 border border-gray-300 dark:border-gray-600 shadow-md transition-all duration-300'
          >
            <option value=''>اختر مستخدم</option>
            {employeeUsers.map(user => (
              <option key={user.id} value={user.id}>
                {user.name}
              </option>
            ))}
          </select>
        </div>
        {selectedUser && (
          <>
            <div className='flex justify-center mt-4'>
              <button
                type='button'
                className='bg-gradient-to-r from-pink-500 to-pink-600 hover:from-pink-600 hover:to-pink-700 text-white px-4 py-2 rounded-full shadow-md hover:scale-105 transition-transform duration-300'
                onClick={handlePasswordReset}
              >
                إعادة تعيين كلمة السر
              </button>
            </div>

            <div className='mt-5  bg-gray-200 dark:bg-gray-700 rounded-lg shadow-lg'>
              <h2 className='text-lg font-bold text-center text-pink-700 dark:text-yellow-400 mb-4'>
                <FaUserCircle className='inline ml-2' />
                بيانات المستخدم
              </h2>
              <UserInfoCard user={selectedUser} />
            </div>

            <div className='mt-5 p-4 bg-gray-200 dark:bg-gray-700 rounded-lg shadow-lg'>
              <h2 className='text-lg font-bold text-center text-pink-700 dark:text-yellow-400 mb-4'>
                <FaUserShield className='inline ml-2' />
                صلاحيات المستخدم
              </h2>
              {alert.message && (
                <div
                  className={`${
                    alert.type === 'success'
                      ? 'bg-green-100 text-green-800'
                      : 'bg-red-100 text-red-700'
                  } border ${
                    alert.type === 'success'
                      ? 'border-green-400'
                      : 'border-red-400'
                  } px-4 py-3 rounded relative mb-4`}
                  role='alert'
                >
                  <span>{alert.message}</span>
                </div>
              )}
              {loading ? (
                <div className='flex justify-center mb-2'>
                  <LogoImageSpinner />
                </div>
              ) : (
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4'>
                  {Object.keys(userPermissions).map(section => (
                    <PermissionsSection
                      key={section}
                      section={section}
                      permissions={userPermissions[section]}
                      handlePermissionChange={handlePermissionChange}
                    />
                  ))}
                </div>
              )}
              <div className='flex justify-center'>
                <button
                  onClick={handleSavePermissions}
                  className='w-full md:w-1/4 bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white font-bold py-2 px-4 rounded-full transition-all duration-300 hover:scale-105 shadow-lg'
                >
                  حفظ التغييرات
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RoleManagement;
