import React, { Suspense } from 'react';
import useAuth from './components/Auth/Authentication/AuthUser';
import ShowSpinner from './components/Tools/ShowSpinner';
import { SidebarProvider } from './utils/SidebarContext';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import './components/Auth/partials/charts/ChartjsConfig';
import ResetPassword from './components/Guest/GuestTools/PasswordReset';
import AuthWrapper from './components/Auth/AuthWrapper';

const LandingPage = React.lazy(() => import('./components/Guest/LandingPage'));
const DoneRegister = React.lazy(() => import('./components/Guest/GuestTools/DoneRegister'));

export default function App() {
  const { getToken } = useAuth();

  return (
    <div dir='rtl' className="min-h-screen">
      <SidebarProvider>
        <Suspense fallback={<ShowSpinner />}>
          <Routes>
            <Route
              path='*'
              element={!getToken() ? <LandingPage /> : <AuthWrapper />}
            />
            <Route path='/done-register' element={<DoneRegister />} />
            <Route path='/done-activation' element={<DoneRegister />} />
            <Route path='/reset-password' element={<ResetPassword />} />
          </Routes>
        </Suspense>
      </SidebarProvider>
    </div>
  );
}
