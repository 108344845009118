import React, { useState } from 'react';
import TableComponent from '../../Tools/TableComponent';
import { AiOutlinePlus } from 'react-icons/ai';

import { useNavigate } from 'react-router-dom';
import useAuth from '../../Auth/Authentication/AuthUser';
import OrderViewMain from '../Tools/OrderViewMain';
import OrderModalForm from '../OrderModalForm';
import DeleteConfirmationModal from '../../Tools/DeleteConfirmationModal';
import { deleteOrder } from '../../Routes/api';
const PlanningOrders = ({ orders, refreshOrders }) => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [orderIdToDelete, setOrderIdToDelete] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState('');
  const { http } = useAuth();
  const navigate = useNavigate();

  const formatDate = dateString => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-CA');
  };

  const customRenderers = {
    customer_id: row =>
      row.customer ? row.customer.name : 'No customer assigned',
    employees: row => {
      const saleRep = row.employees?.sale_rep
        ? row.employees.sale_rep.name
        : 'No sales rep assigned';
      const socialRep = row.employees?.social_rep
        ? row.employees.social_rep.name
        : 'No social rep assigned';
      const designer = row.employees?.designer
        ? row.employees.designer.name
        : 'No designer assigned';

      return (
        <div>
          <p>مندوب المبيعات: {saleRep}</p>
          <p>مندوب التسويق: {socialRep}</p>
          <p>المصمم: {designer}</p>
        </div>
      );
    },
    created_by: row => (row.creator ? row.creator.name : 'No creator assigned'),
    order_date: row => formatDate(row.order_date),
    time_plementation_range: row => row.time_plementation_range,
    payment_status: row => {
      const paymentStatusColors = {
        مسدد: 'text-green-500',
        'غير مسدد': 'text-red-500',
      };
      return (
        <span
          className={`font-bold ${paymentStatusColors[row.payment_status]}`}
        >
          {row.payment_status}
        </span>
      );
    },
    order_status: row => {
      const orderStatusColors = {
        'قيد الإعداد': 'text-green-500',
        'تم الانتهاء': 'text-red-500',
        'قيد التنفيذ': 'text-yellow-500',
        'تم التسليم': 'text-violet-500',
        ملغي: 'text-gray-500',
      };
      return (
        <span className={`font-bold ${orderStatusColors[row.order_status]}`}>
          {row.order_status}
        </span>
      );
    },
  };

  const headers = [
    { key: 'id', text: 'ID' },
    { key: 'order_number', text: 'رقم الطلب' },
    {
      key: 'order_date',
      text: 'تاريخ الطلب',
      render: customRenderers.order_date,
    },
    { key: 'customer_id', text: 'العميل', render: customRenderers.customer_id },
    {
      key: 'time_plementation_range',
      text: 'مدة التنفيذ',
      render: customRenderers.time_plementation_range,
    },
    {
      key: 'payment_status',
      text: 'حالة الدفع',
      render: customRenderers.payment_status,
    },
    {
      key: 'order_status',
      text: 'حالة الطلب',
      render: customRenderers.order_status,
    },
  ];

  const handleAddOrder = async orderData => {
    try {
      await addOrder(http, orderData);
      refreshOrders();
      setSuccessMessage('تم إضافة الطلب بنجاح!');
    } catch (error) {
      console.error('Failed to add order:', error);
      setError('فشل في إضافة الطلب. يرجى المحاولة مرة أخرى.');
    }
  };

  const handleEditOrder = async (orderId, orderData) => {
    try {
      await updateOrder(http, orderId, orderData);
      refreshOrders();
      setSuccessMessage('تم تحديث الطلب بنجاح!');
    } catch (error) {
      console.error('Failed to update order:', error);
      setError('فشل في تحديث الطلب. يرجى المحاولة مرة أخرى.');
    }
  };

  const handleViewClick = orderId => {
    navigate(`/orders/${orderId}`);
  };

  const handleDeleteOrder = async orderId => {
    try {
      await deleteOrder(http, orderId);
      refreshOrders();
      setSuccessMessage('تم حذف الطلب بنجاح!');
    } catch (error) {
      console.error('Failed to delete order:', error);
      setError('فشل في حذف الطلب. يرجى المحاولة مرة أخرى.');
    }
  };

  const promptDeleteOrder = orderId => {
    setOrderIdToDelete(orderId);
    setShowDeleteConfirmation(true);
  };

  const handleDeleteClick = async () => {
    await handleDeleteOrder(orderIdToDelete);
    setShowDeleteConfirmation(false);
  };

  const renderAddButton = () => (
    <button
      onClick={() => setShowModal(true)}
      className='flex items-center px-4 py-2 bg-violet-500 hover:bg-violet-700 text-white rounded-md'
    >
      <AiOutlinePlus className='mr-2' /> إضافة طلب
    </button>
  );

  const handleModalClose = () => {
    setShowModal(false);
    setSuccessMessage('تم إضافة الطلب بنجاح!');
  };

  return (
    <div className='p-5 bg-gradient-to-r from-blue-200/[0.8] to-blue-100/[0.8] dark:from-gray-800/[0.8] dark:to-gray-900/[0.8] rounded-xl shadow-xl'>
      {error && (
        <div
          className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4'
          role='alert'
        >
          <strong className='font-bold'>خطأ: </strong>
          <span className='block sm:inline'>{error}</span>
          <button
            className='absolute top-0 bottom-0 right-0 px-4 py-3'
            onClick={() => setError('')}
          >
            <span className='text-red-500'>&times;</span>
          </button>
        </div>
      )}
      {successMessage && (
        <div className='bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4'>
          <span className='block sm:inline'>{successMessage}</span>
          <button
            className='absolute top-0 bottom-0 right-0 px-4 py-3'
            onClick={() => setSuccessMessage('')}
          >
            <span className='text-green-500'>&times;</span>
          </button>
        </div>
      )}

      <div className='flex flex-col'>
        <TableComponent
          sectionName={'Orders'}
          customRenderers={customRenderers}
          data={orders}
          onDelete={promptDeleteOrder}
          renderAddButton={renderAddButton}
          headers={headers}
          onEdit={handleEditOrder}
          onView={handleViewClick}
        />

        {showModal && (
          <OrderModalForm
            isOpen={showModal}
            onClose={handleModalClose} // استخدام دالة الإغلاق المعدلة
            refetchOrders={refreshOrders}
          />
        )}
      </div>
    </div>
  );
};

export default PlanningOrders;
