import React, { useEffect, useReducer, Suspense } from 'react';
import DashboardCard from './Tools/DashboardCards';
import {
  FcShop,
  FcSalesPerformance,
  FcConferenceCall,
  FcManager,
  FcAssistant,
} from 'react-icons/fc';
import { MdOutlineLocalOffer } from 'react-icons/md';
import { useSpring, animated } from '@react-spring/web';
import useAuth from '../Auth/Authentication/AuthUser';
import LogoImageSpinner from '../Tools/LogoImageSpinner';
import DashboardCard01 from '../Auth/partials/dashboard/DashboardCard01';
import DashboardCard02 from '../Auth/partials/dashboard/DashboardCard02';
import DashboardCard03 from '../Auth/partials/dashboard/DashboardCard03';
import DashboardCard04 from '../Auth/partials/dashboard/DashboardCard04';
import DashboardCard05 from '../Auth/partials/dashboard/DashboardCard05';
import DashboardCard06 from '../Auth/partials/dashboard/DashboardCard06';
import DashboardCard07 from '../Auth/partials/dashboard/DashboardCard07';
import DashboardCard08 from '../Auth/partials/dashboard/DashboardCard08';
import DashboardCard09 from '../Auth/partials/dashboard/DashboardCard09';
import DashboardCard10 from '../Auth/partials/dashboard/DashboardCard10';
import DashboardCard11 from '../Auth/partials/dashboard/DashboardCard11';
import DashboardCard12 from '../Auth/partials/dashboard/DashboardCard12';
import DashboardCard13 from '../Auth/partials/dashboard/DashboardCard13';

const initialState = {
  counts: {
    customers: 0,
    products: 0,
    employees: 0,
    sales: 0,
    offers: 0,
    orders: 0,
  },
  isExpanded: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_COUNTS':
      return { ...state, counts: action.payload };
    case 'TOGGLE_EXPAND':
      return { ...state, isExpanded: !state.isExpanded };
    default:
      return state;
  }
}

const Home = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { http } = useAuth();

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const response = await http.get(`/api/dashboard`);
        dispatch({ type: 'SET_COUNTS', payload: response.data });
      } catch (error) {
        console.error('Error fetching dashboard counts:', error);
      }
    };
    fetchCounts();
  }, []);

  const fade = useSpring({
    from: { opacity: 0 },
    to: { opacity: state.isExpanded ? 0 : 1 },
    delay: 300,
  });

  return (
    <div className='col-span-12'>
      
                 <h1 className='text-2xl pb-8  text-center md:text-3xl text-gray-800 dark:text-gray-100 font-bold'>
                لوحة التحكم
              </h1>
      <div className='grid  grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6 pb-4'>
        

        <DashboardCard
          title={translateTitle('offers').title}
          value={state.counts.offers}
          link={translateTitle('offers').link}
          Icon={MdOutlineLocalOffer}
        />
        {Object.entries(state.counts).map(([key, value]) => {
          if (key !== 'offers') {
            return (
              <DashboardCard
                key={key}
                title={translateTitle(key).title}
                value={value}
                link={translateTitle(key).link}
                Icon={iconMap[key]}
              />
            );
          }
          return null;
        })}
      </div>
      <div className='grid grid-cols-12 gap-6'>
        <Suspense
          fallback={
            <div className='container flex justify-center mt-6 text-center text-xl text-bold pt-4 text-violet-500 dark:text-violet-200'>
              <LogoImageSpinner />
            </div>
          }
        >
          {/* Lazy-loaded Dashboard Cards */}
          <DashboardCard01 />
          <DashboardCard02 />
          <DashboardCard03 />
          <DashboardCard04 />
          <DashboardCard06 />
          <DashboardCard07 />
          <DashboardCard08 />
          <DashboardCard09 />
          <DashboardCard10 />
          <DashboardCard11 />
          <DashboardCard12 />
          <DashboardCard13 />
        </Suspense>
      </div>
    </div>
  );
};

const iconMap = {
  customers: FcConferenceCall,
  products: FcShop,
  employees: FcManager,
  sales: FcSalesPerformance,
  offers: FcShop,
  orders: FcAssistant,
};

function translateTitle(key) {
  const DashboardCardLinks = {
    customers: '/customers',
    products: '/products',
    employees: '/employees',
    sales: '/orders',
    offers: '/offers',
    orders: '/orders',
  };
  const titles = {
    customers: 'العملاء',
    products: 'المنتجات',
    employees: 'الموظفين',
    sales: 'المبيعات',
    offers: 'العروض',
    orders: 'الطلبات',
  };
  return { title: titles[key], link: DashboardCardLinks[key] };
}

export default Home;
