import React, { useState, useEffect } from 'react';
import API_CONFIG from '../../../ApiConfig';
import TableComponent from '../../Tools/TableComponent';
import MultiEmployeeModalForm from './MultiEmployeeModalForm';
import { AiOutlinePlus } from 'react-icons/ai';
import useAuth from '../../Auth/Authentication/AuthUser';
import DeleteConfirmationModal from '../DeleteConfirmationModal';

const MultiEmployeesComponent = ({ multiEmployees, refreshData }) => {
  const { http, user } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [selectedMultiEmployee, setSelectedMultiEmployee] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    refreshData();
  }, []);

  const handleDelete = async () => {
    if (selectedMultiEmployee && selectedMultiEmployee.user_id === user.id) {
      setShowDeleteConfirmation(false);
      setErrorMessage('لا تستطيع حذف الحساب الخاص بك');
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
      return;
    }

    try {
      await http.delete(`/api/multi-employees/${selectedMultiEmployee.id}`);
      setShowDeleteConfirmation(false);
      refreshData();
      setSuccessMessage('تم الحذف بنجاح!');
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    } catch (error) {
      console.error('Error deleting multi Employee:', error);
    }
  };

  const handleEditClick = multiEmployeesId => {
    const multiEmployeeToEdit = multiEmployees.find(
      multiEmployee => multiEmployee.id === multiEmployeesId
    );
    setSelectedMultiEmployee(multiEmployeeToEdit);
    setShowModal(true);
  };

  const renderAddButton = () => {
    return (
      <button
        onClick={() => {
          setSelectedMultiEmployee(null);
          setShowModal(true);
        }}
        className='flex items-center px-4 py-2 bg-violet-500 hover:bg-violet-700 text-white rounded-md'
      >
        <AiOutlinePlus className='text-sm mr-2' /> إضافة موظف
      </button>
    );
  };

  const promptDelete = multiEmployeesId => {
    const multiEmployeeToDelete = multiEmployees.find(
      multiEmployee => multiEmployee.id === multiEmployeesId
    );
    setSelectedMultiEmployee(multiEmployeeToDelete);
    setShowDeleteConfirmation(true);
  };

  return (
    <>
      {successMessage && (
        <div className='mb-4 p-2 bg-green-400 font-bold text-center text-green-800 rounded'>
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div className='mb-4 p-2 bg-red-400 font-bold text-center text-gray-100 rounded'>
          {errorMessage}
        </div>
      )}
      <div className='p-5 bg-gradient-to-r from-blue-200/[0.8] to-blue-100/[0.8] dark:from-gray-800/[0.8] dark:to-gray-900/[0.8] rounded-xl shadow-xl'>
        {showModal && (
          <MultiEmployeeModalForm
            isOpen={showModal}
            multiEmployee={selectedMultiEmployee}
            onClose={() => setShowModal(false)}
            refreshMultiEmployees={refreshData}
            setSuccessMessage={setSuccessMessage}
          />
        )}
        {showDeleteConfirmation && (
          <DeleteConfirmationModal
            onDelete={handleDelete}
            onCancel={() => setShowDeleteConfirmation(false)}
          />
        )}
        <div className='overflow-auto'>
          <TableComponent
            sectionName={'MultiEmployees'}
            data={multiEmployees}
            headers={[
              { key: 'id', text: 'ID' },
              { key: 'name', text: 'الاسم' },
              { key: 'employee_position', text: 'المنصب' },
              { key: 'phone', text: 'رقم الهاتف' },
              { key: 'image', text: 'الصورة' },
            ]}
            onDelete={promptDelete}
            onEdit={handleEditClick}
            renderAddButton={renderAddButton}
          />
        </div>
      </div>
    </>
  );
};

export default MultiEmployeesComponent;
