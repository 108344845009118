import React from 'react';

const OrderCustomerSaleRep = ({
  customers,
  saleReps,
  selectedCustomer,
  selectedSaleRep,
  handleCustomerSelect,
  handleSaleRepSelect,
}) => {
  return (
    <>
      <div className='col-span-2'>
        <h3 className='text-lg font-bold text-center text-orange-400 mb-2'>
          العميل
        </h3>
        <select
          id='customer-select'
          className='mt-1 block w-full bg-gray-300 text-gray-800 border-gray-600 rounded-md focus:ring-indigo-500 focus:border-indigo-500 text-center'
          value={selectedCustomer.id}
          onChange={e =>
            handleCustomerSelect({
              id: e.target.value,
              name: e.target.options[e.target.selectedIndex].text,
            })
          }
          style={{
            height: '40px',
            padding: '6px 12px',
            fontSize: '16px',
          }}
        >
          <option value=''>اختر العميل</option>
          {customers.map(customer => (
            <option key={customer.id} value={customer.id}>
              {customer.name}
            </option>
          ))}
        </select>
      </div>
      <div className='col-span-2'>
        <h3 className='text-lg font-extrabold text-center text-orange-400 shadow-lg rounded-lg overflow-hidden mb-4 p-2'>
          مندوب المبيعات
        </h3>
        <select
          id='sale-rep-select'
          className='mt-1 block w-full bg-gray-300 text-gray-800 border-gray-600 rounded-md focus:ring-indigo-500 focus:border-indigo-500 text-center'
          value={selectedSaleRep}
          onChange={e => handleSaleRepSelect(e)}
          style={{
            height: '40px',
            padding: '6px 12px',
            fontSize: '16px',
          }}
        >
          <option value=''>اختر مندوب مبيعات</option>
          {saleReps.map(saleRep => (
            <option key={saleRep.id} value={saleRep.id}>
              {saleRep.name}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default OrderCustomerSaleRep;
