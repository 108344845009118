// InvoiceList.js
import React, { useState } from 'react';
import TableComponent from '../../Tools/TableComponent';
import useAuth from '../../Auth/Authentication/AuthUser';
import DeleteConfirmationModal from '../../Tools/DeleteConfirmationModal';
import { IconInvoices } from '../../../assets/img/icons';
import SectionHeader from '../../Tools/SectionHeader';

const InvoiceSection = ({ refreshInvoices }) => {
  const [invoices, setInvoices] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { http } = useAuth();

  const headers = [
    { key: 'id', text: 'رقم الفاتورة' },
    { key: 'customer_name', text: 'اسم العميل' },
    { key: 'total', text: 'قيمة الفاتورة' },
    { key: 'status', text: 'حالة الفاتورة' },
  ];

  const fetchData = async () => {
    setLoading(true);
    try {
      const { data } = await http.get('/api/invoices');
      if (Array.isArray(data)) {
        setInvoices(data);
      } else {
        setInvoices([]); // تعيين مصفوفة فارغة في حال عدم استلام مصفوفة من API
        console.error('Invalid data format: expected an array');
      }
    } catch (error) {
      console.error('Failed to fetch data:', error);
      setInvoices([]); // تعيين مصفوفة فارغة في حالة حدوث خطأ
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async invoiceId => {
    if (invoiceId === invoice.id) {
      setShowDeleteModal(false);
      setErrorMessage('لا تستطيع حذف الفاتورة الخاص بك');
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
      return;
    }

    try {
      await http.delete(`/api/invoices/${invoiceId}`);
      setShowDeleteModal(false);
      refreshInvoices();
      setSuccessMessage('تم الحذف بنجاح!');
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    } catch (error) {
      console.error('Error deleting invoice:', error);
    }
  };

  const promptDelete = invoiceId => {
    const invoiceToDelete = invoices.find(invoice => invoice.id === invoiceId);
    setSelectedInvoice(invoiceToDelete);
    setShowDeleteModal(true);
  };

  return (
    <>
      <SectionHeader imageSrc={IconInvoices} sectionTitle='الفواتير' />

      {successMessage && (
        <div className='mb-4 p-2 bg-green-400 font-bold text-center text-green-800 rounded'>
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div className='mb-4 p-2 bg-red-400 font-bold text-center text-gray-100 rounded'>
          {errorMessage}
        </div>
      )}
      <div className='p-5 bg-gradient-to-r from-blue-200 to-blue-100 dark:from-gray-800 dark:to-gray-900 rounded-xl shadow-xl'>
        {showDeleteModal && (
          <DeleteConfirmationModal
            onDelete={() => handleDelete(selectedInvoice.id)}
            onCancel={() => setShowDeleteModal(false)}
          />
        )}

        <div className='overflow-auto'>
          <TableComponent
            data={invoices}
            headers={headers}
            sectionName={'Invoices'}
            onDelete={promptDelete} // Uncomment if needed
          />
        </div>
      </div>
    </>
  );
};

export default InvoiceSection;
