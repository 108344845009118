import React, { useState } from 'react';
import { ProjectFinshed } from '../../assets/img/index';

const DesignModalForm = () => {
  const [projectData, setProjectData] = useState({
    projectName: '',
    clientName: '',
    projectFiles: null,
    projectImages: null,
    projectNotes: '',
  });

  const handleChange = e => {
    const { name, value, files } = e.target;
    if (name === 'projectFiles' || name === 'projectImages') {
      setProjectData({ ...projectData, [name]: files });
    } else {
      setProjectData({ ...projectData, [name]: value });
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    // Submit form logic here
    console.log(projectData);
  };

  const handlePasswordReset = async () => {
    if (!designer || !designer.user_id) {
      alert('البريد الإلكتروني غير موجود للمصمم.');
      return;
    }

    try {
      const response = await http.post('/password/user-reset', {
        user_id: designer.user_id,
      });
      alert(response.data.message);
    } catch (error) {
      console.error('Error sending password reset email:', error);
      alert('حدث خطأ أثناء إرسال رابط إعادة تعيين كلمة السر.');
    }
  };
  return (
    <div
      className={`fixed inset-0 z-50 overflow-y-auto ${isOpen ? '' : 'hidden'} dark:bg-gray-900`}
    >
      {designer && (
        <div className='flex justify-center mb-4'>
          <button
            type='button'
            className='bg-gradient-to-r from-purple-400 via-purple-500 to-purple-600 hover:from-purple-500 hover:via-purple-600 hover:to-purple-700 text-white font-bold px-4 py-2 rounded focus:outline-none focus:shadow-outline'
            onClick={handlePasswordReset}
          >
            إعادة تعيين كلمة السر
          </button>
        </div>
      )}
      <div className='flex items-center justify-center min-h-screen p-4'>
        <div
          className='fixed inset-0 bg-gray-600 bg-opacity-50 transition-opacity'
          onClick={onClose}
        ></div>
        <div className='bg-white dark:bg-gray-800 rounded-lg shadow-2xl transform transition-all sm:max-w-lg sm:w-full p-6'>
          <div className='text-right'>
            <button
              onClick={onClose}
              className='text-gray-400 hover:text-gray-500 transition ease-in-out duration-150'
            >
              إغلاق
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            {/* Form inputs go here */}
            {/* Image input specifically */}
            <div className='mb-4'>
              <label
                htmlFor='image'
                className='block text-gray-700 font-bold mb-2'
              >
                صورة المصمم
              </label>
              <input
                type='file'
                id='image'
                name='image'
                onChange={handleImageChange}
                className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              />
            </div>
            {/* Other form elements */}
            <div className='flex items-center justify-between'>
              <button
                type='submit'
                disabled={isSubmitting} // Disable button during submission
                className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
              >
                {isSubmitting ? 'جاري الحفظ...' : 'حفظ'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DesignModalForm;
