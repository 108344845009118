import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import SidebarLinkGroup from './SidebarLinkGroup';
import { useSidebar } from '../../../utils/SidebarContext';
import NavbarLogo from './Tools/NavbarLogo';
import GardualText from './Tools/GradualText';

const Sidebar = ({ variant = 'default' }) => {
  const { isSidebarOpen, isMobile, setIsSidebarOpen, autoCloseSidebar,isHeaderFullScreen } =
    useSidebar();
  const [currentTab, setCurrentTab] = useState('linksTab');
  const location = useLocation();
  const { pathname } = location;
  const sidebarRef = useRef(null);

  // حساب عرض الشريط العلوي بناءً على حالة الشريط الجانبي


  useEffect(() => {
    if (isMobile && isSidebarOpen) {
      autoCloseSidebar();
    }
  }, [isMobile, isSidebarOpen, autoCloseSidebar]);

  const handleClickOutside = event => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsSidebarOpen(false);
    }
  };

  useEffect(() => {
    if (isMobile && isSidebarOpen) {
      document.addEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isMobile, isSidebarOpen]);

  return (
    <>
      {/* Mini Sidebar: إخفاء الشريط المصغر على الهواتف */}
      {!isMobile && (
        <nav
          ref={sidebarRef}
          className={`fixed inset-y-0 right-0 z-20 w-16 bg-white border-l-2 border-gray-200 dark:border-purple-400 shadow-md dark:bg-gray-800 transition-transform`}
        >
          <div className='flex flex-col items-center py-4 space-y-4'>
            <div className='flex-shrink-0'>
              <NavbarLogo />
            </div>

          {/* أزرار الشريط المصغر */}
          <button
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className={`p-2 transition-colors rounded-lg bg-indigo-800 text-white  dark:text-gray-00 dark:bg-purple-400 dark:hover:bg-orange-300 hover:bg-indigo-600 ${
              isSidebarOpen
                ? 'text-white bg-orange-600'
                : 'text-gray-500 bg-white'
            }`}
          >
            <span className='sr-only'>Toggle sidebar</span>
            <svg
              aria-hidden='true'
              className='w-6 h-6'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M4 6h16M4 12h16M4 18h7'
              />
            </svg>
          </button>
          <NavLink
            to='/'
            className={`p-2 transition-colors duration-300 rounded-lg 
              text-indigo-500 hover:text-indigo-800 
              dark:text-purple-300 dark:hover:text-orange-300
              focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-indigo-400 
              dark:focus:ring-purple-600`}
            title='الرئيسية'
          >
            <svg
              className='w-6 h-6'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6'
              />
            </svg>
          </NavLink>

          <NavLink
            to='/customers'
                   className={`p-2 transition-colors duration-300 rounded-lg 
              text-indigo-500 hover:text-indigo-800 
              dark:text-purple-300 dark:hover:text-orange-300
              focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-indigo-400 
              dark:focus:ring-purple-600`}
            title='العملاء'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='w-6 h-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z'
              />
            </svg>
          </NavLink>

          <NavLink
            to='/orders'
                   className={`p-2 transition-colors duration-300 rounded-lg 
              text-indigo-500 hover:text-indigo-800 
              dark:text-purple-300 dark:hover:text-orange-300
              focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-indigo-400 
              dark:focus:ring-purple-600`}
            title='الطلبات'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='w-6 h-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 0 0-3.7-3.7 48.678 48.678 0 0 0-7.324 0 4.006 4.006 0 0 0-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 0 0 3.7 3.7 48.656 48.656 0 0 0 7.324 0 4.006 4.006 0 0 0 3.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3-3 3'
              />
            </svg>
          </NavLink>

          <NavLink
            to='/offers'
                   className={`p-2 transition-colors duration-300 rounded-lg 
              text-indigo-500 hover:text-indigo-800 
              dark:text-purple-300 dark:hover:text-orange-300
              focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-indigo-400 
              dark:focus:ring-purple-600`}
            title='العروض'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='size-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75'
              />
            </svg>
          </NavLink>

          <NavLink
            to='/products'
                   className={`p-2 transition-colors duration-300 rounded-lg 
              text-indigo-500 hover:text-indigo-800 
              dark:text-purple-300 dark:hover:text-orange-300
              focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-indigo-400 
              dark:focus:ring-purple-600`}
            title='المنتجات'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='w-6 h-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M13.5 21v-7.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349M3.75 21V9.349m0 0a3.001 3.001 0 0 0 3.75-.615A2.993 2.993 0 0 0 9.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 0 0 2.25 1.016c.896 0 1.7-.393 2.25-1.015a3.001 3.001 0 0 0 3.75.614m-16.5 0a3.004 3.004 0 0 1-.621-4.72l1.189-1.19A1.5 1.5 0 0 1 5.378 3h13.243a1.5 1.5 0 0 1 1.06.44l1.19 1.189a3 3 0 0 1-.621 4.72M6.75 18h3.75a.75.75 0 0 0 .75-.75V13.5a.75.75 0 0 0-.75-.75H6.75a.75.75 0 0 0-.75.75v3.75c0 .414.336.75.75.75Z'
              />
            </svg>
          </NavLink>

          <NavLink
            to='/employees'
                   className={`p-2 transition-colors duration-300 rounded-lg 
              text-indigo-500 hover:text-indigo-800 
              dark:text-purple-300 dark:hover:text-orange-300
              focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-indigo-400 
              dark:focus:ring-purple-600`}
            title='الموظفين'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='size-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z'
              />
            </svg>
          </NavLink>

          <NavLink
            to='/employees/designers'
                   className={`p-2 transition-colors duration-300 rounded-lg 
              text-indigo-500 hover:text-indigo-800 
              dark:text-purple-300 dark:hover:text-orange-300
              focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-indigo-400 
              dark:focus:ring-purple-600`}
            title='المصممون'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='w-6 h-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M9.53 16.122a3 3 0 0 0-5.78 1.128 2.25 2.25 0 0 1-2.4 2.245 4.5 4.5 0 0 0 8.4-2.245c0-.399-.078-.78-.22-1.128Zm0 0a15.998 15.998 0 0 0 3.388-1.62m-5.043-.025a15.994 15.994 0 0 1 1.622-3.395m3.42 3.42a15.995 15.995 0 0 0 4.764-4.648l3.876-5.814a1.151 1.151 0 0 0-1.597-1.597L14.146 6.32a15.996 15.996 0 0 0-4.649 4.763m3.42 3.42a6.776 6.776 0 0 0-3.42-3.42'
              />
            </svg>
          </NavLink>

          <NavLink
            to='/employees/sale-reps'
                   className={`p-2 transition-colors duration-300 rounded-lg 
              text-indigo-500 hover:text-indigo-800 
              dark:text-purple-300 dark:hover:text-orange-300
              focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-indigo-400 
              dark:focus:ring-purple-600`}
            title='مندوبي المبيعات'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='size-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M6.429 9.75 2.25 12l4.179 2.25m0-4.5 5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0 4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0-5.571 3-5.571-3'
              />
            </svg>
          </NavLink>

          <NavLink
            to='/employees/social-reps'
                   className={`p-2 transition-colors duration-300 rounded-lg 
              text-indigo-500 hover:text-indigo-800 
              dark:text-purple-300 dark:hover:text-orange-300
              focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-indigo-400 
              dark:focus:ring-purple-600`}
            title='مندوبين التسويق'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='size-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155'
              />
            </svg>
          </NavLink>

          <NavLink
            to='/users'
                   className={`p-2 transition-colors duration-300 rounded-lg 
              text-indigo-500 hover:text-indigo-800 
              dark:text-purple-300 dark:hover:text-orange-300
              focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-indigo-400 
              dark:focus:ring-purple-600`}
            title='المستخدمين و الأدوار'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='size-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z'
              />
            </svg>
          </NavLink>
          <NavLink
            to='/user-permissions'
                   className={`p-2 transition-colors duration-300 rounded-lg 
              text-indigo-500 hover:text-indigo-800 
              dark:text-purple-300 dark:hover:text-orange-300
              focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-indigo-400 
              dark:focus:ring-purple-600`}
            title='صلاحيات المستخدمين'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='size-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M6 13.5V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 9.75V10.5'
              />
            </svg>
          </NavLink>
          <NavLink
            to='/collects'
                   className={`p-2 transition-colors duration-300 rounded-lg 
              text-indigo-500 hover:text-indigo-800 
              dark:text-purple-300 dark:hover:text-orange-300
              focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-indigo-400 
              dark:focus:ring-purple-600`}
            title=' الحسابات'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='size-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z'
              />
            </svg>
          </NavLink>
        </div>
      </nav>

)}
      {/* Full Sidebar for mobile and large screens */}
      <div
        className={`fixed inset-y-0 right-0 z-30 transition-transform transform dark:bg-gray-800 shadow-lg ${
          isSidebarOpen ? 'translate-x-0' : 'translate-x-full'
        } ${isMobile || isHeaderFullScreen ? 'w-full' : 'md:w-64'} bg-white`}
      >


  <div className='flex items-center h-16 justify-center py-4'>
    <NavLink to='/'>
      <GardualText text='حداثة' />
    </NavLink>
  </div>

        <nav className='flex flex-col flex-1 px-4 space-y-0'>
        <SidebarLinkGroup activecondition={pathname === '/' || pathname.includes('dashboard')}>
    {(handleClick, open) => (
      <>
        {/* استخدام div بدل a لتغليف العنصر القابل للنقر */}
        <div
          className={`block text-gray-900 dark:text-gray-100 truncate transition-all duration-300 ${
            pathname === '/' || pathname.includes('dashboard')
              ? 'bg-blue-400 text-white dark:bg-blue-900 shadow-md'
              : 'hover:bg-gray-200 dark:hover:bg-gray-700'
          }`}
          onClick={handleClick}
        >
          <div className='flex items-center justify-between'>
            <div className="flex-1 py-4 space-y-4">
              <div className="flex items-center justify-center w-full space-x-2 text-white bg-blue-700 rounded-lg hover:shadow-lg transition-all">
                <span className="p-2 bg-blue-800 rounded-lg">
                  <svg
                    className={`shrink-0 fill-current ${
                      pathname.includes('dashboard') ? 'text-blue-500' : 'text-yellow-300 dark:text-white'
                    }`}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5 0a1 1 0 0 1 1 1v2a3 3 0 0 1-3 3H1a1 1 0 0 1 0-2h2a1 1 0 0 0 1-1V1a1 1 0 0 1 1-1zm6 0a1 1 0 0 1 1 1v2a3 3 0 0 1-3 3H7a1 1 0 0 1 0-2h2a1 1 0 0 0 1-1V1a1 1 0 0 1 1-1zm-6 13a3 3 0 0 0-3-3H1a1 1 0 0 0 0 2h2a1 1 0 0 1 1 1v2a1 1 0 0 0 2 0v-2zm6 0a3 3 0 0 0-3-3H7a1 1 0 0 0 0 2h2a1 1 0 0 1 1 1v2a1 1 0 0 0 2 0v-2z"/>
                  </svg>
                </span>
                <span className="text-center flex-1">الأقسام</span>
              </div>
            </div>
            <div className='flex shrink-0 mr-2'>
              <svg
                className={`w-3 h-3 shrink-0 mr-1 fill-current text-gray-500 dark:text-gray-400 ${open && 'rotate-180'}`}
                viewBox='0 0 12 12'
              >
                <path d='M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z' />
              </svg>
            </div>
          </div>
        </div>

        {/* Dropdown content */}
        <div className='lg:hidden lg:sidebar-expanded:block 2xl:block'>
          <ul className={`pr-4 bg-violet-100 dark:bg-gray-900 rounded-md ${!open && 'hidden'}`}>
            <li className='mb-1 last:mb-0'>
              <NavLink
                to='/'
                className={({ isActive }) =>
                  `block transition duration-150 truncate ${
                    isActive 
      ? 'text-gray-700 bg-gray-200 dark:bg-purple-700 dark:text-white' 
      : 'text-gray-600 dark:text-gray-400 hover:text-blue-500 hover:bg-gray-100 dark:hover:text-purple-400 dark:hover:bg-gray-800'
    }`
                }
              >
                <span className='text-sm font-medium'>الرئيسية</span>
              </NavLink>
            </li>
                    
                    
                        <li className='mb-1 last:mb-0'>
            <NavLink
              to='/customers'
              className={({ isActive }) =>
                `block transition duration-150 truncate ${
                 isActive 
      ? 'text-gray-700 bg-gray-200 dark:bg-purple-700 dark:text-white' 
      : 'text-gray-600 dark:text-gray-400 hover:text-blue-500 hover:bg-gray-100 dark:hover:text-purple-400 dark:hover:bg-gray-800'

                }`
              }
            >
              <span className='text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200'>
                العملاء
              </span>
            </NavLink>
          </li>
          <li className='mb-1 last:mb-0'>
            <NavLink
              to='/products'
              className={({ isActive }) =>
                `block transition duration-150 truncate ${
                 isActive 
      ? 'text-gray-700 bg-gray-200 dark:bg-purple-700 dark:text-white' 
      : 'text-gray-600 dark:text-gray-400 hover:text-blue-500 hover:bg-gray-100 dark:hover:text-purple-400 dark:hover:bg-gray-800'

                }`
              }
            >
              <span className='text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200'>
                المتجر
              </span>
            </NavLink>
          </li>
          <li className='mb-1 last:mb-0'>
            <NavLink
              to='/orders'
              className={({ isActive }) =>
                `block transition duration-150 truncate ${
                 isActive 
      ? 'text-gray-700 bg-gray-200 dark:bg-purple-700 dark:text-white' 
      : 'text-gray-600 dark:text-gray-400 hover:text-blue-500 hover:bg-gray-100 dark:hover:text-purple-400 dark:hover:bg-gray-800'

                }`
              }
            >
              <span className='text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200'>
                الطلبات
              </span>
            </NavLink>
          </li>
          <li className='mb-1 last:mb-0'>
            <NavLink
              to='/offers'
              className={({ isActive }) =>
                `block transition duration-150 truncate ${
                 isActive 
      ? 'text-gray-700 bg-gray-200 dark:bg-purple-700 dark:text-white' 
      : 'text-gray-600 dark:text-gray-400 hover:text-blue-500 hover:bg-gray-100 dark:hover:text-purple-400 dark:hover:bg-gray-800'

                }`
              }
            >
              <span className='text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200'>
                عروض الأسعار
              </span>
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  )}
</SidebarLinkGroup>

          {/* employees */}
 
  {/* شئون العاملين */}
  <SidebarLinkGroup activecondition={pathname === '/employees' || pathname.includes('employees')}>
    {(handleClick, open) => (
      <>
        {/* استخدام div بدل a لتغليف العنصر القابل للنقر */}
        <div
          className={`block text-gray-900 dark:text-gray-100 truncate transition-all duration-300 ${
            pathname === '/employees' || pathname.includes('employees')
              ? 'bg-cyan-400 text-white dark:bg-cyan-900 shadow-md'
              : 'hover:bg-gray-200 dark:hover:bg-gray-700'
          }`}
          onClick={handleClick}
        >
          <div className='flex items-center justify-between'>
            <div className="flex-1 py-4 space-y-4">
              <div className="flex items-center justify-center w-full space-x-2 text-white bg-cyan-700 rounded-lg hover:shadow-lg transition-all">
                <span className="p-2 bg-cyan-800 rounded-lg">
                  <svg
                    className={`shrink-0 fill-current ${
                      pathname.includes('employees') ? 'text-cyan-500' : 'text-yellow-300 dark:text-white'
                    }`}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5 0a1 1 0 0 1 1 1v2a3 3 0 0 1-3 3H1a1 1 0 0 1 0-2h2a1 1 0 0 0 1-1V1a1 1 0 0 1 1-1zm6 0a1 1 0 0 1 1 1v2a3 3 0 0 1-3 3H7a1 1 0 0 1 0-2h2a1 1 0 0 0 1-1V1a1 1 0 0 1 1-1zm-6 13a3 3 0 0 0-3-3H1a1 1 0 0 0 0 2h2a1 1 0 0 1 1 1v2a1 1 0 0 0 2 0v-2zm6 0a3 3 0 0 0-3-3H7a1 1 0 0 0 0 2h2a1 1 0 0 1 1 1v2a1 1 0 0 0 2 0v-2z"/>
                  </svg>
                </span>
                <span className="text-center flex-1">شئون العاملين</span>
              </div>
            </div>
            <div className='flex shrink-0 mr-2'>
              <svg
                className={`w-3 h-3 shrink-0 mr-1 fill-current text-gray-500 dark:text-gray-400 ${open && 'rotate-180'}`}
                viewBox='0 0 12 12'
              >
                <path d='M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z' />
              </svg>
            </div>
          </div>
        </div>

        {/* Dropdown content */}
        <div className='lg:hidden lg:sidebar-expanded:block 2xl:block'>
          <ul className={`pr-4 bg-violet-100 dark:bg-gray-900 rounded-md ${!open && 'hidden'}`}>
            <li className='mb-1 last:mb-0'>
              <NavLink
                end
                to='/employees'
                className={({ isActive }) =>
                  `block transition duration-150 truncate ${
                    isActive ? 'text-cyan-700 bg-gray-200 dark:bg-gray-700' : 'text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-white'
                  }`
                }
              >
                <span className='text-sm font-medium'>الإداريين</span>
              </NavLink>
            </li>
            <li className='mb-1 last:mb-0'>
              <NavLink
                end
                to='/employees/designers'
                className={({ isActive }) =>
                  'block transition duration-150 truncate ' +
                  (isActive ? 'text-cyan-700 bg-gray-200 dark:bg-gray-700' : 'text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-white')
                }
              >
                <span className='text-sm font-medium'>المصممين</span>
              </NavLink>
            </li>
            <li className='mb-1 last:mb-0'>
              <NavLink
                end
                to='/employees/social-reps'
                className={({ isActive }) =>
                  'block transition duration-150 truncate ' +
                  (isActive
                    ? 'text-violet-500'
                    : 'text-gray-500/90 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200')
                }
              >
                <span className='text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200'>
                  مندوبي التسويق
                </span>
              </NavLink>
            </li>
            <li className='mb-1 last:mb-0'>
              <NavLink
                end
                to='/employees/sale-reps'
                className={({ isActive }) =>
                  'block transition duration-150 truncate ' +
                  (isActive
                    ? 'text-violet-500'
                    : 'text-gray-500/90 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200')
                }
              >
                <span className='text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200'>
                  مندوبي المبيعات
                </span>
              </NavLink>
              </li>
        </ul>
      </div>
    </>
  )}
</SidebarLinkGroup>

          {/* users */}
        
  <SidebarLinkGroup activecondition={pathname === '/users' || pathname.includes('users')}>
    {(handleClick, open) => (
      <>
        {/* استخدام div بدل a لتغليف العنصر القابل للنقر */}
        <div
          className={`block text-gray-900 dark:text-gray-100 truncate transition-all duration-300 ${
            pathname === '/users' || pathname.includes('users')
              ? 'bg-red-400 text-white dark:bg-red-900 shadow-md'
              : 'hover:bg-gray-200 dark:hover:bg-gray-700'
          }`}
          onClick={handleClick}
        >
          <div className='flex items-center justify-between'>
            <div className="flex-1 py-4 space-y-4">
              <div className="flex items-center justify-center w-full space-x-2 text-white bg-red-700 rounded-lg hover:shadow-lg transition-all">
                <span className="p-2 bg-red-800 rounded-lg">
                  <svg
                    className={`shrink-0 fill-current ${
                      pathname.includes('users') ? 'text-white' : 'text-yellow-300 dark:text-white'
                    }`}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5 0a1 1 0 0 1 1 1v2a3 3 0 0 1-3 3H1a1 1 0 0 1 0-2h2a1 1 0 0 0 1-1V1a1 1 0 0 1 1-1zm6 0a1 1 0 0 1 1 1v2a3 3 0 0 1-3 3H7a1 1 0 0 1 0-2h2a1 1 0 0 0 1-1V1a1 1 0 0 1 1-1zm-6 13a3 3 0 0 0-3-3H1a1 1 0 0 0 0 2h2a1 1 0 0 1 1 1v2a1 1 0 0 0 2 0v-2zm6 0a3 3 0 0 0-3-3H7a1 1 0 0 0 0 2h2a1 1 0 0 1 1 1v2a1 1 0 0 0 2 0v-2z"/>
                  </svg>
                </span>
                <span className="text-center flex-1">المستخدمين</span>
              </div>
            </div>
            <div className='flex shrink-0 mr-2'>
              <svg
                className={`w-3 h-3 shrink-0 mr-1 fill-current text-gray-500 dark:text-gray-400 ${open && 'rotate-180'}`}
                viewBox='0 0 12 12'
              >
                <path d='M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z' />
              </svg>
            </div>
          </div>
        </div>

        {/* Dropdown content */}
        <div className='lg:hidden lg:sidebar-expanded:block 2xl:block'>
          <ul className={`pr-4  bg-violet-100 dark:bg-gray-900 rounded-md ${!open && 'hidden'}`}>
            <li className='mb-1 last:mb-0'>
              <NavLink
                end
                to='/users'
                className={({ isActive }) =>
                  `block transition duration-150 truncate ${
                   isActive 
      ? 'text-gray-700 bg-gray-200 dark:bg-purple-700 dark:text-white' 
      : 'text-gray-600 dark:text-gray-400 hover:text-blue-500 hover:bg-gray-100 dark:hover:text-purple-400 dark:hover:bg-gray-800'

                  }`
                }
              >
                <span className='text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200'>
                  الأدوار والمستخدمين
                </span>
              </NavLink>
            </li>
            <li className='mb-1 last:mb-0'>
              <NavLink
                end
                to='/user-permissions'
                className={({ isActive }) =>
                  `block transition duration-150 truncate ${
                    isActive ? 'text-violet-500' : 'text-gray-500/90 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200'
                  }`
                }
              >
                <span className='text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200'>
                  صلاحيات المستخدم
                </span>
              </NavLink>     </li>
        </ul>
      </div>
    </>
  )}
</SidebarLinkGroup>
      

          {/* invoices */}
        
          <SidebarLinkGroup activecondition={pathname === '/invoices' || pathname.includes('invoices')}>
    {(handleClick, open) => (
      <>
        {/* استخدام div بدل a لتغليف العنصر القابل للنقر */}
        <div
          className={`block text-gray-900 dark:text-gray-100 truncate transition-all duration-300 ${
            pathname === '/invoices' || pathname.includes('invoices')
              ? 'bg-violet-400 text-white dark:bg-blue-900 shadow-md'
              : 'hover:bg-gray-200 dark:hover:bg-gray-700'
          }`}
          onClick={handleClick}
        >
          <div className='flex items-center justify-between'>
            <div className="flex-1 py-4 space-y-4">
              <div className="flex items-center justify-center w-full space-x-2 text-white bg-green-700 rounded-lg hover:shadow-lg transition-all">
                <span className="p-2 bg-green-800 rounded-lg">
                  <svg
                    className={`shrink-0 fill-current ${
                      pathname.includes('invoices') ? 'text-white' : 'text-yellow-300 dark:text-white'
                    }`}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5 0a1 1 0 0 1 1 1v2a3 3 0 0 1-3 3H1a1 1 0 0 1 0-2h2a1 1 0 0 0 1-1V1a1 1 0 0 1 1-1zm6 0a1 1 0 0 1 1 1v2a3 3 0 0 1-3 3H7a1 1 0 0 1 0-2h2a1 1 0 0 0 1-1V1a1 1 0 0 1 1-1zm-6 13a3 3 0 0 0-3-3H1a1 1 0 0 0 0 2h2a1 1 0 0 1 1 1v2a1 1 0 0 0 2 0v-2zm6 0a3 3 0 0 0-3-3H7a1 1 0 0 0 0 2h2a1 1 0 0 1 1 1v2a1 1 0 0 0 2 0v-2z"/>
                  </svg>
                </span>
                <span className="text-center flex-1">الشئون المالية</span>
              </div>
            </div>
            <div className='flex shrink-0 mr-2'>
              <svg
                className={`w-3 h-3 shrink-0 mr-1 fill-current text-gray-500 dark:text-gray-400 ${open && 'rotate-180'}`}
                viewBox='0 0 12 12'
              >
                <path d='M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z' />
              </svg>
            </div>
          </div>
        </div>

        {/* Dropdown content */}
        <div className='lg:hidden lg:sidebar-expanded:block 2xl:block'>
          <ul className={`pr-4  bg-gray-100 dark:bg-gray-900 rounded-md ${!open && 'hidden'}`}>
            <li className='mb-1 last:mb-0'>
               <NavLink
                end
                to='/invoices'
                className={({ isActive }) =>
                  `block transition duration-150 truncate ${
                    isActive ? 'text-green-700 bg-gray-200 dark:bg-gray-700' : 'text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-white'
                  }`
                }
              >
                <span className='text-sm font-medium'>الفواتير</span>
              </NavLink>
            </li>
        </ul>
      </div>
    </>
  )}
</SidebarLinkGroup>

        </nav>
      </div>
    </>
  );
};

export default Sidebar;
