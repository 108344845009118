import React from 'react';
import {
  RiDiscountPercentFill,
  RiPriceTag2Fill,
  RiMapPinTimeFill,
} from 'react-icons/ri';

const OrderDiscountAndTax = ({
  taxRate,
  setTaxRate,
  discountRate,
  setDiscountRate,
  timePlementationRange,
  setTimePlementationRange,
  error,
}) => {
  const today = new Date();
  const sixtyDaysFromNow = new Date();
  sixtyDaysFromNow.setDate(today.getDate() + 60);

  const formattedToday = today.toISOString().split('T')[0];
  const formattedSixtyDaysFromNow = sixtyDaysFromNow
    .toISOString()
    .split('T')[0];

  return (
    <div className='bg-gray-100 dark:bg-gray-800 transition-colors duration-500 p-6 rounded-lg shadow-lg'>
      <h3 className='text-2xl font-bold mt-2 text-center text-yellow-500 dark:text-yellow-300 mb-4'>
        نظام الخصم والضريبة
      </h3>
      <div className='grid grid-cols-1 md:grid-cols-3 gap-10 mb-5'>
        <InputField
          label='نسبة الضريبة'
          Icon={RiDiscountPercentFill}
          id='taxRate'
          min={0}
          type='number'
          value={taxRate}
          onChange={e => setTaxRate(parseFloat(e.target.value))}
        />
        <InputField
          label='نسبة الخصم'
          Icon={RiPriceTag2Fill}
          id='discountRate'
          type='number'
          value={discountRate}
          onChange={e => setDiscountRate(parseFloat(e.target.value))}
        />
        <InputField
          label='مدة التنفيذ'
          Icon={RiMapPinTimeFill}
          id='timePlementationRange'
          type='number'
          value={timePlementationRange}
          onChange={e => setTimePlementationRange(e.target.value)}
          min={1}
          max={60}
        />
      </div>
    </div>
  );
};

const InputField = ({ label, Icon, id, type, value, onChange, error, min }) => (
  <div className='mb-4 flex flex-col items-center'>
    <label
      htmlFor={id}
      className='block text-center text-lg text-gray-700 dark:text-gray-300'
    >
      <Icon className='text-lg inline-block ml-2' />
      {label}:
    </label>
    <input
      type={type}
      id={id}
      className='mt-1 block w-full p-2 border rounded text-center bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100'
      value={value}
      onChange={onChange}
      min={min}
      aria-label={label}
    />
    {error && <p className='text-red-400 text-xs mt-1'>{error}</p>}
  </div>
);

export default OrderDiscountAndTax;
