import React from 'react';

const ExecutionOrders = () => {
  return (
    <div
      style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}
    >
      <div className='grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3'>
        <h2>تنفيذ</h2>
      </div>
    </div>
  );
};

export default ExecutionOrders;
