import React from 'react';
import BarChart from '../charts/BarChart01';
import { tailwindConfig } from '../../../../utils/Utils';

function DashboardCard04() {
  const chartData = {
    labels: [
      '12-01-2022',
      '01-01-2023',
      '02-01-2023',
      '03-01-2023',
      '04-01-2023',
      '05-01-2023',
    ],
    datasets: [
      {
        label: 'مباشر',
        data: [800, 1600, 900, 1300, 1950, 1700],
        backgroundColor: tailwindConfig().theme.colors.sky[500],
        hoverBackgroundColor: tailwindConfig().theme.colors.sky[600],
        barPercentage: 0.7,
        categoryPercentage: 0.7,
        borderRadius: 4,
      },
      {
        label: 'غير مباشر',
        data: [4900, 2600, 5350, 4800, 5200, 4800],
        backgroundColor: tailwindConfig().theme.colors.violet[500],
        hoverBackgroundColor: tailwindConfig().theme.colors.violet[600],
        barPercentage: 0.7,
        categoryPercentage: 0.7,
        borderRadius: 4,
      },
    ],
  };

  return (
    <div className='flex flex-col col-span-full sm:col-span-6 bg-white dark:bg-gray-800 shadow-sm rounded-xl'>
      <header className='px-5 py-4 border-b border-gray-100 dark:border-gray-700/60'>
        <h2 className='font-semibold text-gray-800 dark:text-gray-100'>
          مباشر مقابل غير مباشر
        </h2>
      </header>
      <div className='px-5 py-3'>
        {typeof window !== 'undefined' && (
          <BarChart data={chartData} width={595} height={248} />
        )}
      </div>
    </div>
  );
}

export default DashboardCard04;
