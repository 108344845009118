import React, { useState, useEffect } from 'react';
import API_CONFIG from '../../../ApiConfig';
import { IoMdCreate } from 'react-icons/io';
import useAuth from '../../Auth/Authentication/AuthUser';
import { fetchCategories } from '../../Routes/api';

const ProductModalForm = ({ product, onClose, refreshProducts, isOpen }) => {
  const [categories, setCategories] = useState([]);
  const [message, setMessage] = useState({});
  const { http } = useAuth();
  const [validationErrors, setValidationErrors] = useState({});
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: '',
    category_id: '',
    image: null,
  });
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    const fetchCategoriesData = async () => {
      try {
        const categoriesData = await fetchCategories(http);
        setCategories(categoriesData);
      } catch (error) {
        console.error('Failed to fetch categories:', error);
      }
    };

    fetchCategoriesData();
  }, []);

  useEffect(() => {
    if (isOpen && product) {
      setFormData({
        name: product.name,
        description: product.description,
        price: product.price,
        category_id: product.category_id,
        image: product.image ? `${API_CONFIG.baseURL}${product.image}` : null,
      });
    } else {
      setFormData({
        name: '',
        description: '',
        price: '',
        category_id: '',
        image: null,
      });
      setImageFile(null);
    }
  }, [product, isOpen]);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleFileChange = e => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setFormData(prevState => ({
        ...prevState,
        image: URL.createObjectURL(file),
      }));
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const formDataToSend = new FormData();

    formDataToSend.append('name', formData.name);
    formDataToSend.append('description', formData.description);
    formDataToSend.append('price', formData.price);
    formDataToSend.append('category_id', formData.category_id);

    if (imageFile) {
      formDataToSend.append('image', imageFile);
    }

    try {
      const endpoint =
        product && product.id ? `/api/products/${product.id}` : '/api/products';
      const method = product && product.id ? 'PATCH' : 'POST';
      formDataToSend.append('_method', method);

      await http.post(endpoint, formDataToSend);
      onClose();
      refreshProducts();
    } catch (error) {
      console.error('Error saving product:', error);
      setValidationErrors(error.response.data.errors || {});
    }
  };

  if (!isOpen) return null;

  const formFields = [
    {
      id: 'name',
      name: 'name',
      type: 'text',
      placeholder: 'الاسم',
      icon: IoMdCreate,
    },
    {
      id: 'description',
      name: 'description',
      type: 'text',
      placeholder: 'الوصف',
      icon: IoMdCreate,
    },
    {
      id: 'price',
      name: 'price',
      type: 'number',
      placeholder: 'السعر',
      icon: IoMdCreate,
    },
    {
      id: 'category_id',
      name: 'category_id',
      type: 'select',
      placeholder: 'إختر التصنيف',
      icon: IoMdCreate,
    },
  ];

  return (
    <div
      className={`fixed inset-0 z-50 overflow-y-auto ${!isOpen ? 'hidden' : ''}`}
    >
      <div className='flex items-center justify-center min-h-screen'>
        <div
          className='fixed inset-0 bg-gray-600 bg-opacity-50 transition-opacity'
          onClick={onClose}
        ></div>
        <div className='bg-white dark:bg-gray-800 rounded-lg shadow-xl transform transition-all sm:max-w-lg sm:w-full p-6'>
          {message.text && (
            <div
              className={`${
                message.type === 'success'
                  ? 'bg-green-100 border border-green-400 text-green-700'
                  : 'bg-red-100 border border-red-400 text-red-700'
              } px-4 py-3 rounded relative mb-4`}
              role='alert'
            >
              <span className='block sm:inline'>{message.text}</span>
            </div>
          )}
          <div className='mb-4 border-0 text-center bg-gray-100 dark:bg-gray-800 border-gray-300'>
            <h2 className='text-xl font-bold text-gray-800 dark:text-violet-600'>
              {product ? 'تعديل المنتج' : 'إضافة منتج'}
            </h2>
          </div>
          <form onSubmit={handleSubmit} className='space-y-6'>
            {formFields.map(field => (
              <div
                key={field.id}
                className='flex items-center border-b border-gray-300 py-2'
              >
                <field.icon className='text-lg text-gray-800 dark:text-violet-600 mr-2' />
                {field.type === 'select' ? (
                  <select
                    name={field.name}
                    id={field.id}
                    value={formData[field.name]}
                    onChange={handleChange}
                    required
                    className='w-full bg-transparent bg-white p-2 text-gray-900 font-bold border-none text-right'
                  >
                    <option value=''>{field.placeholder}</option>
                    {categories.map(category => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    type={field.type}
                    name={field.name}
                    id={field.id}
                    placeholder={field.placeholder}
                    value={formData[field.name]}
                    onChange={handleChange}
                    required
                    className='w-full bg-transparent bg-white p-2 text-gray-900 font-bold border-none text-right'
                  />
                )}
              </div>
            ))}
            {validationErrors && (
              <div className='text-red-500 text-sm'>
                {Object.keys(validationErrors).map(key => (
                  <div key={key}>{validationErrors[key][0]}</div>
                ))}
              </div>
            )}
            <div className='mb-4'>
              <label
                htmlFor='image'
                className='block text-gray-700 dark:text-violet-600 font-bold mb-2'
              >
                Image
              </label>
              <input
                type='file'
                name='image'
                onChange={handleFileChange}
                className='appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none'
              />
              {formData.image && (
                <div>
                  <img
                    src={formData.image}
                    alt='Product Image'
                    className='w-32 h-32 object-cover'
                  />
                </div>
              )}
            </div>
            <div className='flex justify-around mt-4'>
              <button
                type='button'
                className='bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700'
                onClick={onClose}
              >
                إلغاء
              </button>
              <button
                type='submit'
                className='py-2 px-4 text-sm font-medium text-white bg-violet-600 rounded-md hover:bg-violet-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
              >
                {product ? 'تعديل' : 'حفظ'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProductModalForm;
