import React, { useState, useEffect, useCallback } from 'react';
import { fetchCustomers, deleteCustomer } from '../Routes/api';
import TableComponent from '../Tools/TableComponent';
import { IconCustomers } from '../../assets/img/icons/index';
import SectionHeader from '../Tools/SectionHeader';
import CustomerModalForm from './CustomerModalForm';
import useAuth from '../Auth/Authentication/AuthUser';
import { AiOutlinePlus } from 'react-icons/ai';
import LogoImageSpinner from '../Tools/LogoImageSpinner';
import DeleteConfirmationModal from '../Tools/DeleteConfirmationModal';

const CustomerList = () => {
  const [customers, setCustomers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [customerIdToDelete, setCustomerIdToDelete] = useState(null);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { http } = useAuth();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setError('');
      setSuccessMessage('');
    }, 3000);

    return () => clearTimeout(timer);
  }, [error, successMessage]);

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const customersData = await fetchCustomers(http);
      setCustomers(customersData);
      setError('');
    } catch (error) {
      setError('Failed to fetch data. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  const headers = [
    { key: 'id', text: 'ID' },
    { key: 'name', text: 'الأسم' },
    { key: 'phone', text: 'رقم الهاتف' },
    { key: 'country', text: 'الدولة' },
    { key: 'gender', text: 'النوع' },
  ];

  const refreshCustomers = async () => {
    await getData();
  };

  const handleEditClick = customerId => {
    const customerToEdit = customers.find(
      customer => customer['id'] === customerId
    );
    setSelectedCustomer(customerToEdit);
    setShowModal(true);
  };

  const handleDeleteConfirmation = customerId => {
    promptDeleteCustomer(customerId);
  };

  const promptDeleteCustomer = customerId => {
    setCustomerIdToDelete(customerId);
    setShowDeleteConfirmation(true);
  };

  const handleDeleteClick = async () => {
    if (customerIdToDelete) {
      try {
        await deleteCustomer(http, customerIdToDelete);
        setCustomers(
          customers.filter(customer => customer.id !== customerIdToDelete)
        );
        setSuccessMessage('تم حذف العميل بنجاح');
        setShowDeleteConfirmation(false);
      } catch (error) {
        console.error('Error deleting customer:', error);
        setError(
          'An error occurred while deleting the customer. Please try again.'
        );
        setShowDeleteConfirmation(false);
      }
    } else {
      setError('Error: Attempted to delete without the correct customer ID');
    }
  };

  const renderAddButton = () => {
    return (
      <button
        onClick={() => {
          setSelectedCustomer(null);
          setShowModal(true);
        }}
        className='flex items-center px-4 py-2 bg-violet-500 rounded-xl hover:bg-violet-700 text-white transition-all duration-300 ease-in-out'
      >
        <AiOutlinePlus className='mr-2' /> إضافة عميل
      </button>
    );
  };

  return (
    <>
      {showModal && (
        <CustomerModalForm
          isOpen={showModal}
          customer={selectedCustomer}
          onClose={() => setShowModal(false)}
          refreshCustomers={refreshCustomers}
          setSuccessMessage={setSuccessMessage}
        />
      )}
      <SectionHeader sectionTitle='قائمة العملاء' imageSrc={IconCustomers} />
      {error && (
        <div
          className='bg-red-100 border border-red-400 text-center text-red-700 dark:bg-red-500 dark:text-red-200 px-4 py-3 rounded relative mb-4'
          role='alert'
        >
          <strong className='font-bold'>Error: </strong>
          <span className='block sm:inline'>{error}</span>
        </div>
      )}
      {successMessage && (
        <div
          className='bg-green-100 border neon-shadow border-green-400 text-center text-green-700 dark:bg-green-500 dark:text-green-200 px-4 py-3 rounded relative mb-4'
          role='alert'
        >
          <strong className='font-bold'>Success: </strong>
          <span className='block sm:inline'>{successMessage}</span>
        </div>
      )}

      {showDeleteConfirmation && (
        <DeleteConfirmationModal
          onDelete={handleDeleteClick}
          onCancel={() => setShowDeleteConfirmation(false)}
        />
      )}
      <div className='overflow-auto'>
        {loading ? (
          <div className='flex items-center justify-center'>
            <LogoImageSpinner />
          </div>
        ) : (
          <div className='container mx-auto h-full px-4 pt-10'>
            <div className='p-5 bg-gradient-to-r from-blue-200/[0.8] to-blue-100/[0.8] dark:from-gray-800/[0.8] dark:to-gray-900/[0.8] rounded-3xl shadow-xl'>
              <div className='mb-4 flex justify-end'>{renderAddButton()}</div>
              <TableComponent
                data={customers}
                headers={headers}
                onDelete={handleDeleteConfirmation}
                onEdit={handleEditClick}
                sectionName={'Customers'}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CustomerList;
