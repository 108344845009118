import React from 'react';
import { CiSquareRemove } from 'react-icons/ci';
import { BsTrash } from 'react-icons/bs';

const OrderProducts = ({ products, handleRemoveProduct }) => {
  return (
    <div className='overflow-x-auto   '>
      <h2 className='text-lg font-semibold p-4 text-center text-yellow-400 dark:text-yellow-300'>
        سلة المشتريات
      </h2>

      <table className='min-w-full divide-y divide-gray-200 mb-6'>
        <thead>
          <tr className='font-semibold text-gray-900 dark:text-white bg-gray-50 dark:bg-gray-700 uppercase tracking-wider'>
            <th className='px-3 py-2 border border-gray-300 shadow-lg text-center text-xs sm:text-sm'>
              #
            </th>
            <th className='px-3 py-2 border border-gray-300 shadow-lg text-center text-xs sm:text-sm'>
              المنتج
            </th>
            <th className='px-3 py-2 border border-gray-300 shadow-lg text-center text-xs sm:text-sm'>
              التصنيف
            </th>
            <th className='px-3 py-2 border border-gray-300 shadow-lg text-center text-xs sm:text-sm'>
              الكمية
            </th>
            <th className='px-3 py-2 border border-gray-300 shadow-lg text-center text-xs sm:text-sm'>
              سعر الوحدة
            </th>
            <th className='px-3 py-2 border border-gray-300 shadow-lg text-center text-xs sm:text-sm'>
              إجمالى السعر
            </th>
            <th className='px-3 py-2 border border-gray-300 shadow-lg text-center text-xs sm:text-sm'>
              ملاحظات
            </th>
            <th className='px-3 py-2 border border-gray-300 shadow-lg text-center text-xs sm:text-sm'>
              <CiSquareRemove className='text-xl text-red-600' />
            </th>
          </tr>
        </thead>
        <tbody>
          {products.map((product, index) => (
            <tr
              key={product.slug}
              className={`text-sm font-medium text-gray-700 dark:text-gray-300 ${index % 2 === 0 ? 'bg-gray-50 dark:bg-gray-700' : 'bg-gray-100 dark:bg-gray-600'}`}
            >
              <td className='text-center px-2 py-1 border border-gray-200 shadow-lg text-violet-500 dark:text-yellow-300'>
                {product.id} {/* تحديث هنا ليعرض slug بدلاً من معرف المنتج */}
              </td>

              <td className='text-center px-2 py-1 border border-gray-200 shadow-lg text-violet-500 dark:text-yellow-300'>
                {product.name}
              </td>
              <td className='text-center px-2 py-1 border border-gray-200 shadow-lg'>
                {product.category}
              </td>
              <td className='text-center px-2 py-1 border border-gray-200 shadow-lg'>
                {product.quantity}
              </td>
              <td className='text-center px-2 py-1 border border-gray-200 shadow-lg'>
                ${product.price}
              </td>
              <td className='text-center px-2 py-1 border border-gray-200 shadow-lg'>
                ${(product.price * product.quantity).toFixed(2)}
              </td>
              <td className='text-center px-2 py-1 border border-gray-200 shadow-lg'>
                {product.notes}
              </td>
              <td className='text-center px-2 py-1 border border-gray-200 shadow-lg'>
                <button
                  onClick={() => handleRemoveProduct(product.slug)}
                  aria-label={`Remove ${product.slug}`}
                >
                  <BsTrash className='text-xl text-red-600' />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrderProducts;
