const OrderCategoryProducts = ({
  categories,
  selectedCategory,
  handleCategorySelect,
  filteredProducts,
  productInputs,
  setProductInputs,
  handleAddToProductList,
  showAddedProductMessage,
  addedProductMessage,
}) => {
  return (
    <>
      <div className='col-span-2'>
        <h3 className='text-lg text-center font-extrabold text-orange-400 shadow-lg rounded-lg overflow-hidden mb-4 p-2'>
          التصنيف
        </h3>

        <select
          className='mt-1 block w-full bg-gray-300 text-gray-800 border-gray-600 rounded-md focus:ring-indigo-500 focus:border-indigo-500 text-center'
          value={selectedCategory}
          onChange={e => handleCategorySelect(e.target.value)}
          style={{
            height: '40px',
            padding: '6px 12px',
            fontSize: '16px',
          }} // تم تعديل الارتفاع والحشو وحجم الخط
        >
          <option value=''>اختر التصنيف </option>
          {categories.map(category => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>
      <div className='col-span-2'>
        <h2 className='text-lg font-extrabold text-center text-orange-400 shadow-lg rounded-lg overflow-hidden mb-4 p-2'>
          المنتجات
        </h2>

        {filteredProducts.length > 0 ? (
          <div className='overflow-x-auto'>
            {filteredProducts.map(product => (
              <div
                key={product.id}
                className='flex flex-col px-4 py-2 rounded-md mb-2'
              >
                <div className='text-green-500 text-center mb-2'>
                  {product.name}
                </div>
                <div className='flex items-center justify-center mb-2'>
                  <input
                    type='number'
                    min='1'
                    value={productInputs[product.id]?.quantity}
                    onChange={e =>
                      setProductInputs(prev => ({
                        ...prev,
                        [product.id]: {
                          ...prev[product.id],
                          quantity: parseInt(e.target.value),
                        },
                      }))
                    }
                    className='border border-gray-600 text-center text-gray-800 rounded px-2 py-1 w-full max-w-xs'
                  />
                </div>
                <div className='flex items-center justify-center mb-2'>
                  <input
                    type='text'
                    value={productInputs[product.id]?.notes}
                    onChange={e =>
                      setProductInputs(prev => ({
                        ...prev,
                        [product.id]: {
                          ...prev[product.id],
                          notes: e.target.value,
                        },
                      }))
                    }
                    className='border border-gray-600 text-center text-gray-800 rounded px-2 py-1 w-full max-w-xs'
                    placeholder='الملاحظات'
                  />
                </div>
                <div className='flex items-center justify-center'>
                  <button
                    type='button'
                    onClick={() =>
                      handleAddToProductList(
                        product,
                        productInputs[product.id]?.quantity,
                        productInputs[product.id]?.notes
                      )
                    }
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M12 6v6m0 0v6m0-6h6m-6 0H6'
                      />
                    </svg>
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className='text-lg font-semibold text-violet-500 dark:text-green-400 text-center'>
            لا توجد منتجات متاحة في هذا التصنيف
          </p>
        )}
      </div>
      {showAddedProductMessage && (
        <div className='added-product-message'>{addedProductMessage}</div>
      )}
    </>
  );
};

export default OrderCategoryProducts;
