import React, { Fragment } from 'react';
import { Text, View, StyleSheet, Font } from '@react-pdf/renderer';

import fontRegular from '../../../../assets/fonts/static/Cairo-Regular.ttf';
import fontBold from '../../../../assets/fonts/static/Cairo-Bold.ttf';

// تسجيل الخطوط
Font.register({
  family: 'Cairo-Regular',
  format: 'truetype',
  src: fontRegular,
});
Font.register({
  family: 'Cairo-Bold',
  format: 'truetype',
  src: fontBold,
});

// إعداد الأنماط
const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 20,
  },
  headerItem: {
    marginHorizontal: 10,
  },
  textBold: {
    fontSize: 12,
    fontFamily: 'Cairo-Bold',
  },
  textBg: {
    fontSize: 12,
    fontFamily: 'Cairo-Regular',
    backgroundColor: '#FAE4CF',
    padding: 5,
  },
});

// مكون العرض
const OfferNo = ({ offerNumber, offerDate }) => (
  <Fragment>
    <View style={styles.header}>
      <Text style={[styles.headerItem, styles.textBg]}>{offerNumber}</Text>
      <Text style={[styles.headerItem, styles.textBold]}>: رقم</Text>
      <Text style={[styles.headerItem, styles.textBg]}>{offerDate}</Text>
      <Text style={[styles.headerItem, styles.textBold]}>: تاريخ</Text>
    </View>
  </Fragment>
);

export default OfferNo;
