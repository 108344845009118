import React from 'react';
import { BsTrash } from 'react-icons/bs';
import { CiSquareRemove } from 'react-icons/ci';

const OfferProducts = ({ products, handleRemoveProduct }) => {
  return (
    <div className='neon-shadow overflow-x-auto '>
      <h2 className='text-lg font-semibold p-4 text-center text-orange-400  00 dark:text-yellow-300'>
        سلة المشتريات
      </h2>

      <table className='min-w-full divide-y divide-gray-300 dark:divide-gray-600 mb-6 bg-white dark:bg-gray-900'>
        <thead>
          <tr className='font-semibold text-gray-900 dark:text-white bg-gray-300 dark:bg-gray-800 uppercase tracking-wider'>
            <th className='px-4 py-2 border border-gray-300 dark:border-gray-600 text-center text-xs sm:text-sm'>
              م
            </th>
            <th className='px-4 py-2 border border-gray-300 dark:border-gray-600 text-center text-xs sm:text-sm'>
              المنتج
            </th>
            <th className='px-4 py-2 border border-gray-300 dark:border-gray-600 text-center text-xs sm:text-sm'>
              التصنيف
            </th>
            <th className='px-4 py-2 border border-gray-300 dark:border-gray-600 text-center text-xs sm:text-sm'>
              الكمية
            </th>
            <th className='px-4 py-2 border border-gray-300 dark:border-gray-600 text-center text-xs sm:text-sm'>
              سعر الوحدة
            </th>
            <th className='px-4 py-2 border border-gray-300 dark:border-gray-600 text-center text-xs sm:text-sm'>
              إجمالى السعر
            </th>
            <th className='px-4 py-2 border border-gray-300 dark:border-gray-600 text-center text-xs sm:text-sm'>
              ملاحظات
            </th>
            <th className='px-4 py-2 border border-gray-300 dark:border-gray-600 text-center text-xs sm:text-sm'>
              <CiSquareRemove className='text-xl text-red-500 dark:text-red-400' />
            </th>
          </tr>
        </thead>
        <tbody>
          {products.map((product, index) => (
            <tr
              key={product.slug}
              className={`text-sm font-medium ${index % 2 === 0 ? 'bg-gray-50 dark:bg-gray-800' : 'bg-gray-100 dark:bg-gray-700'} hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors`}
            >
              <td className='text-center px-4 py-2 border border-gray-300 dark:border-gray-600 text-violet-600 dark:text-yellow-400'>
                {product.id}
              </td>
              <td className='text-center px-4 py-2 border border-gray-300 dark:border-gray-600 text-violet-600 dark:text-yellow-400'>
                {product.name}
              </td>
              <td className='text-center px-4 py-2 border border-gray-300 dark:border-gray-600  text-gray-800 dark:text-white'>
                {product.category}
              </td>
              <td className='text-center px-4 py-2 border border-gray-300 dark:border-gray-600  text-gray-800 dark:text-white'>
                {product.quantity}
              </td>
              <td className='text-center px-4 py-2 border border-gray-300 dark:border-gray-600  text-gray-800 dark:text-white'>
                ${product.price}
              </td>
              <td className='text-center px-4 py-2 border border-gray-300 dark:border-gray-600  text-gray-800 dark:text-white'>
                ${(product.price * product.quantity).toFixed(2)}
              </td>
              <td className='text-center px-4 py-2 border border-gray-300 dark:border-gray-600  text-gray-800 dark:text-white'>
                {product.notes || 'لا توجد ملاحظات'}
              </td>
              <td className='text-center px-4 py-2 border border-gray-300 dark:border-gray-600  text-gray-800 dark:text-white'>
                <button
                  onClick={() => handleRemoveProduct(product.slug)}
                  aria-label={`Remove ${product.slug}`}
                >
                  <BsTrash className='text-xl text-red-600' />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OfferProducts;
