// Import necessary modules
import React, { useEffect, useState, useMemo } from 'react';
import useAuth from '../Auth/Authentication/AuthUser.jsx';
import { v4 as uuidv4 } from 'uuid';
import { FaPlus } from 'react-icons/fa';
import PaymentMethod from './Tools/PaymentMethod.jsx';
import OfferProducts from './Tools/OfferProducts.jsx';
import DiscountAndTax from './Tools/DiscountAndTax.jsx';
import CustomerSaleRep from './Tools/CustomerSaleRep.jsx';
import CategoryProducts from './Tools/CategoryProducts.jsx';

const OfferForm = ({ refetchOffers, isOpen, onClose, setSuccessMessage }) => {
  const { http, user } = useAuth();

  const initialOfferState = {
    created_by: user?.id || '',
    customer_id: '',
    sale_rep_id: '',
    products: [],
    tax_amount: 0,
    discount_amount: 0,
    discount_rate: 0,
    tax_rate: 0,
    total_final: 0,
    valid_until: '',
    payment_method: 'cash',
    payment_type: 'full',
    status: 'active',
    time_plementation_range: 0,
  };

  const [offerData, setOfferData] = useState(initialOfferState);
  const [offerDate, setOfferDate] = useState(
    new Date().toISOString().slice(0, 16)
  );
  const [offerNumber, setOfferNumber] = useState('');
  const [categories, setCategories] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [validUntil, setValidUntil] = useState('');
  const [saleReps, setSaleReps] = useState([]);
  const [products, setProducts] = useState([]);
  const [showAddedProductMessage, setShowAddedProductMessage] = useState(false);
  const [addedProductMessage, setAddedProductMessage] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('cash');
  const [productInputs, setProductInputs] = useState({});
  const [paymentType, setPaymentType] = useState('full');
  const [taxRate, setTaxRate] = useState(0);
  const [discountRate, setDiscountRate] = useState(0);

  const [timePlementationRange, setTimePlementationRange] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({
    id: '',
    name: '',
  });
  const [selectedSaleRep, setSelectedSaleRep] = useState({ id: '', name: '' });
  const [dataFetched, setDataFetched] = useState(false);
  const [error, setError] = useState(null);
  const [formErrors, setFormErrors] = useState([]);

  const total = useMemo(
    () =>
      offerData.products.reduce(
        (acc, curr) => acc + curr.quantity * curr.price,
        0
      ),
    [offerData.products]
  );
  const taxAmount = useMemo(() => total * (taxRate / 100), [total, taxRate]);
  const discountAmount = useMemo(
    () => (total + taxAmount) * (discountRate / 100),
    [total, taxAmount, discountRate]
  );
  const totalFinal = useMemo(
    () => total + taxAmount - discountAmount,
    [total, taxAmount, discountAmount]
  );

  // Generate offer number on component mount
  useEffect(() => {
    const randomDigits = Math.random().toString().slice(2, 9);
    setOfferNumber(`HA-00${randomDigits}`);
  }, []);

  useEffect(() => {
    const initialInputs = {};
    filteredProducts.forEach(product => {
      initialInputs[product.id] = { quantity: 1, notes: '' };
    });
    setProductInputs(initialInputs);
  }, [filteredProducts]);
  useEffect(() => {
    async function fetchData() {
      try {
        const [saleRepsData, customersData, productsData] = await Promise.all([
          http.get('/api/sale-reps'),
          http.get('/api/customers'),
          http.get('/api/products'),
        ]);
        setSaleReps(saleRepsData.data);
        setCustomers(customersData.data);
        setProducts(
          productsData.data.map(product => ({
            ...product,
            category: product.category.name,
          }))
        );
        setDataFetched(true);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    }

    if (isOpen && !dataFetched) {
      fetchData();
    }

    if (!isOpen) {
      resetForm();
      setDataFetched(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (products.length > 0) {
      const extractedCategories = Array.from(
        new Set(products.map(product => product.category))
      );
      setCategories(extractedCategories);
    }
  }, [products]);

  useEffect(() => {
    if (selectedCategory !== '') {
      const filtered = products.filter(
        product => product.category === selectedCategory
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts([]);
    }
  }, [selectedCategory, products]);

  const resetForm = () => {
    setOfferData(initialOfferState);
    setOfferDate(new Date().toISOString().slice(0, 16));
    setOfferNumber('');
    setCategories([]);
    setCustomers([]);
    setSaleReps([]);
    setProducts([]);
    setTimePlementationRange('');
    setSelectedCategory('');
    setSelectedCustomer({ id: '', name: '' });
    setSelectedSaleRep({ id: '', name: '' });
    setDataFetched(false);
  };

  const handleCategorySelect = category => {
    setSelectedCategory(category);
  };

  const handlePaymentMethodChange = method => {
    setPaymentMethod(method);
  };

  const handlePaymentTypeChange = type => {
    setPaymentType(type);
  };
  const handleAddToProductList = (product, quantityInput, notesInput) => {
    const quantity = parseInt(quantityInput);
    if (isNaN(quantity) || quantity <= 0) {
      alert('يرجى إدخال كمية صالحة.');
      return;
    }

    if (!selectedCustomer || !selectedSaleRep) {
      alert('الرجاء تحديد العميل ومندوب المبيعات قبل إضافة المنتجات.');
      return;
    }

    const newProduct = {
      ...product,
      slug: uuidv4(),
      productId: product.id,
      quantity: quantity,
      notes: notesInput,
    };

    setOfferData(prevState => ({
      ...prevState,
      products: [...prevState.products, newProduct],
    }));

    setAddedProductMessage(`تمت إضافة ${product.name} إلى السلة.`);
    setShowAddedProductMessage(true);

    const timer = setTimeout(() => {
      setShowAddedProductMessage(false);
    }, 3000);

    // تنظيف المؤقت عند إعادة التنفيذ
    return () => clearTimeout(timer);
  };

  const totalProducts = offerData.products.reduce(
    (sum, product) => sum + product.quantity,
    0
  );

  const handleCustomerSelect = customer => {
    setSelectedCustomer(customer);
    setOfferData(prevState => ({
      ...prevState,
      customer_id: customer.id,
    }));
  };

  const handleSaleRepSelect = saleRep => {
    setSelectedSaleRep(saleRep);
    setOfferData(prevState => ({
      ...prevState,
      sale_rep_id: saleRep.id,
    }));
  };

  const handleRemoveProduct = slug => {
    setOfferData(prevState => ({
      ...prevState,
      products: prevState.products.filter(product => product.slug !== slug),
    }));
  };
  const handleSubmit = async e => {
    e.preventDefault();

    // Check for required fields and set errors
    const errors = [];
    if (!selectedCustomer.id) errors.push('يرجى تحديد العميل.');
    if (!selectedSaleRep.id) errors.push('يرجى تحديد مندوب المبيعات.');
    if (!totalProducts) errors.push('يرجى تحديد المنتجات المطلوبة.');
    if (!validUntil) errors.push('يرجى تحديد تاريخ انتهاء العرض.');

    if (timePlementationRange <= 0) errors.push('يرجى إدخال نطاق تنفيذ العرض.');

    if (errors.length > 0) {
      setFormErrors(errors);
      return;
    }

    if (offerData.processing) return;
    setOfferData(prev => ({ ...prev, processing: true }));
    setSuccessMessage(''); // Clear previous messages
    setFormErrors([]);
    setError(null);

    try {
      const newTotal = offerData.products.reduce(
        (acc, cur) => acc + cur.quantity * cur.price,
        0
      );

      const newTaxAmount = newTotal * (taxRate / 100);
      const newDiscountAmount =
        (newTotal + newTaxAmount) * (discountRate / 100);
      const newTotalFinal = newTotal + newTaxAmount - newDiscountAmount;

      const formattedProducts = offerData.products.map(product => ({
        product_id: product.productId,
        quantity: product.quantity,
        notes: product.notes,
      }));

      const requestData = {
        offer_date: offerDate,
        offer_number: offerNumber,
        created_by: user.id,
        customer_id: selectedCustomer.id,
        sale_rep_id: selectedSaleRep.id,
        products: formattedProducts,
        tax_rate: taxRate,
        discount_rate: discountRate,
        valid_until: validUntil,
        time_plementation_range: timePlementationRange,
        total_final: newTotalFinal,
        total: newTotal,
        tax_amount: newTaxAmount,
        discount_amount: newDiscountAmount,
        payment_method: paymentMethod,
        payment_type: paymentType,
      };

      const response = await http.post('/api/offers', requestData);
      onClose();
      refetchOffers();
      setSuccessMessage('تمت إضافة العرض بنجاح!');
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
      resetForm();
    } catch (error) {
      console.error('Error creating offer:', error);
      setFormErrors(['فشل إرسال العرض. يرجى المحاولة لاحقاً.']);
    } finally {
      setOfferData(prev => ({ ...prev, processing: false }));
    }
  };

  return (
    <div
      className={`fixed inset-0 z-50 overflow-y-auto ${isOpen ? 'bg-gray-900 bg-opacity-50 dark:bg-black dark:bg-opacity-80' : 'hidden'}`}
    >
      <div className=' bg-white dark:bg-gray-800 rounded-lg overflow-hidden w-full max-w-4xl m-auto'>
        {/* Header */}
        <div className='flex justify-between items-center bg-gradient-to-l from-purple-600 to-indigo-600 dark:from-gray-700 dark:to-gray-900 border-3 p-3 rounded-t-xl border-orange-400'>
          <h2 className='text-xl font-bold text-white'>عرض سعر جديد</h2>
          <button onClick={onClose} className='text-white'>
            <FaPlus className='rotate-45 text-2xl' />
          </button>
        </div>
        <DiscountAndTax
          validUntil={validUntil}
          setValidUntil={setValidUntil}
          taxRate={taxRate}
          setTaxRate={setTaxRate}
          discountRate={discountRate}
          setDiscountRate={setDiscountRate}
          timePlementationRange={timePlementationRange}
          setTimePlementationRange={setTimePlementationRange}
          error={error}
        />

        <form onSubmit={handleSubmit} className='space-y-6 p-4'>
          {error && (
            <div className='bg-red-500 text-white p-3 rounded-md mb-4'>
              {error}
            </div>
          )}
          <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
            <div className='mt-2'>
              <h3 className='text-lg font-bold text-center text-orange-400  00 dark:text-yellow-300 mb-2'>
                رقم العرض
              </h3>
              <input
                type='text'
                className='input-offer mt-1 block w-full bg-violet-200 dark:bg-violet-300 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm dark:shadow-md text-center focus:outline-none'
                value={offerNumber}
                readOnly
              />
            </div>
            <div className='mt-2'>
              <h3 className='text-lg font-bold text-center text-orange-400  00 dark:text-yellow-300 mb-2'>
                تاريخ العرض
              </h3>
              <input
                type='datetime-local'
                className='input-offer mt-1 block w-full bg-violet-200 dark:bg-violet-300 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm dark:shadow-md focus:border-indigo-500 dark:focus:border-indigo-300 focus:ring focus:ring-indigo-500 dark:focus:ring-indigo-300 focus:ring-opacity-50'
                value={offerDate}
                onChange={e => setOfferDate(e.target.value)}
                readOnly
              />
            </div>

            <CustomerSaleRep
              customers={customers}
              saleReps={saleReps}
              selectedCustomer={selectedCustomer}
              selectedSaleRep={selectedSaleRep}
              handleCustomerSelect={handleCustomerSelect}
              handleSaleRepSelect={handleSaleRepSelect}
            />
            <CategoryProducts
              categories={categories}
              selectedCategory={selectedCategory}
              handleCategorySelect={handleCategorySelect}
              filteredProducts={filteredProducts}
              productInputs={productInputs}
              setProductInputs={setProductInputs}
              handleAddToProductList={handleAddToProductList}
              showAddedProductMessage={showAddedProductMessage}
              addedProductMessage={addedProductMessage}
            />
          </div>
          <div className='justify-center mb-2'>
            <div className='bg-gradient-to-b from-gray-200 to-gray-100 dark:from-logic-dark-violet dark:to-logic-dark-blue  p-4 rounded-2xl shadow-2xl overflow-hidden text-white'>
              <OfferProducts
                products={offerData.products}
                handleRemoveProduct={handleRemoveProduct}
              />

              <PaymentMethod
                totalFinal={totalFinal}
                paymentMethod={paymentMethod}
                paymentType={paymentType}
                setPaymentMethod={handlePaymentMethodChange}
                setPaymentType={handlePaymentTypeChange}
                total={total}
                discountAmount={discountAmount}
                taxAmount={taxAmount}
                validUntil={validUntil}
              />
            </div>
          </div>
          <div className='flex justify-center bg-red-100'>
            {error && <div className='alert alert-error'>{error}</div>}
            {formErrors.length > 0 && (
              <div className='alert alert-error'>
                {formErrors.map((err, index) => (
                  <p key={index}>{err}</p>
                ))}
              </div>
            )}
          </div>
          <button
            type='submit'
            className='bg-violet-600 hover:bg-violet-900 dark:bg-yellow-500 dark:hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full'
          >
            تأكيد الطلب
          </button>
        </form>
      </div>
    </div>
  );
};

export default OfferForm;
