import React, { useState, useEffect } from 'react';
import { IoMdContact, IoMdCall } from 'react-icons/io';
import { TbScanPosition } from 'react-icons/tb';
import useAuth from '../../Auth/Authentication/AuthUser';
import API_CONFIG from '../../../ApiConfig';
import ModalComponent from '../../Tools/ModalComponent';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const validateImage = file => {
  const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
  const maxSize = 1024 * 1024; // 1MB
  if (!validTypes.includes(file.type)) {
    return 'الرجاء تحميل صورة بصيغة JPG، JPEG، PNG، أو GIF.';
  }
  if (file.size > maxSize) {
    return 'حجم الصورة يجب أن لا يتجاوز 1024 كيلوبايت.';
  }
  return '';
};

const validatePhoneNumber = phone => {
  const phoneNumber = parsePhoneNumberFromString(phone);
  if (!phoneNumber || !phoneNumber.isValid()) {
    return 'رقم الهاتف غير صالح.';
  }
  return '';
};

const validateForm = formData => {
  const errors = {};
  if (!formData.name.trim()) errors.name = 'الاسم مطلوب.';
  if (!formData.phone.trim()) errors.phone = 'رقم الهاتف مطلوب.';
  else {
    const phoneError = validatePhoneNumber(formData.phone);
    if (phoneError) errors.phone = phoneError;
  }
  return errors;
};

const MultiEmployeeModalForm = ({
  isOpen,
  onClose,
  multiEmployee,
  refreshMultiEmployees,
  setSuccessMessage,
}) => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    employee_position: '',
    image: null,
  });
  const [username, setUsername] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [imageFile, setImageFile] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { http } = useAuth();

  useEffect(() => {
    if (isOpen && multiEmployee) {
      setFormData({
        name: multiEmployee.name,
        phone: multiEmployee.phone,
        employee_position: multiEmployee.employee_position,
        image: multiEmployee.image
          ? `${API_CONFIG.baseURL}${multiEmployee.image}`
          : null,
      });
      setUsername(multiEmployee.email.split('@')[0]); // استعادة الجزء الأول من البريد الإلكتروني
    } else {
      setFormData({ name: '', phone: '', employee_position: '', image: null });
      setUsername('');
      setImageFile(null);
    }
    setIsSubmitted(false);
  }, [isOpen, multiEmployee]);

  const handleChange = e => {
    const { name, value } = e.target;
    if (name === 'username') {
      setUsername(value);
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleFileChange = e => {
    const file = e.target.files[0];
    if (file) {
      const error = validateImage(file);
      if (!error) {
        setImageFile(file);
        setFormData(prev => ({ ...prev, image: URL.createObjectURL(file) }));
      } else {
        setValidationErrors(prev => ({ ...prev, image: error }));
      }
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsSubmitted(true);
    const errors = validateForm(formData, imageFile);
    setValidationErrors(errors);
    if (Object.keys(errors).length > 0) return;

    const email = `${username}@hadathah.org`; // دمج الجزء الأول مع النطاق الثابت

    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('phone', formData.phone);
    formDataToSend.append('employee_position', formData.employee_position);
    formDataToSend.append('email', email); // إضافة البريد الإلكتروني

    if (imageFile) formDataToSend.append('image', imageFile);

    try {
      const endpoint =
        multiEmployee && multiEmployee.id
          ? `/api/multi-employees/${multiEmployee.id}`
          : '/api/multi-employees';
      const method = multiEmployee && multiEmployee.id ? 'PATCH' : 'POST';
      formDataToSend.append('_method', method);

      await http.post(endpoint, formDataToSend);
      onClose();
      refreshMultiEmployees();
      setSuccessMessage(
        multiEmployee ? 'تم التحديث بنجاح!' : 'تمت الإضافة بنجاح!'
      );
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (error) {
      console.error('Error saving multi Employee:', error);
      setValidationErrors(error.response.data.errors || {});
    }
  };

  const modalContent = (
    <form onSubmit={handleSubmit} className='mt-4'>
      <div className='mb-4'>
        <label
          htmlFor='name'
          className='block text-gray-500 dark:text-gray-400 font-bold mb-2'
        >
          اسم الموظف
        </label>
        <div className='flex items-center border-b border-gray-300 dark:border-gray-600 py-2'>
          <IoMdContact className='text-lg text-gray-500 dark:text-violet-400 ml-2' />
          <input
            type='text'
            name='name'
            value={formData.name}
            onChange={handleChange}
            placeholder='الأسم'
            className='w-full bg-transparent p-2 text-gray-700 dark:text-gray-300 border-none text-right'
            required
          />
          {validationErrors.name && (
            <div className='text-red-500 font-bold'>
              {validationErrors.name}
            </div>
          )}
        </div>
      </div>

      <div className='mb-4'>
        <label
          htmlFor='phone'
          className='block text-gray-500 dark:text-gray-400 font-bold mb-2'
        >
          رقم الهاتف
        </label>
        <div className='flex items-center border-b border-gray-300 dark:border-gray-600 py-2'>
          <IoMdCall className='text-lg text-gray-500 dark:text-violet-400 ml-2' />
          <input
            type='tel'
            name='phone'
            value={formData.phone}
            onChange={handleChange}
            placeholder='+XXXXXXXXX'
            className='w-full bg-transparent p-2 text-gray-700 dark:text-gray-300 border-none text-right'
            required
          />
          {validationErrors.phone && (
            <div className='text-red-500 font-bold'>
              {validationErrors.phone}
            </div>
          )}
        </div>
      </div>

      <div className='mb-4'>
        <label
          htmlFor='employee_position'
          className='block text-gray-500 dark:text-gray-400 font-bold mb-2'
        >
          المنصب
        </label>
        <div className='flex items-center border-b border-gray-300 dark:border-gray-600 py-2'>
          <TbScanPosition className='text-lg text-gray-500 dark:text-violet-400 ml-2' />
          <input
            type='text'
            name='employee_position'
            value={formData.employee_position}
            onChange={handleChange}
            placeholder='المنصب'
            className='w-full bg-transparent p-2 text-gray-700 dark:text-gray-300 border-none text-right'
            required
          />
          {validationErrors.employee_position && (
            <div className='text-red-500 font-bold'>
              {validationErrors.employee_position}
            </div>
          )}
        </div>
      </div>

      <div className='mb-4'>
        <label
          htmlFor='email'
          className='block text-gray-500 dark:text-gray-400 font-bold mb-2'
        >
          البريد الإلكتروني
        </label>
        <div className='flex items-center border-b border-gray-300 dark:border-gray-600 py-2'>
    
        <span className='ml-2'>hadathah.org@</span>
              <input
            type='text'
            name='username'
            value={username}
            onChange={handleChange}
            placeholder='اسم المستخدم'
            className='w-full bg-transparent p-2 text-gray-700 dark:text-gray-300 border-none text-right'
            required
          />
          {validationErrors.email && (
            <div className='text-red-500 font-bold'>
              {validationErrors.email}
            </div>
          )}
        </div>
      </div>

      <div className='mb-4'>
        <label
          htmlFor='image'
          className='block text-gray-500 dark:text-gray-400 font-bold mb-2'
        >
          الصورة
        </label>
        <input
          type='file'
          name='image'
          onChange={handleFileChange}
          className='appearance-none bg-transparent border-none w-full text-gray-700 dark:text-gray-300 p-2 leading-tight focus:outline-none'
        />
        {formData.image && (
          <div className='mt-2'>
            <img
              src={formData.image}
              alt='Multi Employee Image'
              className='w-32 h-32 object-cover rounded-full mx-auto'
            />
          </div>
        )}
        {validationErrors.image && (
          <p className='text-red-500 text-xs italic'>
            {validationErrors.image}
          </p>
        )}
      </div>

      <div className='flex justify-around mt-2'>
        <button
          className='bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700'
          onClick={onClose}
        >
          إلغاء
        </button>
        <button
          type='submit'
          className='bg-violet-500 hover:bg-violet-700 text-white font-bold px-4 py-2 rounded focus:outline-none focus:shadow-outline'
        >
          {multiEmployee ? 'تحديث' : 'إضافة'}
        </button>
      </div>
    </form>
  );

  return (
    <ModalComponent
      titleModal={multiEmployee ? 'تحديث موظف' : 'إضافة موظف'}
      onClose={onClose}
      ContentModal={modalContent}
      isOpen={isOpen}
    />
  );
};

export default MultiEmployeeModalForm;
