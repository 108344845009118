import React, { useState, useEffect } from 'react';
import { IoMdContact, IoMdCall } from 'react-icons/io';
import useAuth from '../Auth/Authentication/AuthUser';
import API_CONFIG from '../../ApiConfig';
import ModalComponent from '../Tools/ModalComponent';

const positionTranslations = {
  designer: 'مصمم',
  social_reps: 'مندوب تسويق',
  sale_reps: 'مندوب مبيعات',
  multi_employees: 'موظف إدارى',
};

const roleTranslations = {
  1: 'مدير',
  2: 'موظف',
};

const translatePositionToEnglish = arabicValue => {
  return Object.keys(positionTranslations).find(
    key => positionTranslations[key] === arabicValue
  );
};

const translateRoleToEnglish = arabicValue => {
  return Object.keys(roleTranslations).find(
    key => roleTranslations[key] === arabicValue
  );
};

const UserModalForm = ({ isOpen, onClose, selectedUser, refreshUsers }) => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    skills: '',
    employee_position: '',
    covered_areas: '',
    position: '',
    role: '',
    emailPrefix: '',
    image: null,
  });

  const [validationErrors, setValidationErrors] = useState({});
  const [imageFile, setImageFile] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { http } = useAuth();

  useEffect(() => {
    if (isOpen) {
      if (selectedUser) {
        const [emailPrefix] = selectedUser.email.split('@');
        setFormData({
          name: selectedUser.name,
          phone: selectedUser.phone,
          emailPrefix,
          skills: selectedUser.skills,
          covered_areas: selectedUser.covered_areas || '',
          position: selectedUser.position || '',
          role: selectedUser.role || '',
          employee_position: selectedUser.employee_position || '',
          image: selectedUser.image
            ? `${API_CONFIG.baseURL}${selectedUser.image}`
            : null,
        });
      } else {
        setFormData({
          name: '',
          phone: '',
          skills: '',
          employee_position: '',
          covered_areas: '',
          position: '',
          role: '',
          emailPrefix: '',
          image: null,
        });
        setImageFile(null);
      }
    }
    setIsSubmitted(false);
  }, [isOpen, selectedUser]);

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  }

  function handleFileChange(e) {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setFormData(prevState => ({
        ...prevState,
        image: URL.createObjectURL(file),
      }));
    }
  }

  const handleSubmit = async e => {
    e.preventDefault();
    setIsSubmitted(true);

    if (
      !formData.name ||
      !formData.phone ||
      !formData.position ||
      !formData.role ||
      !formData.emailPrefix
    ) {
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('phone', formData.phone);
    formDataToSend.append('skills', formData.skills);
    formDataToSend.append('employee_position', formData.employee_position);
    formDataToSend.append('covered_areas', formData.covered_areas);
    formDataToSend.append(
      'position',
      translatePositionToEnglish(formData.position)
    );
    formDataToSend.append('role', translateRoleToEnglish(formData.role));
    formDataToSend.append('email', `${formData.emailPrefix}@hadathah.org`);

    if (imageFile) {
      formDataToSend.append('image', imageFile);
    }

    try {
      const endpoint =
        selectedUser && selectedUser.id
          ? `/api/employee-users/${selectedUser.id}`
          : '/api/employee-users';
      const method = selectedUser && selectedUser.id ? 'PATCH' : 'POST';

      await http.post(endpoint, formDataToSend);
      onClose();
      refreshUsers();
    } catch (error) {
      console.error('Error saving user:', error);
      setValidationErrors(error.response.data.errors || {});
    }
  };

  const modalContent = (
    <form onSubmit={handleSubmit} className='mt-4 space-y-4'>
      <div className='space-y-2'>
        <label className='text-violet-800 dark:text-violet-500 text-sm font-bold'>
          إسم الموظف
        </label>
        <div className='flex items-center border-b border-gray-300 dark:border-gray-600 py-2'>
          <IoMdContact className='text-lg text-gray-800 dark:text-violet-600 ml-2' />
          <input
            type='text'
            name='name'
            value={formData.name}
            onChange={handleChange}
            placeholder='اسم الموظف'
            className='w-full bg-transparent bg-gray-200 dark:bg-gray-800 p-2 text-gray-800 dark:text-gray-200 font-bold border-none text-right'
            required
            readOnly
          />
        </div>
        {isSubmitted && !formData.name && (
          <div className='text-red-500 font-bold'>يرجى إدخال اسم الموظف</div>
        )}
      </div>

      <div className='space-y-2'>
        <label className='text-violet-800 dark:text-violet-500 text-sm font-bold'>
          رقم الهاتف
        </label>
        <div className='flex items-center border-b border-gray-300 dark:border-gray-600 py-2'>
          <IoMdCall className='text-lg text-gray-800 dark:text-violet-600 ml-2' />
          <input
            type='text'
            name='phone'
            value={formData.phone}
            onChange={handleChange}
            placeholder='رقم الهاتف'
            className='w-full bg-transparent bg-gray-200 dark:bg-gray-800 p-2 text-gray-800 dark:text-gray-200 font-bold border-none text-right'
            required
            readOnly
          />
        </div>
        {isSubmitted && !formData.phone && (
          <div className='text-red-500 font-bold'>يرجى إدخال رقم الهاتف</div>
        )}
      </div>

      <div className='space-y-2'>
        <label className='text-violet-800 dark:text-violet-500 text-sm font-bold'>
          المنصب
        </label>
        <div className='flex items-center border-b border-gray-300 dark:border-gray-600 py-2'>
          <select
            name='position'
            value={formData.position}
            onChange={handleChange}
            className='w-full bg-transparent bg-gray-200 dark:bg-gray-800 p-2 text-gray-800 dark:text-gray-200 font-bold border-none text-right'
            required
            disabled
          >
            <option value=''>اختر المنصب</option>
            <option value='مصمم'>مصمم</option>
            <option value='مندوب تسويق'>مندوب تسويق</option>
            <option value='مندوب مبيعات'>مندوب مبيعات</option>
            <option value='موظف إدارى'>موظف إدارى</option>
          </select>
        </div>
        {isSubmitted && !formData.position && (
          <div className='text-red-500 font-bold'>يرجى اختيار المنصب</div>
        )}
      </div>

      <div className='space-y-2'>
        <label className='text-violet-800 dark:text-violet-500 text-sm font-bold'>
          الدور
        </label>
        <div className='flex items-center border-b border-gray-300 dark:border-gray-600 py-2'>
          <select
            name='role'
            value={formData.role}
            onChange={handleChange}
            className='w-full bg-transparent bg-gray-200 dark:bg-gray-800 p-2 text-gray-800 dark:text-gray-200 font-bold border-none text-right'
            required
          >
            <option value=''>اختر الدور</option>
            <option value='مدير'>مدير</option>
            <option value='موظف'>موظف</option>
          </select>
        </div>
        {isSubmitted && !formData.role && (
          <div className='text-red-500 font-bold'>يرجى اختيار الدور</div>
        )}
      </div>

      {formData.position === 'موظف إدارى' && (
        <div className='space-y-2'>
          <label className='text-violet-800 dark:text-violet-500 text-sm font-bold'>
            الوظيفة
          </label>
          <textarea
            className='w-full bg-transparent bg-gray-200 dark:bg-gray-800 p-2 text-gray-800 dark:text-gray-200 font-bold border-none text-right'
            name='employee_position'
            value={formData.employee_position}
            onChange={handleChange}
            placeholder='الوظيفة'
            required
            readOnly
          />
        </div>
      )}

      <div className='space-y-2'>
        <label className='text-violet-800 dark:text-violet-500 text-sm font-bold'>
          البريد الإلكتروني
        </label>
        <div className='flex items-center border-b border-gray-300 dark:border-gray-600 py-2'>
          <input
            type='text'
            name='emailPrefix'
            value={formData.emailPrefix}
            onChange={handleChange}
            placeholder='البريد الإلكتروني'
            className='w-full bg-transparent bg-gray-200 dark:bg-gray-800 p-2 text-gray-800 dark:text-gray-200 font-bold border-none text-right'
            required
          />
          <span className='text-gray-800 dark:text-violet-600 ml-2'>
            @hadathah.org
          </span>
        </div>
        {isSubmitted && !formData.emailPrefix && (
          <div className='text-red-500 font-bold'>
            يرجى إدخال البريد الإلكتروني
          </div>
        )}
      </div>

      <div className='mb-4'>
        <label
          htmlFor='image'
          className='block text-gray-800 dark:text-gray-200 font-bold mb-2'
        >
          الصورة
        </label>
        <input
          type='file'
          name='image'
          onChange={handleFileChange}
          className='appearance-none bg-transparent border-none w-full text-gray-800 dark:text-gray-200 p-2 leading-tight focus:outline-none'
        />
        {formData.image && (
          <div>
            <img
              src={formData.image}
              alt='Profile Image'
              className='w-32 h-32 object-cover mt-2'
            />
          </div>
        )}
      </div>

      <div className='flex justify-around mt-4'>
        <button
          type='button'
          className='bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50'
          onClick={onClose}
        >
          إلغاء
        </button>
        <button
          type='submit'
          className='bg-violet-500 hover:bg-violet-700 text-white font-bold px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-opacity-50'
        >
          {selectedUser ? 'تحديث' : 'إضافة'}
        </button>
      </div>
    </form>
  );

  return (
    <ModalComponent
      titleModal={selectedUser ? 'تعديل المستخدم' : 'إضافة مستخدم'}
      onClose={onClose}
      ContentModal={modalContent}
      isOpen={isOpen}
    />
  );
};

export default UserModalForm;
