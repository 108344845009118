import plugin from 'tailwindcss/plugin';
import forms from '@tailwindcss/forms';
import cssVariablesPlugin from 'postcss-css-variables';
import rtl from 'tailwindcss-rtl';
import typography from '@tailwindcss/typography'; // إضافة تحسينات النصوص
import aspectRatio from '@tailwindcss/aspect-ratio'; // إضافة دعم لنسبة الأبعاد
import lineClamp from '@tailwindcss/line-clamp'; // إضافة دعم لتقليص الأسطر

export default {
  mode: 'jit', // تفعيل الوضع الفوري لتحسين الأداء
  content: [
    './src/**/*.{js,jsx,ts,tsx,vue}', // تحديث المسارات لتشمل المزيد من أنواع الملفات
    './public/index.html',
    './node_modules/flowbite/**/*.js',
    './node_modules/@headlessui/react/**/*.js', // دعم مكتبة Headless UI
  ],

  darkMode: 'class', // تفعيل الوضع الليلي

  theme: {
    extend: {
      colors: {
        gray: {
          50: '#F9FAFB',
          100: '#F3F4F6',
          200: '#E5E7EB',
          300: '#BFC4CD',
          400: '#9CA3AF',
          500: '#6B7280',
          600: '#4B5563',
          700: '#374151',
          800: '#1F2937',
          900: '#111827',
          950: '#030712',
        },
        violet: {
          50: '#F1EEFF',
          100: '#E6E1FF',
          200: '#D2CBFF',
          300: '#B7ACFF',
          400: '#9C8CFF',
          500: '#8470FF',
          600: '#755FF8',
          700: '#5D47DE',
          800: '#4634B1',
          900: '#2F227C',
          950: '#1C1357',
        },
        // ألوان جديدة مخصصة حسب الحاجة
        primary: '#4F46E5', 
        secondary: '#64748B',
        accent: '#D97706',
      },
      fontFamily: {
        inter: ['Inter', 'sans-serif'],
        sans: ['ui-sans-serif', 'system-ui'],
        serif: ['ui-serif', 'Georgia'],
      },
      fontSize: {
        xs: ['0.75rem', { lineHeight: '1.5' }],
        sm: ['0.875rem', { lineHeight: '1.5715' }],
        base: ['1rem', { lineHeight: '1.5', letterSpacing: '-0.01em' }],
        lg: ['1.125rem', { lineHeight: '1.5', letterSpacing: '-0.01em' }],
        xl: ['1.25rem', { lineHeight: '1.5', letterSpacing: '-0.01em' }],
        '2xl': ['1.5rem', { lineHeight: '1.33', letterSpacing: '-0.01em' }],
        '3xl': ['1.88rem', { lineHeight: '1.33', letterSpacing: '-0.01em' }],
        '4xl': ['2.25rem', { lineHeight: '1.25', letterSpacing: '-0.02em' }],
        '5xl': ['3rem', { lineHeight: '1.25', letterSpacing: '-0.02em' }],
        '6xl': ['3.75rem', { lineHeight: '1.2', letterSpacing: '-0.02em' }],
      },
      screens: {
        xs: '480px', // شاشات صغيرة جدًا مثل الهواتف الصغيرة
        sm: '640px', // الأجهزة المحمولة
        md: '768px', // الأجهزة اللوحية
        lg: '1024px', // شاشات سطح المكتب الصغيرة
        xl: '1280px', // شاشات سطح المكتب الكبيرة
        '2xl': '1536px', // شاشات أكبر
      },
      borderWidth: {
        3: '3px',
      },
      minWidth: {
        36: '9rem',
        44: '11rem',
        56: '14rem',
        60: '15rem',
        72: '18rem',
        80: '20rem',
      },
      maxWidth: {
        '8xl': '88rem',
        '9xl': '96rem',
      },
      zIndex: {
        60: '60',
      },
    },
  },
  variants: {
    extend: {},
  },
  plugins: [
    cssVariablesPlugin(),
    forms, // إدراج تحسينات النماذج
    rtl,   // دعم الاتجاه من اليمين لليسار
    typography, // تحسين النصوص
    aspectRatio, // دعم لنسب الأبعاد
    plugin(({ addVariant, e }) => {
      addVariant('sidebar-expanded', ({ modifySelectors, separator }) => {
        modifySelectors(({ className }) => `.sidebar-expanded .${e(`sidebar-expanded${separator}${className}`)}`);
      });
    }),
  ],
};
