import React from 'react';
import { Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import fontBold from '../../../../assets/fonts/static/Cairo-Bold.ttf'; // Bold font file

Font.register({
  family: 'Cairo-Bold',
  format: 'truetype',
  src: fontBold,
});
const styles = StyleSheet.create({
  textBold: {
    fontSize: 12,
    fontFamily: 'Cairo-Bold',
    marginTop: 35,
  },
  textBg: {
    backgroundColor: '#FAE4CF',

    marginTop: 35,
  },
  customerSection: {
    marginBottom: 15,
    left: '70%',
  },
});

const BillTo = ({ customerName, saleRepName }) => (
  <View style={styles.customerSection}>
    <Text>
      <Text style={styles.textBold}>السادة / </Text>
      <Text style={styles.textBg}>{customerName}</Text>
      <Text style={styles.textBold}> المحترمين</Text>
    </Text>
    <Text>
      <Text style={styles.textBold}>عناية / </Text>
      <Text style={styles.textBg}>{customerName}</Text>
      <Text style={styles.textBold}> المحترم</Text>
    </Text>
  </View>
);

export default BillTo;
