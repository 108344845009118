import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  PDFDownloadLink,
  Image,
} from '@react-pdf/renderer';
import { FaDownload } from 'react-icons/fa';
import fontRegular from '../../../assets/fonts/static/Cairo-Regular.ttf'; // Regular font file
import fontBold from '../../../assets/fonts/static/Cairo-Bold.ttf'; // Bold font file
import { PdfBackgroundPink } from '../../../assets/img/index'; // Background image file
import OfferItemsTable from './Tools/OfferItemsTable';
import OfferNo from './Tools/OfferNo';
import BillTo from './Tools/BillTo';
import OfferThankYouMsg from './Tools/OfferThankYouMsg';

// Register the regular font

Font.register({
  family: 'Cairo',
  format: 'truetype',
  src: fontRegular,
});
Font.register({
  family: 'Cairo-Bold',
  format: 'truetype',
  src: fontBold,
});

const styles = StyleSheet.create({
  body: {
    backgroundImage: `url(${PdfBackgroundPink})`,
    margin: 0,

    position: 'relative',
    fontFamily: 'Cairo',
    fontSize: 12,
    color: '#942C6B',
  },
  bgImageContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
  },
  bgImage: {
    width: '100%',
    height: '100%',
  },
  content: {
    position: 'relative',
    padding: 40,
  },

  table: {
    marginTop: 20,
  },
});

const OfferPricePDF = ({
  offerNumber,
  offerDate,
  saleRepName,
  customerName,
  total,
  totalFinal,
  products,
  productDetails,
  validUntil,
  taxAmount,
  paymentType,
  paymentMethod,
  discountAmount,
}) => {
  const MyDocument = () => (
    <Document>
      <Page size='A4' style={styles.body}>
        {/* <View style={styles.bgImageContainer}>
          <Image style={styles.bgImage} src={PdfBackgroundPink} />
        </View>
        <View style={styles.content}>
          <OfferNo offerNumber={offerNumber} offerDate={offerDate} />
          <BillTo customerName={customerName} saleRepName={saleRepName} />
          <OfferThankYouMsg />
        </View>
        <OfferItemsTable products={products} productDetails={productDetails} /> */}
      </Page>
    </Document>
  );

  return (
    <div className='botao'>
      <PDFDownloadLink document={<MyDocument />} fileName='offer.pdf'>
        {({ blob, url, loading, error }) =>
          loading ? (
            'Loading document...'
          ) : (
            <>
              <span className='texto'>تحميل PDF</span>
              <FaDownload className='mysvg' />
            </>
          )
        }
      </PDFDownloadLink>
    </div>
  );
};

export default OfferPricePDF;
