import React, { useState, useEffect } from 'react';
import { IoMdContact, IoMdCall } from 'react-icons/io';
import useAuth from '../../Auth/Authentication/AuthUser';
import API_CONFIG from '../../../ApiConfig';
import ModalComponent from '../../Tools/ModalComponent';
import {
  validateImage,
  validatePhoneNumber,
  validateForm,
} from '../../Tools/Validation'; // استيراد الوظائف

const SaleRepModalForm = ({
  isOpen,
  onClose,
  saleRep,
  setSuccessMessage,
  refreshData,
}) => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    covered_areas: '',
    image: null,
  });

  const [imageFile, setImageFile] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const { http } = useAuth();

  useEffect(() => {
    if (isOpen && saleRep) {
      setFormData({
        name: saleRep.name,
        phone: saleRep.phone,
        covered_areas: saleRep.covered_areas,
        image: saleRep.image ? `${API_CONFIG.baseURL}${saleRep.image}` : null,
      });
      setValidationErrors({});
    } else {
      setFormData({ name: '', phone: '', covered_areas: '', image: null });
      setImageFile(null);
      setValidationErrors({});
    }
  }, [isOpen, saleRep]);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleFileChange = e => {
    const file = e.target.files[0];
    if (file) {
      const imageErrors = validateImage(file);
      if (!imageErrors) {
        setImageFile(file);
        setFormData(prevState => ({
          ...prevState,
          image: URL.createObjectURL(file),
        }));
        setValidationErrors(prevErrors => {
          const { image, ...rest } = prevErrors;
          return rest;
        });
      } else {
        setValidationErrors(prevErrors => ({
          ...prevErrors,
          image: [imageErrors],
        }));
      }
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.name) {
      errors.name = ['الاسم مطلوب.'];
    }

    if (!formData.covered_areas) {
      errors.covered_areas = ['مناطق التغطية مطلوبة.'];
    }

    if (!formData.phone) {
      errors.phone = ['رقم الهاتف مطلوب.'];
    } else {
      const phoneError = validatePhoneNumber(formData.phone);
      if (phoneError) errors.phone = [phoneError];
    }

    if (imageFile && validateImage(imageFile)) {
      errors.image = ['الصورة غير صالحة.'];
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('phone', formData.phone);
    formDataToSend.append('covered_areas', formData.covered_areas);

    if (imageFile) {
      formDataToSend.append('image', imageFile);
    }

    try {
      const endpoint =
        saleRep && saleRep.id
          ? `/api/sale-reps/${saleRep.id}`
          : '/api/sale-reps';
      const method = saleRep && saleRep.id ? 'PATCH' : 'POST';
      formDataToSend.append('_method', method);

      await http.post(endpoint, formDataToSend);
      onClose();
      refreshData();
      setSuccessMessage(saleRep ? 'تم التحديث بنجاح!' : 'تمت الإضافة بنجاح!');
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    } catch (error) {
      setValidationErrors(error.response.data.errors || {});
    }
  };

  const modalContent = (
    <form onSubmit={handleSubmit} className='mt-4'>
      {Object.keys(validationErrors).length > 0 && (
        <div className='mt-4'>
          <h3 className='text-red-500 text-md font-bold'>أخطاء:</h3>
          <ul>
            {Object.entries(validationErrors).map(([field, errors]) =>
              errors.map(error => (
                <li key={error} className='text-red-500 text-xs italic'>
                  {error}
                </li>
              ))
            )}
          </ul>
        </div>
      )}
      <div className='flex items-center border-b border-gray-300 py-2'>
        <IoMdContact className='text-lg text-gray-800 dark:text-violet-600 ml-2' />
        <input
          type='text'
          name='name'
          value={formData.name}
          onChange={handleChange}
          placeholder='اسم المندوب'
          className={`w-full bg-transparent bg-white p-2 text-gray-900 font-bold border-none text-right ${saleRep ? 'bg-gray-200' : ''}`}
          readOnly={!!saleRep}
        />
      </div>
      {validationErrors.name && (
        <p className='text-red-500 text-xs italic'>
          {validationErrors.name[0]}
        </p>
      )}

      <div className='flex items-center border-b border-gray-300 py-2'>
        <IoMdCall className='text-lg text-gray-800 dark:text-violet-600 ml-2' />
        <input
          type='text'
          name='phone'
          value={formData.phone}
          onChange={handleChange}
          placeholder='رقم الهاتف'
          className='w-full bg-transparent bg-white p-2 text-gray-900 font-bold border-none text-right'
        />
      </div>
      {validationErrors.phone && (
        <p className='text-red-500 text-xs italic'>
          {validationErrors.phone[0]}
        </p>
      )}

      <div className='flex items-center border-b border-gray-300 py-2'>
        <textarea
          className='w-full bg-transparent bg-white p-2 text-gray-900 font-bold border-none text-right'
          name='covered_areas'
          value={formData.covered_areas}
          onChange={handleChange}
          placeholder='مناطق التغطية'
        />
      </div>
      {validationErrors.covered_areas && (
        <p className='text-red-500 text-xs italic'>
          {validationErrors.covered_areas[0]}
        </p>
      )}

      <div className='mb-4'>
        <label
          htmlFor='image'
          className='block text-gray-700 dark:text-violet-700 font-bold mb-2'
        >
          الصورة
        </label>
        <input
          type='file'
          name='image'
          onChange={handleFileChange}
          className='appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none'
        />
        {formData.image && (
          <div>
            <img
              src={formData.image}
              alt='Sale Rep Image'
              className='w-32 h-32 object-cover'
            />
          </div>
        )}
        {validationErrors.image && (
          <p className='text-red-500 text-xs italic'>
            {validationErrors.image[0]}
          </p>
        )}
      </div>
      <div className='flex justify-around mt-2'>
        <button
          className='bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700'
          onClick={onClose}
        >
          إلغاء
        </button>

        <button
          type='submit'
          className='bg-violet-500 hover:bg-violet-700 text-white font-bold px-4 py-2 rounded focus:outline-none focus:shadow-outline'
        >
          {saleRep ? 'تحديث' : 'إضافة'}
        </button>
      </div>
    </form>
  );

  return (
    <ModalComponent
      titleModal={saleRep ? 'تحديث مندوب المبيعات' : 'إضافة مندوب المبيعات'}
      onClose={onClose}
      ContentModal={modalContent}
      isOpen={isOpen}
    />
  );
};

export default SaleRepModalForm;
