import React, { useState } from 'react';
import { animated, useSpring } from '@react-spring/web';
import { MdEdit } from 'react-icons/md';
import { FaTrashAlt } from 'react-icons/fa';
import API_CONFIG from '../../../ApiConfig';

const ProductItem = ({ product, onEdit, onDelete }) => {
  const [isHovered, setIsHovered] = useState(false);

  // Improved animation for the item scale
  const hoverStyle = useSpring({
    transform: isHovered ? 'scale(1.1)' : 'scale(1)',
    config: { mass: 1, tension: 210, friction: 20 },
  });

  // Animation for showing buttons at the top of the item
  const buttonsAnimation = useSpring({
    to: {
      opacity: isHovered ? 1 : 0,
      transform: isHovered ? 'translateY(0)' : 'translateY(-20px)',
    },
    from: {
      opacity: 0,
      transform: 'translateY(-20px)',
    },
    config: { tension: 300, friction: 15 },
  });

  return (
    <animated.div
      className='relative p-4 min-w-1/4 rounded-lg shadow-lg bg-blue-100 dark:bg-blue-900 cursor-pointer m-4'
      style={hoverStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className='absolute top-2 left-2 flex space-x-2'>
        <animated.div
          style={buttonsAnimation}
          className='flex flex-col space-y-2'
        >
          <button
            onClick={e => {
              e.stopPropagation();
              onEdit(product);
            }}
            className='p-2 bg-white border border-green-500 text-green-500 rounded-full hover:bg-green-100 transition ease-in-out duration-200'
            aria-label='Edit product'
          >
            <MdEdit className='text-xl' />
          </button>
        </animated.div>
      </div>

      <div className='absolute top-2 right-2 flex space-x-2'>
        <animated.div
          style={buttonsAnimation}
          className='flex flex-col space-y-2'
        >
          <button
            onClick={e => {
              e.stopPropagation();
              onDelete(product.id);
            }}
            className='p-2 bg-white border border-red-500 text-red-500 rounded-full hover:bg-red-100 transition ease-in-out duration-200'
            aria-label='Delete product'
          >
            <FaTrashAlt className='text-xl' />
          </button>
        </animated.div>
      </div>

      <div className='flex flex-col items-center'>
        <img
          src={`${API_CONFIG.baseURL}${product.image}`}
          alt={product.name}
          className='w-24 h-24 rounded-full mb-2'
        />
        <h3 className='text-md font-bold text-green-700 dark:text-orange-300'>
          {product.name}
        </h3>
        <h4 className='text-sm text-violet-600 dark:text-green-200'>
          {product.category?.name}
        </h4>
        <p className='text-xs dark:text-gray-300 mb-1'>
          الوصف: {product.description}
        </p>
        <p className='text-xs font-semibold dark:text-gray-200'>
          السعر: {product.price}
        </p>
      </div>
    </animated.div>
  );
};

export default ProductItem;
