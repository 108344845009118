import React, { useState, useCallback } from 'react';
import { Link, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import DesignerComponent from './Designers/index';
import SaleRepComponent from './SalesReps/index';
import SocialRepComponent from './SocialReps';
import MultiEmployeesComponent from './MultiEmployees';
import SectionHeader from '../Tools/SectionHeader';
import {
  IconEmployees,
  IconDesigners,
  IconSaleReps,
  IconSocialReps,
} from '../../assets/img/icons/index';
import useAuth from '../Auth/Authentication/AuthUser';

const EmployeeManager = () => {
  const [designersData, setDesignersData] = useState([]);
  const [socialRepsData, setSocialRepsData] = useState([]);
  const [saleRepsData, setSaleRepsData] = useState([]);
  const [multiEmployeesData, setMultiEmployeesData] = useState([]);
  const { http } = useAuth();
  const location = useLocation();

  const fetchData = useCallback(async () => {
    try {
      const response = await http.get('/api/employee-users');
      const { employeeUsers } = response.data;

      const designersData = employeeUsers.filter(
        user => user.position === 'مصمم'
      );
      const socialRepsData = employeeUsers.filter(
        user => user.position === 'مندوب تسويق'
      );
      const saleRepsData = employeeUsers.filter(
        user => user.position === 'مندوب مبيعات'
      );
      const multiEmployeesData = employeeUsers.filter(
        user => user.position === 'موظف إدارى'
      );

      setDesignersData(designersData);
      setSocialRepsData(socialRepsData);
      setSaleRepsData(saleRepsData);
      setMultiEmployeesData(multiEmployeesData);

      console.log('Employees data fetched successfully.');
    } catch (error) {
      console.error('Failed to fetch employees data:', error);
    }
  }, []);

  const refreshData = () => {
    fetchData();
  };

  const getSectionImage = () => {
    switch (location.pathname) {
      case '/employees/designers':
        return IconDesigners;
      case '/employees/social-reps':
        return IconSocialReps;
      case '/employees/sale-reps':
        return IconSaleReps;
      default:
        return IconEmployees;
    }
  };
  const getSectionTitle = () => {
    switch (location.pathname) {
      case '/employees/designers':
        return 'مصممين';
      case '/employees/social-reps':
        return 'مندوبي تسويق';
      case '/employees/sale-reps':
        return 'مندوبي مبيعات';
      default:
        return 'موظفين';
    }
  };

  return (
    <div className='container mx-auto h-full px-4'>
      <SectionHeader
        sectionTitle={getSectionTitle()}
        imageSrc={getSectionImage()}
      />

      <div className='mt-8'>
        <Routes>
          <Route
            path='/'
            element={<Navigate to='/employees/multi-employees' replace />}
          />
          <Route
            path='/multi-employees'
            element={
              <MultiEmployeesComponent
                refreshData={refreshData}
                multiEmployees={multiEmployeesData}
              />
            }
          />
          <Route
            path='/designers'
            element={
              <DesignerComponent
                refreshData={refreshData}
                designers={designersData}
              />
            }
          />
          <Route
            path='/social-reps'
            element={
              <SocialRepComponent
                refreshData={refreshData}
                socialReps={socialRepsData}
              />
            }
          />
          <Route
            path='/sale-reps'
            element={
              <SaleRepComponent
                refreshData={refreshData}
                saleReps={saleRepsData}
              />
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export default EmployeeManager;
