import React from 'react';
import { Link } from 'react-router-dom';
import LineChart from '../charts/LineChart01';
import { chartAreaGradient } from '../charts/ChartjsConfig';
import EditMenu from '../../../Tools/DropdownEditMenu';

// Import utilities
import { tailwindConfig, hexToRGB } from '../../../../utils/Utils';

function DashboardCard03() {
  const chartData = {
    labels: [
      '12-01-2022',
      '01-01-2023',
      '02-01-2023',
      '03-01-2023',
      '04-01-2023',
      '05-01-2023',
      '06-01-2023',
      '07-01-2023',
      '08-01-2023',
      '09-01-2023',
      '10-01-2023',
      '11-01-2023',
      '12-01-2023',
      '01-01-2024',
      '02-01-2024',
      '03-01-2024',
      '04-01-2024',
      '05-01-2024',
      '06-01-2024',
      '07-01-2024',
      '08-01-2024',
      '09-01-2024',
      '10-01-2024',
      '11-01-2024',
      '12-01-2024',
      '01-01-2025',
    ],
    datasets: [
      // الخط البنفسجي
      {
        data: [
          540, 466, 540, 466, 385, 432, 334, 334, 289, 289, 200, 289, 222, 289,
          289, 403, 554, 304, 289, 270, 134, 270, 829, 344, 388, 364,
        ],
        fill: true,
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          return chartAreaGradient(ctx, chartArea, [
            {
              stop: 0,
              color: `rgba(${hexToRGB(tailwindConfig().theme.colors.violet[500])}, 0)`,
            },
            {
              stop: 1,
              color: `rgba(${hexToRGB(tailwindConfig().theme.colors.violet[500])}, 0.2)`,
            },
          ]);
        },
        borderColor: tailwindConfig().theme.colors.violet[500],
        borderWidth: 2,
        pointRadius: 0,
        pointHoverRadius: 3,
        pointBackgroundColor: tailwindConfig().theme.colors.violet[500],
        pointHoverBackgroundColor: tailwindConfig().theme.colors.violet[500],
        pointBorderWidth: 0,
        pointHoverBorderWidth: 0,
        clip: 20,
        tension: 0.2,
      },
      // الخط الرمادي
      {
        data: [
          689, 562, 477, 477, 477, 477, 458, 314, 430, 378, 430, 498, 642, 350,
          145, 145, 354, 260, 188, 188, 300, 300, 282, 364, 660, 554,
        ],
        borderColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.gray[500])}, 0.25)`,
        borderWidth: 2,
        pointRadius: 0,
        pointHoverRadius: 3,
        pointBackgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.gray[500])}, 0.25)`,
        pointHoverBackgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.gray[500])}, 0.25)`,
        pointBorderWidth: 0,
        pointHoverBorderWidth: 0,
        clip: 20,
        tension: 0.2,
      },
    ],
  };

  return (
    <div className='flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white dark:bg-gray-800 shadow-sm rounded-xl'>
      <div className='px-5 pt-5'>
        <header className='flex justify-between items-start mb-2'>
          <h2 className='text-lg font-semibold text-gray-800 dark:text-gray-100 mb-2'>
            قسم التصميمات
          </h2>
          {/* زر القائمة */}
          <EditMenu align='right' className='relative inline-flex'>
            <li>
              <Link
                className='font-medium text-sm text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-200 flex py-1 px-3'
                to='#0'
              >
                خيار 1
              </Link>
            </li>
            <li>
              <Link
                className='font-medium text-sm text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-200 flex py-1 px-3'
                to='#0'
              >
                خيار 2
              </Link>
            </li>
            <li>
              <Link
                className='font-medium text-sm text-red-500 hover:text-red-600 flex py-1 px-3'
                to='#0'
              >
                إزالة
              </Link>
            </li>
          </EditMenu>
        </header>
        <div className='text-xs font-semibold text-gray-400 dark:text-gray-500 uppercase mb-1'>
          الإلرادات
        </div>
        <div className='flex items-start'>
          <div className='text-3xl font-bold text-gray-800 dark:text-gray-100 ml-2'>
            9,962 ر.س
          </div>
          <div className='text-sm font-medium text-green-700 px-1.5 bg-green-500/20 rounded-full'>
            +49%
          </div>
        </div>
      </div>
      {/* الرسم البياني المبني باستخدام Chart.js 3 */}
      <div className='grow max-sm:max-h-[128px] xl:max-h-[128px]'>
        {/* تغيير ارتفاع الرسم البياني */}
        <LineChart data={chartData} width={389} height={128} />
      </div>
    </div>
  );
}

export default DashboardCard03;
