import React, { useState, useEffect, useRef } from 'react';
import { FaTimes } from 'react-icons/fa';
import useAuth from '../../Auth/Authentication/AuthUser';
import { useNavigate } from 'react-router-dom';

const OfferConvertForm = ({
  offerData,
  products,
  showModal,
  closeModal,
  setShowModal,
}) => {
  const navigate = useNavigate();
  const { http } = useAuth();
  const [selectedRep, setSelectedRep] = useState(null);
  const [selectedDesigner, setSelectedDesigner] = useState(null);
  const [commission, setCommission] = useState(false);
  const [orderType, setOrderType] = useState([]);
  const [designers, setDesigners] = useState([]);
  const [socialReps, setSocialReps] = useState([]);
  const isFetching = useRef(false);
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    if (showModal) {
      fetchDesignersAndSocialReps();
    }
  }, [showModal]);

  const fetchDesignersAndSocialReps = async () => {
    if (isFetching.current || fetched) return;
    isFetching.current = true;
    try {
      const designersResponse = await http.get('/api/designers');
      const socialRepsResponse = await http.get('/api/social-reps');
      setDesigners(designersResponse.data);
      setSocialReps(socialRepsResponse.data);
      setFetched(true);
    } catch (error) {
      console.error('Failed to fetch designers and social reps:', error);
    } finally {
      isFetching.current = false;
    }
  };

  const getProductDetailsWithId = id => {
    const product = products.find(product => product.id === id);
    return product
      ? {
          name: product.name,
          price: product.price,
          category: product.category?.name,
        }
      : null;
  };

  const handleOrderTypeChange = event => {
    const value = event.target.value;
    setOrderType(prevOrderType =>
      prevOrderType.includes(value)
        ? prevOrderType.filter(type => type !== value)
        : [...prevOrderType, value]
    );
  };

  const handleCommissionChange = event => {
    setCommission(event.target.checked);
  };

  const handleRepChange = event => {
    setSelectedRep(event.target.value);
  };

  const handleDesignerChange = event => {
    setSelectedDesigner(event.target.value);
  };

  const convertOfferToOrder = async event => {
    event.preventDefault();

    try {
      const productsData = offerData.products.map(product => {
        const productDetails = getProductDetailsWithId(product.product_id);
        return {
          product_id: product.product_id,
          quantity: product.quantity,
          price: productDetails?.price,
          notes: product.notes,
        };
      });

      const employeesData = {
        designer_id: selectedDesigner,
        sale_rep_id: offerData.sale_rep?.id,
        social_rep_id: selectedRep,
      };

      await http.post(`/api/offers/convert-offer/${offerData.id}`, {
        order_date: new Date().toISOString().split('T')[0],
        products: productsData,
        payment_method: offerData.payment_method,
        time_plementation_range: offerData.time_plementation_range,
        is_commission: commission,
        payment_type: offerData.payment_type,
        created_by: offerData.created_by,
        order_type: orderType,
        employees: employeesData,
      });

      alert('تم تحويل العرض إلى طلب بنجاح');
      navigate('/orders');
    } catch (error) {
      console.error('Failed to convert offer to order:', error);
      alert('فشل في تحويل العرض إلى طلب');
    }
  };

  if (!showModal) {
    return null;
  }

  return (
    <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50'>
      <div className='bg-white dark:bg-gray-900 rounded-xl shadow-2xl p-6 sm:p-8 transform transition-all duration-300 ease-in-out w-full max-w-2xl md:max-w-3xl mx-4'>
        <div className='modal-header mb-6 text-center bg-gradient-to-r from-blue-500 to-purple-500 dark:from-blue-700 dark:to-purple-700 w-full flex items-center justify-center py-4 relative rounded-t-xl shadow-lg'>
          <h4 className='text-2xl font-semibold text-white'>تنفيذ العرض</h4>
          <button
            className='absolute top-3 right-3 text-white hover:text-gray-200 transition-colors duration-200 ease-in-out'
            onClick={closeModal}
          >
            <FaTimes className='w-6 h-6' />
          </button>
        </div>
        <form onSubmit={convertOfferToOrder}>
          <div className='mb-6'>
            <label className='text-lg font-semibold text-gray-900 dark:text-white mb-2 block'>
              نوع الطلب:
            </label>
            <div className='flex flex-wrap gap-4'>
              {['خدمة', 'تصميمات ومطبوعات', 'منتجات دعائية', 'نشر وتسويق'].map(
                (type, index) => (
                  <div key={index} className='flex items-center mb-2'>
                    <input
                      type='radio'
                      id={type}
                      value={type}
                      checked={orderType.includes(type)}
                      onChange={handleOrderTypeChange}
                      className='form-radio h-5 w-5 text-blue-500 dark:text-blue-300 transition duration-150 ease-in-out'
                    />
                    <label
                      htmlFor={type}
                      className='ml-2 text-gray-800 dark:text-gray-200'
                    >
                      {type}
                    </label>
                  </div>
                )
              )}
            </div>
          </div>
          <div className='grid grid-cols-1 gap-4'>
            {orderType.includes('تصميمات ومطبوعات') && (
              <div>
                <label className='block text-md font-semibold text-gray-800 dark:text-gray-200 mb-2'>
                  المصمم:
                </label>
                <select
                  value={selectedDesigner || ''}
                  onChange={handleDesignerChange}
                  className='form-select block w-full mt-1 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm text-gray-800 dark:text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-300'
                >
                  <option value=''>اختر</option>
                  {designers.map(designer => (
                    <option
                      key={designer.id}
                      value={designer.id}
                      className='text-gray-800 dark:text-gray-200'
                    >
                      {designer.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {orderType.includes('نشر وتسويق') && (
              <div>
                <label className='block text-md font-semibold text-gray-800 dark:text-gray-200 mb-2'>
                  المسؤول التسويقي:
                </label>
                <select
                  value={selectedRep || ''}
                  onChange={handleRepChange}
                  className='form-select block w-full mt-1 bg-gray-100 dark:bg-gray-800 border border-gray-500 dark:border-gray-700 rounded-md shadow-sm text-gray-800 dark:text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-300'
                >
                  <option value=''>اختر</option>
                  {socialReps.map(rep => (
                    <option
                      key={rep.id}
                      value={rep.id}
                      className='text-gray-800 dark:text-gray-200'
                    >
                      {rep.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
          <div className='mt-6 flex items-center'>
            <input
              type='checkbox'
              checked={commission}
              onChange={handleCommissionChange}
              className='form-checkbox h-5 w-5 text-green-500 dark:text-green-300 transition duration-150 ease-in-out'
            />
            <label className='ml-2 text-lg font-semibold text-gray-900 dark:text-white'>
              شمول الطلب عمولة المندوب
            </label>
          </div>
          <div className='mt-6 flex justify-end'>
            <button
              type='submit'
              className='bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md transition duration-300 dark:bg-blue-700 dark:hover:bg-blue-800'
            >
              تحويل العرض إلى طلب
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default OfferConvertForm;
