import React, { useState, useEffect } from 'react';
import useAuth from '../../Auth/Authentication/AuthUser';
import { IoMdContact } from 'react-icons/io';
import { BsTextareaResize } from 'react-icons/bs';
import API_CONFIG from '../../../ApiConfig';
import { addCategory, updateCategory } from '../../Routes/api';

function CategoryModalForm({ isOpen, category, onClose, refreshCategories }) {
  const { http } = useAuth();
  const [validationErrors, setValidationErrors] = useState({});
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    image: null,
  });
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    if (isOpen && category) {
      setFormData({
        name: category.name,
        description: category.description,
        image: category.image ? `${API_CONFIG.baseURL}${category.image}` : null,
      });
    } else {
      setFormData({ name: '', description: '', image: null });
      setImageFile(null);
    }
  }, [isOpen, category]);

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  }

  function handleFileChange(e) {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setFormData(prevState => ({
        ...prevState,
        image: URL.createObjectURL(file),
      }));
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const formDataToSend = new FormData();

    formDataToSend.append('name', formData.name);
    formDataToSend.append('description', formData.description);

    if (imageFile) {
      formDataToSend.append('image', imageFile);
    }

    try {
      if (category && category.id) {
        await updateCategory(http, category.id, formDataToSend);
      } else {
        await addCategory(http, formDataToSend);
      }
      onClose();
      refreshCategories();
    } catch (error) {
      console.error('Error saving category:', error);
      setValidationErrors(error.response.data.errors || {});
    }
  }

  return (
    <div
      className={`fixed inset-0 z-50 overflow-y-auto ${!isOpen ? 'hidden' : ''}`}
    >
      <div className='flex items-center justify-center min-h-screen'>
        <div
          className='fixed inset-0 bg-gray-600 bg-opacity-50 transition-opacity'
          onClick={onClose}
        ></div>
        <div className='bg-white dark:bg-gray-900 rounded-lg shadow-xl transform transition-all sm:max-w-lg sm:w-full p-6'>
          <div className='border-0 m-2 p-2  bg-gray-200 dark:bg-gray-800 text-center'>
            <h3 className='text-xl font-bold border-b pb-4 shadow-sm text-violet-900 dark:text-green-200'>
              {category ? 'تعديل التصنيف' : 'إضافة تصنيف'}
            </h3>
          </div>
          <form
            onSubmit={handleSubmit}
            className='text-gray-900 dark:text-gray-100'
          >
            <div className='flex items-center border-b border-gray-300 py-2'>
              <IoMdContact className='text-lg text-gray-800 dark:text-violet-600 ml-2' />
              <input
                type='text'
                name='name'
                value={formData.name}
                onChange={handleChange}
                placeholder='اسم التصنيف'
                className='w-full bg-transparent  bg-white p-2 text-gray-900 font-bold  border-none text-right'
              />
            </div>
            <div className='flex items-center border-b border-gray-300 py-2'>
              <BsTextareaResize className='text-lg text-gray-800 dark:text-violet-800 ml-2' />
              <textarea
                className='w-full bg-transparent  bg-white p-2 text-gray-900 font-bold  border-none text-right'
                name='description'
                value={formData.description}
                onChange={handleChange}
                placeholder='الوصف'
              />
            </div>
            <div className='mb-4'>
              <label
                htmlFor='image'
                className='block text-gray-700 dark:text-violet-700 font-bold mb-2'
              >
                Image
              </label>

              <input
                type='file'
                name='image'
                onChange={handleFileChange}
                className='appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none'
              />
              {formData.image && (
                <div>
                  <img
                    src={formData.image}
                    alt='Product Group Image'
                    className='w-32 h-32 object-cover'
                  />
                </div>
              )}
            </div>
            <div className='flex justify-around mt-2'>
              <button
                className='bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700'
                onClick={onClose}
              >
                إلغاء
              </button>
              <button
                type='submit'
                className='bg-violet-500 hover:bg-violet-700 text-white font-bold px-4 py-2 rounded focus:outline-none focus:shadow-outline'
              >
                {category ? 'تحديث' : 'إضافة'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CategoryModalForm;
