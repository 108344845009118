import React, { useEffect, useState, useCallback } from 'react';
import { IconOrders } from '../../assets/img/icons/index';
import SectionHeader from '../Tools/SectionHeader';
import PlanningOrders from './Sections/PlanningOrders';
import ExecutionOrders from './Sections/ExecutionOrders';
import CompletedOrders from './Sections/CompletedOrders';
import FinishedOrders from './Sections/FinishedOrders';
import useAuth from '../Auth/Authentication/AuthUser';
import LogoImageSpinner from '../Tools/LogoImageSpinner';
import { fetchOrders } from '../Routes/api';
const OrderManagement = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [orders, setOrders] = useState([]);
  const [showImage, setShowImage] = useState(true);
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(null);
  const { http } = useAuth();

  const getOrders = useCallback(async () => {
    setShowImage(true); // عرض الصورة
    setLoading(true);
    try {
      const ordersData = await fetchOrders(http);
      setOrders(ordersData);
      setError(null);
    } catch (error) {
      console.error('Error fetching orders:', error);
      setError('Failed to fetch orders');
    } finally {
      setLoading(false);
      setShowImage(false); // إخفاء الصورة بعد الحصول على البيانات
    }
  }, []);

  useEffect(() => {
    getOrders();
  }, [getOrders]);

  const filteredOrders = orders.filter(order => {
    switch (activeTab) {
      case 1:
        return order.order_status === 'إعداد';
      case 2:
        return order.order_status === 'تنفيذ';
      case 3:
        return order.order_status === 'مكتمل';
      case 4:
        return order.order_status === 'منتهى';
      default:
        return [];
    }
  });
  const renderTabContent = () => {
    if (showImage) {
      return (
        <div className='flex z-50 justify-center items-center h-screen'>
          <LogoImageSpinner />
        </div>
      );
    }

    if (loading) {
      return <div className='text-center'>جاري التحميل...</div>;
    }

    if (error) {
      return <div>Error: {error}</div>;
    }

    switch (activeTab) {
      case 1:
        return (
          <PlanningOrders orders={filteredOrders} refreshOrders={getOrders} />
        );
      case 2:
        return (
          <ExecutionOrders orders={filteredOrders} refreshOrders={getOrders} />
        );
      case 3:
        return (
          <CompletedOrders orders={filteredOrders} refreshOrders={getOrders} />
        );
      case 4:
        return (
          <FinishedOrders orders={filteredOrders} refreshOrders={getOrders} />
        );
      default:
        return <div>Tab Content Not Found</div>;
    }
  };

  const tabButtons = [
    { id: 1, label: 'إعداد' },
    { id: 2, label: 'تنفيذ' },
    { id: 3, label: 'مكتمل' },
    { id: 4, label: 'منتهى' },
  ];
  return (
    <div className='container mx-auto px-4'>
      <SectionHeader imageSrc={IconOrders} sectionTitle='قائمة الطلبات'>
        <div className='flex justify-between'>
          {tabButtons.map(tab => (
            <button
              key={tab.id}
              aria-selected={activeTab === tab.id}
              className={`px-4 py-2 font-bold ${activeTab === tab.id ? 'text-green-700 dark:text-green-300' : 'text-yellow-500 dark:text-yellow-300'}`}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.label}
            </button>
          ))}
        </div>
      </SectionHeader>
      <div className='mt-8'>{renderTabContent()}</div>
    </div>
  );
};

export default OrderManagement;
