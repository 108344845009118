import React, { useState } from 'react';
import useAuth from '../../Auth/Authentication/AuthUser';

const InvoiceModalForm = ({
  orderId,
  productData,
  orderData,
  invoiceData,
  saleRepName,
  totalBeforeDiscountAndTax,
  totalAfterDiscountAndTax,
  onClose,
  onSave,
}) => {
  const { http } = useAuth();
  const getProductDetailsWithId = id => {
    const product = productData.find(product => product.id === id);
    return product
      ? {
          name: product.name,
          price: product.price,
          category: product.category?.name,
        }
      : { name: 'اسم المنتج غير معروف', price: 0, category: 'غير معروف' };
  };

  const [formData, setFormData] = useState({
    orderId: orderData.orderId,
    customerName: orderData.customer?.name || '',
    saleRepName: saleRepName || '',
    total: orderData.total,
    discount: orderData.discount_rate,
    tax: orderData.tax_rate,
    isCommission: orderData.is_commission,
    status: invoiceData?.status || 'pending',
    products: orderData.products.map(product => ({
      ...product,
      name: getProductDetailsWithId(product.product_id)?.name || 'غير معروف',
      price: getProductDetailsWithId(product.product_id)?.price || 0,
    })),
  });

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const response = await http.post('/api/invoices', {
        ...formData,
        total_before_discount_and_tax: totalBeforeDiscountAndTax,
        total_after_discount_and_tax: totalAfterDiscountAndTax,
        discount_amount: (
          totalBeforeDiscountAndTax - totalAfterDiscountAndTax
        ).toFixed(2),
        tax_amount: (
          (totalBeforeDiscountAndTax - totalAfterDiscountAndTax) *
          (formData.tax / 100)
        ).toFixed(2),
      });
      onSave(response.data);
      onClose();
    } catch (error) {
      console.error('Failed to create invoice:', error);
    }
  };

  return (
    <div className='fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50'>
      <div className='bg-white dark:bg-gray-900 rounded-lg shadow-2xl p-6 w-full max-w-4xl'>
        <div className='flex justify-between items-center mb-4 bg-gradient-to-r from-blue-500 to-purple-500 dark:from-blue-800 dark:to-purple-800 rounded-lg p-4 shadow-lg'>
          <h2 className='text-xl font-bold text-white dark:text-yellow-300 mx-auto'>
            إنشاء فاتورة
          </h2>
          <button
            className=' dark:text-gray-300 text-gray-800 hover:text-red-500 dark:hover:text-red-400 text-2xl'
            onClick={onClose}
          >
            &times;
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className='grid grid-cols-2 gap-4'>
            <div className='mb-4'>
              <label className='block  dark:text-gray-300 text-gray-800 mb-2'>
                اسم العميل
              </label>
              <input
                type='text'
                name='customerName'
                value={formData.customerName}
                onChange={handleInputChange}
                readOnly
                className='w-full p-2 border border-gray-300 rounded dark:bg-gray-800 dark:border-gray-700 dark:text-gray-300 text-gray-800'
              />
            </div>
            <div className='mb-4'>
              <label className='block  dark:text-gray-300 text-gray-800 mb-2'>
                نسبة الخصم
              </label>
              <input
                type='number'
                name='discount'
                value={formData.discount}
                onChange={handleInputChange}
                readOnly
                className='w-full p-2 border border-gray-300 rounded dark:bg-gray-800 dark:border-gray-700 dark:text-gray-300 text-gray-800'
              />
            </div>
            <div className='mb-4'>
              <label className='block  dark:text-gray-300 text-gray-800 mb-2'>
                نسبة الضريبة
              </label>
              <input
                type='number'
                name='tax'
                value={formData.tax}
                onChange={handleInputChange}
                readOnly
                className='w-full p-2 border border-gray-300 rounded dark:bg-gray-800 dark:border-gray-700 dark:text-gray-300 text-gray-800'
              />
            </div>
            <div className='mb-4'>
              <label className='block  dark:text-gray-300 text-gray-800 mb-2'>
                حالة العمولة
              </label>
              <input
                type='text'
                name='commissionStatus'
                value={
                  formData.isCommission
                    ? 'يوجد عمولة مرتبطة للمندوب'
                    : 'لا يوجد عمولة مرتبطة للمندوب'
                }
                readOnly
                className='w-full p-2 border border-gray-300 rounded dark:bg-gray-800 dark:border-gray-700 dark:text-gray-300 text-gray-800'
              />
            </div>
            <div className='mb-4'>
              <label className='block  dark:text-gray-300 text-gray-800 mb-2'>
                مندوب التسويق
              </label>
              <input
                type='text'
                name='saleRepName'
                value={saleRepName}
                readOnly
                className='w-full p-2 border border-gray-300 rounded dark:bg-gray-800 dark:border-gray-700 dark:text-gray-300 text-gray-800'
              />
            </div>
            <div className='mb-4'>
              <label className='block  dark:text-gray-300 text-gray-800 mb-2'>
                حالة الفاتورة
              </label>
              <select
                name='status'
                value={formData.status}
                onChange={handleInputChange}
                className='w-full p-2 border border-gray-300 rounded dark:bg-gray-800 dark:border-gray-700 dark:text-gray-300 text-gray-800'
              >
                <option value='pending'>معلقة</option>
                <option value='paid'>مدفوعة</option>
                <option value='cancelled'>ملغاة</option>
              </select>
            </div>
          </div>
          <h3 className='text-lg font-bold mb-4  dark:text-gray-300 text-gray-800'>
            تفاصيل المنتجات
          </h3>
          <table className='min-w-full divide-y divide-gray-200 mb-6'>
            <thead>
              <tr className='font-semibold text-gray-900 dark:text-white bg-gray-50 dark:bg-gray-700 uppercase tracking-wider'>
                <th className='px-3 py-2 border border-gray-300 shadow-lg text-center text-xs sm:text-sm'>
                  #
                </th>
                <th className='px-3 py-2 border border-gray-300 shadow-lg text-center text-xs sm:text-sm'>
                  المنتج
                </th>
                <th className='px-3 py-2 border border-gray-300 shadow-lg text-center text-xs sm:text-sm'>
                  الكمية
                </th>
                <th className='px-3 py-2 border border-gray-300 shadow-lg text-center text-xs sm:text-sm'>
                  السعر
                </th>
                <th className='px-3 py-2 border border-gray-300 shadow-lg text-center text-xs sm:text-sm'>
                  الإجمالي
                </th>
              </tr>
            </thead>
            <tbody>
              {formData.products.map((product, index) => (
                <tr
                  key={index}
                  className={`text-sm font-medium  dark:text-gray-300 text-gray-800 ${
                    index % 2 === 0
                      ? 'bg-gray-50 dark:bg-gray-700'
                      : 'bg-gray-100 dark:bg-gray-600'
                  }`}
                >
                  <td className='px-3 py-2 border border-gray-200 shadow-lg text-center text-violet-500 dark:text-yellow-300'>
                    {index + 1}
                  </td>
                  <td className='px-3 py-2 border border-gray-200 shadow-lg text-center text-violet-500 dark:text-yellow-300'>
                    {product.name}
                  </td>
                  <td className='px-3 py-2 border border-gray-200 shadow-lg text-center'>
                    {product.quantity}
                  </td>
                  <td className='px-3 py-2 border border-gray-200 shadow-lg text-center'>
                    {Number(product.price).toFixed(2)}
                  </td>
                  <td className='px-3 py-2 border border-gray-200 shadow-lg text-center'>
                    {(Number(product.price) * product.quantity).toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className='grid grid-cols-2 gap-4'>
            <div>
              <label className='block  dark:text-gray-300 text-gray-800 mb-2'>
                الإجمالي قبل الخصم والضريبة
              </label>
              <input
                type='text'
                value={Number(totalBeforeDiscountAndTax).toFixed(2)}
                readOnly
                className='w-full p-2 border border-gray-300 rounded dark:bg-gray-800 dark:border-gray-700 dark:text-gray-300 text-gray-800'
              />
            </div>
            <div>
              <label className='block  dark:text-gray-300 text-gray-800 mb-2'>
                الإجمالي بعد الخصم والضريبة
              </label>
              <input
                type='text'
                value={Number(totalAfterDiscountAndTax).toFixed(2)}
                readOnly
                className='w-full p-2 border border-gray-300 rounded dark:bg-gray-800 dark:border-gray-700 dark:text-gray-300 text-gray-800'
              />
            </div>
          </div>
          <button
            type='submit'
            className='w-full bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded transition duration-300 dark:bg-blue-700 dark:hover:bg-blue-800 mt-4'
          >
            إنشاء الفاتورة
          </button>
        </form>
      </div>
    </div>
  );
};

export default InvoiceModalForm;
