import axios from 'axios';
import { io } from 'socket.io-client';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import API_CONFIG from '../../../ApiConfig';
import SocetUrl from '../../../SocetUrl';

export default function useAuth() {
  const navigate = useNavigate();

  const csrfToken = document.querySelector('meta[name="csrf-token"]');
  const csrfTokenValue = csrfToken ? csrfToken.getAttribute('content') : null;

  const getToken = () => {
    const tokenString = sessionStorage.getItem('token');
    return tokenString ? JSON.parse(tokenString) : null;
  };

  const getUser = () => {
    const userString = sessionStorage.getItem('user');
    return userString ? JSON.parse(userString) : null;
  };

  const getRole = () => {
    const roleString = sessionStorage.getItem('role');
    return roleString || null;
  };

  const http = axios.create({
    baseURL: `${API_CONFIG.baseURL}`,
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });

  http.interceptors.request.use(config => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  // تحميل الصلاحيات من الخادم
  const fetchPermissions = async userId => {
    try {
      const response = await http.get(`api/permissions/${userId}`);
      const permissions = response.data;
      sessionStorage.setItem('permissions', JSON.stringify(permissions));
      setPermissions(permissions);
      return permissions;
    } catch (error) {
      console.error('Error fetching permissions:', error);
      return [];
    }
  };

  const getPermissions = () => {
    const permissionsString = sessionStorage.getItem('permissions');
    return permissionsString ? JSON.parse(permissionsString) : [];
  };

  const [token, setToken] = useState(getToken());
  const [user, setUser] = useState(getUser());
  const [role, setRole] = useState(getRole());
  const [permissions, setPermissions] = useState(getPermissions());
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (user && user.id) {
      // بعد تسجيل الدخول، تحميل الصلاحيات فورًا
      fetchPermissions(user.id);
      fetchUserImage(user.id); // Fetch user image
      setupWebSocket(user.id); // إعداد WebSocket للاستماع لتحديثات الصلاحيات
    }
  }, [user]);

  // إعداد WebSocket للاستماع للتحديثات
  const setupWebSocket = (userId) => {
    const socket = io(`${SocetUrl.baseURL}:8080`, {
      transports: ['websocket'],
    });

    // الاستماع لتحديث الصلاحيات
    socket.on(`user.${userId}:UserPermissionsUpdated`, (event) => {
      console.log('Received updated permissions:', event.permissions);
      sessionStorage.setItem('permissions', JSON.stringify(event.permissions));
      setPermissions(event.permissions); // تحديث حالة الصلاحيات في الواجهة
    });

    socket.on('disconnect', () => {
      console.log('WebSocket connection closed');
    });

    return () => {
      socket.disconnect(); // قطع الاتصال عند إزالة المكون
    };
  };

  
  
  const fetchUserImage = async userId => {
    try {
      const response = await http.get(`/api/users/${userId}`);
      const userData = response.data;
      setImage(userData.user_image); // Ensure the backend sends `user_image`
    } catch (error) {
      console.error('Error fetching user image:', error);
    }
  };

  
  const saveToken = (user, token, role) => {
    try {
      sessionStorage.setItem('token', JSON.stringify(token));
      sessionStorage.setItem('user', JSON.stringify(user));
      sessionStorage.setItem('role', role);
      setToken(token);
      setUser(user);
      setRole(role);

      fetchPermissions(user.id); // Fetch permissions once when saving token
      fetchUserImage(user.id); // Fetch user image
      navigate('/');
    } catch (error) {
      console.error('Error saving token and user:', error);
    }
  };

  const login = async (email, password) => {
    try {
      const response = await axios.post(`${API_CONFIG.baseURL}/api/login`, {
        email,
        password,
      });
      if (response.data.access_token && response.data.user) {
        saveToken(
          response.data.user,
          response.data.access_token,
          response.data.role
        );
        return { success: true };
      } else {
        return {
          success: false,
          message: 'Login failed. Please try again later.',
        };
      }
    } catch (error) {
      let message = 'An unexpected error occurred. Please try again.';
      if (error.response && error.response.data.errors) {
        const messages = Object.values(error.response.data.errors)
          .map(e => e.join('\n'))
          .join('\n');
        message = messages;
      } else if (error.response) {
        message = error.response.data.message || message;
      }
      console.error('Error in login:', error);
      return { success: false, message: message };
    }
  };

  const register = async ({ name, email, password }) => {
    try {
      const response = await axios.post(`${API_CONFIG.baseURL}/api/register`, {
        name,
        email,
        password,
      });
      if (response.data.message === 'success') {
        return true;
      } else {
        return {
          success: false,
          message: 'Registration failed. Please try again later.',
        };
      }
    } catch (error) {
      let message = 'حدث خطأ غير متوقع. يرجى المحاولة مرة أخرى.';
      if (error.response && error.response.data.errors) {
        const messages = Object.values(error.response.data.errors)
          .map(e => e.join('\n'))
          .join('\n');
        message = messages;
      } else if (error.response) {
        message = error.response.data.message || message;
      }
      console.error('Error in registration:', error);
      return { success: false, message: message };
    }
  };

  const logout = () => {
    sessionStorage.clear();
    navigate('/');
  };
  const hasPermission = (permissionName, section) => {
    // التأكد من أن الصلاحيات موجودة وتم تحديثها
    return permissions.some(
      permission =>
        permission.name === permissionName &&
        permission.section === section &&
        permission.pivot.enabled // التأكد من أن الصلاحية مفعلة
    );
  };
  
  return {
    setToken: saveToken,
    token,
    user,
    role,
    image,
    register,
    getToken,
    login,
    hasPermission,
    http,
    fetchPermissions,
    getUser,
    logout,
    getCsrfToken: csrfTokenValue,
    apiURL: '/api',
  };
}
