import React from 'react';
import { LogoArt } from '../../assets/img/index'; // Adjusted import statement

const ShowSpinner = () => {
  return (
    <div className='spinner-container'>
      <img src={LogoArt} alt='Logo Animation' className='logo-animation' />
    </div>
  );
};

export default ShowSpinner;
