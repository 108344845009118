// statusColors.js

export const orderStatusColors = {
  'قيد التنفيذ': 'text-yellow-500',
  'قيد الإعداد': 'text-blue-500',
  'قيد الإنشاء': 'text-blue-400',
  'تم التسليم': 'text-green-500',
  ملغي: 'text-red-500',
};

export const paymentStatusColors = {
  'سداد جزئي': 'text-orange-500',
  'غير مسدد': 'text-red-500',
  مكتمل: 'text-green-500',
  فشل: 'text-gray-500',
};
export const translateOrderType = type => {
  const translations = {
    promotional_products: 'منتجات دعائية',
    designs_and_prints: 'تصميمات ومطبوعات',
    service: 'خدمة',
    marketing: 'تسويق',
  };
  return translations[type] || type;
};
