// InvoiceList.js
import React, { useState } from 'react';
import TableComponent from '../../Tools/TableComponent';
import useAuth from '../../Auth/Authentication/AuthUser';
import { IconInvoices } from '../../../assets/img/icons';
import SectionHeader from '../../Tools/SectionHeader';

const CollectionsSection = () => {
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { http } = useAuth();

  const headers = [
    { key: 'id', text: 'رقم الفاتورة' },
    { key: 'customer_name', text: 'اسم العميل' },
    { key: 'total', text: 'قيمة الفاتورة' },
    { key: 'status', text: 'حالة الفاتورة' },
  ];

  //   const fetchData = async () => {
  //     setLoading(true);
  //     try {
  //       const { data } = await http.get('/api/invoices');
  //       if (Array.isArray(data)) {
  //         setInvoices(data);
  //       } else {
  //         setInvoices([]); // تعيين مصفوفة فارغة في حال عدم استلام مصفوفة من API
  //         console.error('Invalid data format: expected an array');
  //       }
  //     } catch (error) {
  //       console.error('Failed to fetch data:', error);
  //       setInvoices([]); // تعيين مصفوفة فارغة في حالة حدوث خطأ
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  return (
    <>
      <SectionHeader imageSrc={IconInvoices} sectionTitle='التحصيل' />

      {successMessage && (
        <div className='mb-4 p-2 bg-green-400 font-bold text-center text-green-800 rounded'>
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div className='mb-4 p-2 bg-red-400 font-bold text-center text-gray-100 rounded'>
          {errorMessage}
        </div>
      )}
      <div className='p-5 bg-gradient-to-r from-blue-200 to-blue-100 dark:from-gray-800 dark:to-gray-900 rounded-xl shadow-xl'>
        <div className='overflow-auto'>
          {/* <TableComponent
            data={invoices}
            headers={headers}
            sectionName={'Invoices'}
            onDelete={promptDelete} // Uncomment if needed
          /> */}
        </div>
      </div>
    </>
  );
};

export default CollectionsSection;
